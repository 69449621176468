import { Link } from "react-router-dom";
import formatPhone from "../../helpers/formatPhone";
import IconDisclaimer from "../Icons/Disclaimer";
import IconLogo from "../Icons/Logo";
import IconSbp from "../Icons/Sbp";
import classes from "./index.module.scss";

const {
   footer,
   footer__aboutCompany,
   footer__grid,
   footer__cel,
   footer__info,
   footer__links,
   footer__contacts,
   footer__pictures,
   footer__age,
   footer__sbp,
   logo,
   age,
   sbp,
   bold,
} = classes;

const initialState = window.initialState
   ? window.initialState
   : {
        settings: {
           about: {
              inn: 7805772796,
              ogrn: 1207800157465,
              license_cb: "â„–2103140009674, Ð²Ñ‹Ð´Ð°Ð½Ð° 28.04.2021 Ð³Ð¾Ð´Ð°",
              min_loan: 10000,
              max_loan: 30000,
              min_interest: 0,
              max_interest: 1,
              min_duration: 10,
              max_duration: 30,
              min_psk: 0,
              max_psk: 365,
              address: "ÐÐ´Ñ€ÐµÑ: 195221, Ð³Ð¾Ñ€Ð¾Ð´ Ð¡Ð°Ð½ÐºÑ‚-ÐŸÐµÑ‚ÐµÑ€Ð±ÑƒÑ€Ð³, Ð¿Ñ€-ÐºÑ‚ ÐœÐµÑ‚Ð°Ð»Ð»Ð¸ÑÑ‚Ð¾Ð², Ð´. 75 Ð»Ð¸Ñ‚ÐµÑ€Ð° Ð, ÐºÐ². 12",
              phone: 8126288181,
              email: "support@slon.finance",
              work_time: "ÐµÐ¶ÐµÐ´Ð½ÐµÐ²Ð½Ð¾: Ñ 06:00 Ð´Ð¾ 22:00 (Ð¿Ð¾ ÐœÐ¾ÑÐºÐ²Ðµ).",
              footer_text: [
                 "ÐšÐ¾Ð¼Ð¿Ð°Ð½Ð¸Ñ Ð²Ð½ÐµÑÐµÐ½Ð° Ð² Ð³Ð¾ÑÑ€ÐµÐµÑÑ‚Ñ€ Ð¦ÐµÐ½Ñ‚Ñ€Ð°Ð»ÑŒÐ½Ð¾Ð³Ð¾ Ð±Ð°Ð½ÐºÐ° Ð Ð¾ÑÑÐ¸Ð¸ Ð¸ Ð¾ÑÑƒÑ‰ÐµÑÑ‚Ð²Ð»ÑÐµÑ‚ ÑÐ²Ð¾ÑŽ Ð´ÐµÑÑ‚ÐµÐ»ÑŒÐ½Ð¾ÑÑ‚ÑŒ Ð² Ñ€Ð°Ð¼ÐºÐ°Ñ… Ð¤ÐµÐ´ÐµÑ€Ð°Ð»ÑŒÐ½Ñ‹Ñ… Ð·Ð°ÐºÐ¾Ð½Ð¾Ð² Ð Ð¤. Ð¦Ð‘ Ð Ð¤ ÐºÐ¾Ð½Ñ‚Ñ€Ð¾Ð»Ð¸Ñ€ÑƒÐµÑ‚ Ñ€Ð°Ð±Ð¾Ñ‚Ñƒ ÐœÐ¤Ðž Ð¸ Ð¾ÐºÐ°Ð·Ñ‹Ð²Ð°ÐµÑ‚ ÑÐ¾Ð´ÐµÐ¹ÑÑ‚Ð²Ð¸Ðµ Ñ„Ð¸Ð·Ð¸Ñ‡ÐµÑÐºÐ¸Ð¼ Ð»Ð¸Ñ†Ð°Ð¼, Ð² Ð¾Ð½Ð»Ð°Ð¹Ð½-Ñ€ÐµÐ¶Ð¸Ð¼Ðµ Ð´Ð¾ÑÑ‚ÑƒÐ¿Ð½Ð° Ð¸Ð½Ñ‚ÐµÑ€Ð½ÐµÑ‚-Ð¿Ñ€Ð¸Ñ‘Ð¼Ð½Ð°Ñ Ð‘Ð°Ð½ÐºÐ° Ð Ð¾ÑÑÐ¸Ð¸.",
                 "ÐÐ´Ñ€ÐµÑ Ð¼ÐµÑÑ‚Ð¾Ð½Ð°Ñ…Ð¾Ð¶Ð´ÐµÐ½Ð¸Ñ Ð¸ Ð¿Ð¾Ñ‡Ñ‚Ð¾Ð²Ñ‹Ð¹ Ð°Ð´Ñ€ÐµÑ Ð¾Ñ€Ð³Ð°Ð½Ð¸Ð·Ð°Ñ†Ð¸Ð¸: 195221, Ð³Ð¾Ñ€Ð¾Ð´ Ð¡Ð°Ð½ÐºÑ‚-ÐŸÐµÑ‚ÐµÑ€Ð±ÑƒÑ€Ð³, Ð¿Ñ€-ÐºÑ‚ ÐœÐµÑ‚Ð°Ð»Ð»Ð¸ÑÑ‚Ð¾Ð², Ð´. 75 Ð»Ð¸Ñ‚ÐµÑ€Ð° Ð, ÐºÐ². 12. ÐÐ¾Ð¼ÐµÑ€ Ñ‚ÐµÐ»ÐµÑ„Ð¾Ð½Ð° ÑÐ»ÑƒÐ¶Ð±Ñ‹ Ð¾Ð±ÐµÑÐ¿ÐµÑ‡ÐµÐ½Ð¸Ñ Ð´ÐµÑÑ‚ÐµÐ»ÑŒÐ½Ð¾ÑÑ‚Ð¸ Ñ„Ð¸Ð½Ð°Ð½ÑÐ¾Ð²Ð¾Ð³Ð¾ ÑƒÐ¿Ð¾Ð»Ð½Ð¾Ð¼Ð¾Ñ‡ÐµÐ½Ð½Ð¾Ð³Ð¾ - +7 (812) 628-81-81.",
              ],
              copyright: [
                 "Â© 2020-2023 ÐžÐ±Ñ‰ÐµÑÑ‚Ð²Ð¾ Ñ Ð¾Ð³Ñ€Ð°Ð½Ð¸Ñ‡ÐµÐ½Ð½Ð¾Ð¹ Ð¾Ñ‚Ð²ÐµÑ‚ÑÑ‚Ð²ÐµÐ½Ð½Ð¾ÑÑ‚ÑŒÑŽ ÐœÐ¸ÐºÑ€Ð¾ÐºÑ€ÐµÐ´Ð¸Ñ‚Ð½Ð°Ñ ÐºÐ¾Ð¼Ð¿Ð°Ð½Ð¸Ñ Â«Ð”Ð¾Ð¼Ð°ÑˆÐ½Ð¸Ð¹ Ð˜Ð½Ð²ÐµÑÑ‚Ð¾Ñ€Â» (ÑÐ¾ÐºÑ€Ð°Ñ‰ÐµÐ½Ð½Ð¾ â€“ ÐžÐžÐž ÐœÐšÐš Â«Ð”Ð¾Ð¼Ð°ÑˆÐ½Ð¸Ð¹ Ð˜Ð½Ð²ÐµÑÑ‚Ð¾Ñ€Â»), Ð¾ÑÑƒÑ‰ÐµÑÑ‚Ð²Ð»ÑÐµÑ‚ Ð´ÐµÑÑ‚ÐµÐ»ÑŒÐ½Ð¾ÑÑ‚ÑŒ Ð¿Ð¾Ð´ ÐºÐ¾Ð½Ñ‚Ñ€Ð¾Ð»ÐµÐ¼ Ð¦ÐµÐ½Ñ‚Ñ€Ð°Ð»ÑŒÐ½Ð¾Ð³Ð¾ Ð‘Ð°Ð½ÐºÐ° Ð Ð¤.",
              ],
           },
        },
     };

function Footer() {
   let about = initialState.settings.about ? initialState.settings.about : [];

   return (
      <footer className={footer}>
         <div className={footer__aboutCompany}>
            {about.footer_text.map((text, i) => (
               <p key={i}>{text}</p>
            ))}
         </div>
         <div className={footer__grid}>
            <div className={footer__cel}>
               <p className={bold}>Ð¡ÑƒÐ¼Ð¼Ð° Ð·Ð°Ð¹Ð¼Ð°</p>
               <p>
                  Ð¾Ñ‚ {parseFloat(about.min_loan).toLocaleString("ru-RU")} Ð´Ð¾{" "}
                  {parseFloat(about.max_loan).toLocaleString("ru-RU")} Ñ€ÑƒÐ±Ð»ÐµÐ¹
               </p>
            </div>
            <div className={footer__cel}>
               <p className={bold}>Ð¡Ñ€Ð¾Ðº Ð·Ð°Ð¹Ð¼Ð°</p>
               <p>
                  Ð¾Ñ‚ {about.min_duration} Ð´Ð¾ {about.max_duration} ÐºÐ°Ð»ÐµÐ½Ð´Ð°Ñ€Ð½Ñ‹Ñ… Ð´Ð½ÐµÐ¹
               </p>
            </div>
            <div className={footer__cel}>
               <p className={bold}>ÐŸÑ€Ð¾Ñ†ÐµÐ½Ñ‚Ð½Ð°Ñ ÑÑ‚Ð°Ð²ÐºÐ°</p>
               <p>
                  Ð¾Ñ‚ {about.min_interest}% Ð´Ð¾ {about.max_interest}% Ð² Ð´ÐµÐ½ÑŒ
               </p>
            </div>
            <div className={footer__cel}>
               <p className={bold}>ÐŸÐ¡Ðš (Ð¿Ð¾Ð»Ð½Ð°Ñ ÑÑ‚Ð¾Ð¸Ð¼Ð¾ÑÑ‚ÑŒ ÐºÑ€ÐµÐ´Ð¸Ñ‚Ð°)</p>
               <p>
                  Ð¾Ñ‚ {about.min_psk}% Ð´Ð¾ {about.max_psk}% Ð³Ð¾Ð´Ð¾Ð²Ñ‹Ñ…
               </p>
            </div>
            <div className={footer__cel}>
               <p className={bold}>Ð˜ÐÐ</p>
               <p>{about.inn}</p>
            </div>
            <div className={footer__cel}>
               <p className={bold}>ÐžÐ Ð“Ð</p>
               <p>{about.ogrn}</p>
            </div>
            <div className={footer__cel}>
               <p className={bold}>Ð›Ð¸Ñ†ÐµÐ½Ð·Ð¸Ñ Ð¦ÐµÐ½Ñ‚Ñ€Ð°Ð»ÑŒÐ½Ð¾Ð³Ð¾ Ð‘Ð°Ð½ÐºÐ°</p>
               <p>{about.license_cb}</p>
            </div>
         </div>
         <div className={footer__info}>
            <div className={footer__links}>
               <Link to={"/o-nas"}>Ðž ÐºÐ¾Ð¼Ð¿Ð°Ð½Ð¸Ð¸</Link>
               <Link to={"/dokumenti"}>Ð”Ð¾ÐºÑƒÐ¼ÐµÐ½Ñ‚Ñ‹</Link>
            </div>
            <div className={footer__contacts}>
               <p>{about.copyright[0]}</p>
               <address>ÐÐ´Ñ€ÐµÑ: {about.address}</address>
               <p>
                  Ð¢ÐµÐ»ÐµÑ„Ð¾Ð½ Ð´Ð»Ñ ÑÐ²ÑÐ·Ð¸: <a href={"tel: +7" + about.phone}>{formatPhone(about.phone)}</a>
               </p>
               <p>Ð“Ñ€Ð°Ñ„Ð¸Ðº Ñ€Ð°Ð±Ð¾Ñ‚Ñ‹: {about.work_time}</p>
               <p>
                  Ð¡Ð»ÑƒÐ¶Ð±Ð° Ð¿Ð¾Ð´Ð´ÐµÑ€Ð¶ÐºÐ¸ ÐºÐ»Ð¸ÐµÐ½Ñ‚Ð¾Ð²: <a href={"mailto: " + about.email}>{about.email}</a>
               </p>
            </div>
         </div>
         <div className={footer__pictures}>
            <Link aria-label="Ð“Ð»Ð°Ð²Ð½Ð°Ñ" to={"/"} title="Ð“Ð»Ð°Ð²Ð½Ð°Ñ">
               <IconLogo className={logo} />
            </Link>
            <div className={footer__age}>
               <IconDisclaimer className={age} />
            </div>
            <div className={footer__sbp}>
               <IconSbp className={sbp} />
            </div>
         </div>
      </footer>
   );
}

export default Footer;
