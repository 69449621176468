import { useEffect, useState } from "react";

import Input from "../../components/Input";
import InputRadio from "../../components/InputRadio";
import DropDownList from "../../components/DropDownList";

import css from "./styles.module.scss";
import InputRange from "../../components/InputRange";
import CheckBox from "../../components/Checkbox";

const {
   application__stepBlock,
   application__sign,
   application__signHeader,
   application__signBlock,
   application__signBlockCheck,
   application__signBlockText,
   application__signNote,
} = css;

const ApplicationStep3 = ({ onChange = () => {}, data = {}, errors = {}, settings = {}, hasSend = false }) => {
   const [jobType, setJobType] = useState(data.jobType || data.jobType === 0 ? data.jobType : 0);
   const jobTypeArray = ["Ð Ð°Ð±Ð¾Ñ‚Ð°ÑŽ", "ÐÐµ Ñ€Ð°Ð±Ð¾Ñ‚Ð°ÑŽ", "ÐŸÐµÐ½ÑÐ¸Ð¾Ð½ÐµÑ€"];

   const [jobPlace, setJobPlace] = useState(data.jobPlace ? data.jobPlace : "");
   const [jobPosition, setJobPosition] = useState(data.jobPosition ? data.jobPosition : "");

   const [jobAddress, setJobAddress] = useState(data.jobAddress ? data.jobAddress : "");

   const [jobPhone, setJobPhone] = useState(data.jobPhone ? data.jobPhone : "");
   const [nextIncome, setNextIncome] = useState(data.nextIncome || data.nextIncome === 0 ? data.nextIncome : 0);
   const nextIncomeArray = [
      "1Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "2Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "3Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "4Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "5Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "6Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "7Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "8Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "9Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "10Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "11Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "12Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "13Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "14Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "15Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "16Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "17Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "18Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "19Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "20Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "21Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "22Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "23Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "24Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "25Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "26Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "27Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "28Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "29Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "30Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "31Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
   ];

   const [income, setIncome] = useState(data.income || data.income === 0 ? data.income : 0);
   const incomeArray = [
      "Ð”Ð¾ 10 000 â‚½",
      "10 000 â‚½ - 20 000 â‚½",
      "20 000 â‚½ - 35 000 â‚½",
      "35 000 â‚½ - 50 000 â‚½",
      "Ð‘Ð¾Ð»ÐµÐµ 50 000 â‚½",
   ];

   const [loans, setLoans] = useState(data.loans || data.loans === 0 ? data.loans : 1);
   const loansArray = [
      "ÐÐµÑ‚ Ð¿Ð»Ð°Ñ‚ÐµÐ¶ÐµÐ¹ Ð¿Ð¾ ÐºÑ€ÐµÐ´Ð¸Ñ‚Ð°Ð¼",
      "Ð”Ð¾ 10 000 â‚½",
      "10 000 â‚½ - 20 000 â‚½",
      "20 000 â‚½ - 35 000 â‚½",
      "35 000 â‚½ - 50 000 â‚½",
      "Ð‘Ð¾Ð»ÐµÐµ 50 000 â‚½",
   ];

   const [amount, setAmount] = useState(data.amount ? data.amount : 10000);
   const amountArray = [];
   for (let i = 2000; i <= 30000; i += 1000) amountArray.push(i);

   const [duration, setDuration] = useState(data.duration || data.duration === 0 ? data.duration : 0);
   const durationArray = [];
   for (let i = 7; i <= 30; i++) durationArray.push(i);

   const [rule, setRule] = useState(false);
   const [ruleKomf, setRuleKomf] = useState(false);
   const [ruleUstupka, setRuleUstupka] = useState(false);
   const [rulePersDate, setRulePersDate] = useState(false);
   const [ruleAsp, setRuleAsp] = useState(false);

   useEffect(() => {
      onChange({
         jobType,
         jobPlace,
         jobPosition,
         jobAddress,
         jobPhone,
         nextIncome,
         income,
         loans,
         amount,
         duration,
         rule,
         ruleKomf,
         ruleUstupka,
         rulePersDate,
         ruleAsp,
      });
   }, [
      jobType,
      jobPlace,
      jobPosition,
      jobAddress,
      jobPhone,
      nextIncome,
      income,
      loans,
      amount,
      duration,
      rule,
      ruleKomf,
      ruleUstupka,
      rulePersDate,
      ruleAsp,
   ]);

   return (
      <>
         <div className={application__stepBlock}>
            <DropDownList label={"Ð’Ð¸Ð´ Ð·Ð°Ð½ÑÑ‚Ð¾ÑÑ‚Ð¸"} options={jobTypeArray} value={jobType} onChange={setJobType} />
         </div>
         {jobType === 0 ? (
            <>
               <div className={application__stepBlock}>
                  <Input
                     label={"ÐœÐµÑÑ‚Ð¾ Ñ€Ð°Ð±Ð¾Ñ‚Ñ‹"}
                     placeholder={'ÐžÐžÐž "Ð Ð¾Ð³Ð° Ð¸ ÐºÐ¾Ð¿Ñ‹Ñ‚Ð°"'}
                     value={jobPlace}
                     onChange={setJobPlace}
                     error={errors.jobPlace ? errors.jobPlace : null}
                     showErrorOnBlur={!hasSend}
                  />
                  <Input
                     label={"Ð—Ð°Ð½Ð¸Ð¼Ð°ÐµÐ¼Ð°Ñ Ð´Ð¾Ð»Ð¶Ð½Ð¾ÑÑ‚ÑŒ"}
                     placeholder="ÐžÑ…Ñ€Ð°Ð½Ð¸Ðº"
                     value={jobPosition}
                     onChange={setJobPosition}
                     error={errors.jobPosition ? errors.jobPosition : null}
                     showErrorOnBlur={!hasSend}
                  />
               </div>
               <div className={application__stepBlock}>
                  <Input
                     label={"ÐÐ´Ñ€ÐµÑ Ð¾Ñ€Ð³Ð°Ð½Ð¸Ð·Ð°Ñ†Ð¸Ð¸"}
                     placeholder="ÐœÐ¾ÑÐºÐ²Ð°, ÑƒÐ». Ð¢Ð²ÐµÑ€ÑÐºÐ°Ñ, Ð´. 1"
                     value={jobAddress}
                     onChange={setJobAddress}
                     error={errors.jobAddress ? errors.jobAddress : null}
                     showErrorOnBlur={!hasSend}
                  />
               </div>
               <div className={application__stepBlock}>
                  <Input
                     label={"ÐÐ¾Ð¼ÐµÑ€ Ñ‚ÐµÐ»ÐµÑ„Ð¾Ð½Ð° Ð¾Ñ€Ð³Ð°Ð½Ð¸Ð·Ð°Ñ†Ð¸Ð¸"}
                     placeholder="+7 (900) 800-70-60"
                     value={jobPhone}
                     onChange={setJobPhone}
                     mask="+7 (XXX) XXX-XX-XX"
                     regex={/^[0-9]$/}
                     error={errors.jobPhone ? errors.jobPhone : null}
                     showErrorOnBlur={!hasSend}
                  />
                  <DropDownList
                     label={"Ð‘Ð»Ð¸Ð¶Ð°Ð¹ÑˆÐ°Ñ Ð²Ñ‹Ð¿Ð»Ð°Ñ‚Ð°"}
                     options={nextIncomeArray}
                     value={nextIncome}
                     onChange={setNextIncome}
                  />
               </div>
            </>
         ) : (
            ""
         )}
         <div className={application__stepBlock}>
            <DropDownList label={"Ð•Ð¶ÐµÐ¼ÐµÑÑÑ‡Ð½Ñ‹Ð¹ Ð´Ð¾Ñ…Ð¾Ð´"} options={incomeArray} value={income} onChange={setIncome} />
         </div>
         <div className={application__stepBlock}>
            <DropDownList label={"ÐšÑ€ÐµÐ´Ð¸Ñ‚Ð½Ñ‹Ðµ Ð¾Ð±ÑÐ·Ð°Ñ‚ÐµÐ»ÑŒÑÑ‚Ð²Ð°"} options={loansArray} value={loans} onChange={setLoans} />
         </div>
         <div className={application__stepBlock}>
            <InputRange
               label={"ÐšÐ°ÐºÐ°Ñ ÑÑƒÐ¼Ð¼Ð° Ð²Ð°Ð¼ Ñ‚Ñ€ÐµÐ±ÑƒÐµÑ‚ÑÑ?"}
               length={settings.max_amount ? settings.max_amount : 30000}
               showValue={parseInt(amount).toLocaleString("ru-RU") + " â‚½"}
               step={1000}
               min={settings.min_amount ? settings.min_amount : 2000}
               value={amount}
               onChange={setAmount}
            />
            <InputRange
               label={"ÐÐ° ÐºÐ°ÐºÐ¾Ð¹ ÑÑ€Ð¾Ðº?"}
               length={settings.max_duration ? settings.max_duration : 30}
               showValue={duration + " Ð´Ð½ÐµÐ¹"}
               step={1}
               min={settings.min_duration ? settings.min_duration : 7}
               value={duration}
               onChange={setDuration}
            />
         </div>

         <div className={application__sign}>
            <h2 className={application__signHeader}>ÐŸÐ¾Ð´Ð¿Ð¸ÑÐ°Ð½Ð¸Ðµ Ð·Ð°ÑÐ²ÐºÐ¸</h2>

            <label className={application__signBlock}>
               <CheckBox
                  className={application__signBlockCheck}
                  value={rule || (ruleAsp && ruleKomf && rulePersDate && ruleUstupka)}
                  onChange={setRule}
               />
               <p className={application__signBlockText}>Ð¯ ÑÐ¾Ð³Ð»Ð°ÑÐµÐ½ ÑÐ¾ Ð²ÑÐµÐ¼Ð¸ ÑƒÑÐ»Ð¾Ð²Ð¸ÑÐ¼Ð¸</p>
            </label>

            <label className={application__signBlock}>
               <CheckBox className={application__signBlockCheck} value={rule || ruleKomf} onChange={setRuleKomf} />
               <p className={application__signBlockText}>
                  ÐÐ°ÑÑ‚Ð¾ÑÑ‰Ð¸Ð¼ Ð¿Ð¾Ð´Ñ‚Ð²ÐµÑ€Ð¶Ð´Ð°ÑŽ, Ñ‡Ñ‚Ð¾ Ð¿Ð¾Ð»Ð½Ð¾ÑÑ‚ÑŒÑŽ Ð¾Ð·Ð½Ð°ÐºÐ¾Ð¼Ð»ÐµÐ½ Ñ ÑƒÑÐ»Ð¾Ð²Ð¸ÑÐ¼Ð¸ Ð¿Ñ€ÐµÐ´Ð¾ÑÑ‚Ð°Ð²Ð»ÐµÐ½Ð¸Ñ Ð¼Ð¸ÐºÑ€Ð¾Ð·Ð°Ð¹Ð¼Ð°, Ð° Ñ‚Ð°Ðº Ð¶Ðµ Ñ&nbsp;
                  <a href="/upload/documents/publichnaya-oferta.pdf" target="_blank" rel="noopener noreferrer">
                     Ð¿ÑƒÐ±Ð»Ð¸Ñ‡Ð½Ð¾Ð¹ Ð¾Ñ„ÐµÑ€Ñ‚Ð¾Ð¹
                  </a>{" "}
                  Ð¸ Ð¾Ð±Ñ‰Ð¸Ð¼Ð¸ ÑƒÑÐ»Ð¾Ð²Ð¸ÑÐ¼Ð¸.
               </p>
            </label>

            <label className={application__signBlock}>
               <CheckBox
                  className={application__signBlockCheck}
                  value={rule || ruleUstupka}
                  onChange={setRuleUstupka}
               />
               <p className={application__signBlockText}>
                  ÐÐ°ÑÑ‚Ð¾ÑÑ‰Ð¸Ð¼ ÑÐ¾Ð³Ð»Ð°ÑˆÐ°ÑŽÑÑŒ Ð½Ð° ÑƒÑÑ‚ÑƒÐ¿ÐºÑƒ Ð¿Ñ€Ð°Ð² (Ñ‚Ñ€ÐµÐ±Ð¾Ð²Ð°Ð½Ð¸Ð¹) Ð¿Ð¾ Ð´Ð¾Ð³Ð¾Ð²Ð¾Ñ€Ñƒ Ñ‚Ñ€ÐµÑ‚ÑŒÐ¸Ð¼ Ð»Ð¸Ñ†Ð°Ð¼ Ð¿Ñ€Ð¸ ÑƒÑÐ»Ð¾Ð²Ð¸Ð¸ ÑÐ¾Ð±Ð»ÑŽÐ´ÐµÐ½Ð¸Ñ
                  ÐžÐ±Ñ‰ÐµÑÑ‚Ð²Ð¾Ð¼ Ñ‚Ñ€ÐµÐ±Ð¾Ð²Ð°Ð½Ð¸Ð¹ Ð´ÐµÐ¹ÑÑ‚Ð²ÑƒÑŽÑ‰ÐµÐ³Ð¾ Ð·Ð°ÐºÐ¾Ð½Ð¾Ð´Ð°Ñ‚ÐµÐ»ÑŒÑÑ‚Ð²Ð°.
               </p>
            </label>

            <label className={application__signBlock}>
               <CheckBox
                  className={application__signBlockCheck}
                  value={rule || rulePersDate}
                  onChange={setRulePersDate}
               />
               <p className={application__signBlockText}>
                  ÐÐ°ÑÑ‚Ð¾ÑÑ‰Ð¸Ð¼ Ð²Ñ‹Ñ€Ð°Ð¶Ð°ÑŽ ÑÐ²Ð¾Ñ‘ ÑÐ¾Ð³Ð»Ð°ÑÐ¸Ðµ Ð½Ð° Ð¾Ð±Ñ€Ð°Ð±Ð¾Ñ‚ÐºÑƒ{" "}
                  <a href="/file.pdf" target="_blank" rel="noopener noreferrer">
                     Ð¿ÐµÑ€ÑÐ¾Ð½Ð°Ð»ÑŒÐ½Ñ‹Ñ… Ð´Ð°Ð½Ð½Ñ‹Ñ… Ð¸ Ð½Ð° Ð·Ð°Ð¿Ñ€Ð¾Ñ ÐºÑ€ÐµÐ´Ð¸Ñ‚Ð½Ð¾Ð³Ð¾ Ð¾Ñ‚Ñ‡Ñ‘Ñ‚Ð° Ð² Ð±ÑŽÑ€Ð¾ ÐºÑ€ÐµÐ´Ð¸Ñ‚Ð½Ñ‹Ñ… Ð¸ÑÑ‚Ð¾Ñ€Ð¸Ð¹
                  </a>
                  .
               </p>
            </label>

            <label className={application__signBlock}>
               <CheckBox className={application__signBlockCheck} value={rule || ruleAsp} onChange={setRuleAsp} />
               <p className={application__signBlockText}>
                  ÐÐ°ÑÑ‚Ð¾ÑÑ‰Ð¸Ð¼ Ð²Ñ‹Ñ€Ð°Ð¶Ð°ÑŽ ÑÐ²Ð¾Ñ‘ ÑÐ¾Ð³Ð»Ð°ÑÐ¸Ðµ Ð½Ð° Ð¿Ñ€Ð¸Ð½ÑÑ‚Ð¸Ðµ ÑƒÑÐ»Ð¾Ð²Ð¸Ð¹{" "}
                  <a href="/file.pdf" target="_blank" rel="noopener noreferrer">
                     Ð¡Ð¾Ð³Ð»Ð°ÑˆÐµÐ½Ð¸Ñ Ð¾Ð± Ð¸ÑÐ¿Ð¾Ð»ÑŒÐ·Ð¾Ð²Ð°Ð½Ð¸Ð¸ ÐÐ¡ÐŸ
                  </a>
                  .
               </p>
            </label>

            <p className={application__signNote}>
               Ð¯ Ð´Ð°ÑŽ ÑÐ²Ð¾Ñ‘ ÑÐ¾Ð³Ð»Ð°ÑÐ¸Ðµ Ð½Ð° Ñ‚Ð¾, Ñ‡Ñ‚Ð¾ Ð·Ð°Ð¿Ð¾Ð»Ð½Ð¸Ð² ÑÐ²ÐµÐ´Ð¸Ð½Ð¸Ñ Ð² ÐÐ½ÐºÐµÑ‚Ðµ-Ð·Ð°ÑÐ²Ð»ÐµÐ½Ð¸Ð¹ Ð½Ð° Ð¿Ð¾Ð»ÑƒÑ‡ÐµÐ½Ð¸Ðµ Ð¿Ð¾Ñ‚Ñ€ÐµÐ±Ð¸Ñ‚ÐµÐ»ÑŒÑÐºÐ¾Ð³Ð¾ Ð·Ð°Ð¹Ð¼Ð°,
               Ñ Ð¿Ð¾Ð´Ñ‚Ð²ÐµÑ€Ð¶Ð´Ð°ÑŽ Ð´Ð¾ÑÑ‚Ð¾Ð²ÐµÑ€Ð½Ð¾ÑÑ‚ÑŒ Ð¿Ñ€ÐµÐ´Ð¾ÑÑ‚Ð°Ð²Ð»ÐµÐ½Ð½Ñ‹Ñ… Ð¼Ð½Ð¾ÑŽ ÑÐ²ÐµÐ´ÐµÐ½Ð¸Ð¹ Ð¸ ÑÑ‡Ð¸Ñ‚Ð°ÑŽ Ð°Ð½ÐºÐµÑ‚Ñƒ Ð¿Ð¾Ð´Ð¿Ð¸ÑÐ°Ð½Ð½Ð¾Ð¹ ÑÐ¾ ÑÐ²Ð¾ÐµÐ¹ ÑÑ‚Ð¾Ñ€Ð¾Ð½Ñ‹.
            </p>
         </div>
      </>
   );
};

export default ApplicationStep3;
