import { useState } from "react";
import Button from "../Button";
import IconDropDownArrow from "../Icons/DropDownArrow";
import css from "./styles.module.scss";

const {
    dropDownList,
    dropDownList__label,
    dropDownList__native,
    dropDownList__input,
    dropDownList__list,
    dropDownList__item,
    dropDownList__icon
} = css;

const DropDownList = ({className, label, value = 0, options=[], onChange = () => {}}) => {
    const [shown, setShown] = useState(false);
    
    let tmpClassName = dropDownList + (className ? " " + className : "");

    return (
        <label
            className={tmpClassName}
            onMouseLeave={() => setShown(false)}
        >
            {
                label ? (
                    <p className={dropDownList__label}>
                        {label}
                    </p>
                ) : ""
            }
            <select
                className={dropDownList__native}
                defaultValue={value}
                onChange={e=>{onChange(e.target.selectedIndex)}}
            >
                {
                    options.map((option, i) => (
                        <option
                            key={i}
                        >
                            {option}
                        </option>
                    ))
                }
            </select>

            <div
                className={dropDownList__input}
                onClick={() => setShown(true)}
            >
                {
                    options[value] ? options[value] : "Ð’Ñ‹Ð±ÐµÑ€Ð¸Ñ‚Ðµ Ð²Ð°Ñ€Ð¸Ð°Ð½Ñ‚"
                }
                <IconDropDownArrow className={dropDownList__icon} />
            </div>

            {
                shown ? (
                    <div className={dropDownList__list}>
                        {
                            options.map((option, i) => (
                                <Button
                                    className={dropDownList__item}
                                    variant={value !== i ? "gray" : null}
                                    key={i}
                                    onClick={()=>{onChange(i);setShown(false);}}
                                >
                                    {option}
                                </Button>
                            ))
                        }
                    </div>
                ) : ""
            }
        </label>
    );
}

export default DropDownList;