import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import BlockWrapper from "../../components/BlockWrapper";
import Button from "../../components/Button";
import Input from "../../components/Input";
import PincodeInput from "../../components/PincodeInput";
import css from "./styles.module.scss";

const {
   signIn,
   signIn__wrapper,
   signIn__title,
   signIn__text,
   signIn__form,
   signIn__form_pin,
   signIn__input,
   signIn__button,
   signIn__link,
   signIn__secondaryText
} = css;


function SignIn() {
   const navigate = useNavigate();

   const setAuth = ({ token, expire_in }) => {
      localStorage.setItem("token", token);
      localStorage.setItem("expireIn", expire_in);
      localStorage.setItem("role", 0);

      navigate(0);
   };

   const [step, setStep] = useState(0);

   const [phone, setPhone] = useState("");
   const [phoneError, setPhoneError] = useState();

   useEffect(() => setPhoneError(), [phone]);

   const sendPhoneNumber = async (e) => {
      e.preventDefault();

      if (phone.length === 10) {
         const body = new FormData();
         body.append("phone", phone);

         const resp = await fetch(global.api + "auth", {
            method: "POST",
            body,
         });

         const json = await resp.json();

         if (json.status === "success") {
            if (!json.password) {
               setStep(3);
            } else if (!json.email) {
               setStep(4);
            }
         } else {
            if (json.message === "Need a password to log in") {
               setStep(1);
            } else {
               setPhoneError("ÐŸÑ€Ð¾Ð²ÐµÑ€ÑŒÑ‚Ðµ ÑƒÐºÐ°Ð·Ð°Ð½Ð½Ñ‹Ð¹ Ñ‚ÐµÐ»ÐµÑ„Ð¾Ð½");
            }
         }
      }
   };

   const [password, setPassword] = useState("");
   const [passwordError, setPasswordError] = useState(false);

   const sendPassword = async (e) => {
      e.preventDefault();

      if (password.length === 5) {
         const body = new FormData();

         body.append("phone", phone);
         body.append("password", password);

         const resp = await fetch(global.api + "auth", {
            method: "POST",
            body,
         });

         const json = await resp.json();

         if (json.status === "success") {
            setAuth(json.data);
         } else if (json.message === "User blocked") {
            localStorage.setItem("resultPage", JSON.stringify({
               title: "Ð›Ð¸Ñ‡Ð½Ñ‹Ð¹ ÐºÐ°Ð±Ð¸Ð½ÐµÑ‚ Ð·Ð°Ð±Ð»Ð¾ÐºÐ¸Ñ€Ð¾Ð²Ð°Ð½",
               text: json.data?.reason ? "Ð’Ð°Ñˆ Ð»Ð¸Ñ‡Ð½Ñ‹Ð¹ ÐºÐ°Ð±Ð¸Ð½ÐµÑ‚ Ð±Ñ‹Ð» Ð·Ð°Ð±Ð»Ð¾ÐºÐ¸Ñ€Ð¾Ð²Ð°Ð½<br />Ð¿Ð¾ ÑÐ»ÐµÐ´ÑƒÑŽÑ‰ÐµÐ¹ Ð¿Ñ€Ð¸Ñ‡Ð¸Ð½Ðµ:<br />"+json.data.reason+"<br /><br />Ð”Ð»Ñ ÑÐ½ÑÑ‚Ð¸Ñ Ð±Ð»Ð¾ÐºÐ¸Ñ€Ð¾Ð²ÐºÐ¸ Ð¾Ð±Ñ€Ð°Ñ‚Ð¸Ñ‚ÐµÑÑŒ Ð¿Ð¾ Ð½Ð¾Ð¼ÐµÑ€Ñƒ <b style=\"white-space: nowrap;\">+7 (812) 408-25-25</b>." : "Ð”Ð»Ñ ÑÐ½ÑÑ‚Ð¸Ñ Ð±Ð»Ð¾ÐºÐ¸Ñ€Ð¾Ð²ÐºÐ¸ Ð¾Ð±Ñ€Ð°Ñ‚Ð¸Ñ‚ÐµÑÑŒ Ð¿Ð¾ Ð½Ð¾Ð¼ÐµÑ€Ñƒ <b style=\"white-space: nowrap;\">+7 (812) 408-25-25</b>.",
               buttonText: "Ð’ÐµÑ€Ð½ÑƒÑ‚ÑŒÑÑ Ð½Ð° Ð³Ð»Ð°Ð²Ð½ÑƒÑŽ",
               buttonLink: "/",
            }));
            navigate("/message");
         } else {
            setPasswordError(true);

            const timer = setTimeout(() => {
               setPasswordError(false);
               setPassword("");
               clearTimeout(timer);
            }, 1000);
         }
      }
   };

   const sendResetRequest = async () => {
      const body = new FormData();
      body.append("phone", phone);

      const resp = await fetch(global.api + "reset_password", {
         method: "POST",
         body,
      });

      const json = await resp.json();

      if (json.status === "success") {
         setStep(2);
      }
   };

   const goToEmail = (e) => {
      e.preventDefault();

      if (password.length === 5) {
         setStep(4);
      }
   };

   const [email, setEmail] = useState("");
   const [emailError, setEmailError] = useState();

   const checkEmail = (em) => em.match(/^(\w|-|\.|_){2,}@(\w|-|\.|_){2,}\.\w{2,}$/);

   const sendPasswordEmail = async (e) => {
      e.preventDefault();

      if (password.length === 5 && checkEmail(email)) {
         const body = new FormData();

         body.append("phone", phone);
         body.append("password", password);
         body.append("email", email);

         const resp = await fetch(global.api + "auth", {
            method: "POST",
            body,
         });

         const json = await resp.json();

         if (json.status === "success") {
            setAuth(json.data);
            setEmailError();
         } else {
            if (
               json.message === "Need a email to log in" ||
               json.message === "The email parameter is invalid"
            ) {
               setEmailError("ÐÐ´Ñ€ÐµÑ ÑÐ». Ð¿Ð¾Ñ‡Ñ‚Ñ‹ ÑƒÐºÐ°Ð·Ð°Ð½ Ð½ÐµÐºÐ¾Ñ€Ñ€ÐµÐºÑ‚Ð½Ð¾.");
            }
            else if (json.message === "User with this email already exist") {
               setEmailError("ÐšÑ‚Ð¾-Ñ‚Ð¾ ÑƒÐ¶Ðµ Ð¸ÑÐ¿Ð¾Ð»ÑŒÐ·ÑƒÐµÑ‚ ÑÑ‚Ð¾Ñ‚ Ð°Ð´Ñ€ÐµÑ ÑÐ». Ð¿Ð¾Ñ‡Ñ‚Ñ‹.");
            }
         }
      }
   };

   return (
      <div className={signIn}>
         {step === 0 || (step !== 1 && step !== 2 && step !== 3 && step !== 4) ? (
            <>
               <BlockWrapper className={signIn__wrapper}>
                  <h2 className={signIn__title}>Ð’Ñ…Ð¾Ð´ Ð² Ð»Ð¸Ñ‡Ð½Ñ‹Ð¹ ÐºÐ°Ð±Ð¸Ð½ÐµÑ‚</h2>
                  <p className={signIn__text}>Ð’Ð²ÐµÐ´Ð¸Ñ‚Ðµ Ð½Ð¾Ð¼ÐµÑ€ Ñ‚ÐµÐ»ÐµÑ„Ð¾Ð½Ð° Ð´Ð»Ñ Ð²Ñ…Ð¾Ð´Ð°</p>
                  <form className={signIn__form} onSubmit={sendPhoneNumber} action="">
                     <Input
                        className={signIn__input}
                        placeholder="ÐÐ¾Ð¼ÐµÑ€ Ñ‚ÐµÐ»ÐµÑ„Ð¾Ð½Ð°"
                        mask={"+7 (XXX) XXX-XX-XX"}
                        name="phone"
                        regex={/[0-9]/}
                        value={phone}
                        onChange={setPhone}
                        error={phoneError}
                     />
                     <Button className={signIn__button} onClick={sendPhoneNumber} disabled={phone.length !== 10}>
                        Ð”Ð°Ð»ÐµÐµ
                     </Button>
                  </form>
               </BlockWrapper>

               <p className={signIn__secondaryText}>
                  Ð£ÐºÐ°Ð·Ñ‹Ð²Ð°Ñ Ð½Ð¾Ð¼ÐµÑ€ Ñ‚ÐµÐ»ÐµÑ„Ð¾Ð½Ð°, Ð²Ñ‹ ÑÐ¾Ð³Ð»Ð°ÑˆÐ°ÐµÑ‚ÐµÑÑŒ Ð½Ð° Ð¾Ð±Ñ€Ð°Ð±Ð¾Ñ‚ÐºÑƒ <Link to="/">Ð¿ÐµÑ€ÑÐ¾Ð½Ð°Ð»ÑŒÐ½Ñ‹Ñ… Ð´Ð°Ð½Ð½Ñ‹Ñ…</Link>.
               </p>
               <p className={signIn__secondaryText}>
                  ÐžÐ±Ñ€Ð°Ñ‰Ð°ÐµÐ¼ Ð²Ð°ÑˆÐµ Ð²Ð½Ð¸Ð¼Ð°Ð½Ð¸Ðµ, Ñ‡Ñ‚Ð¾ ÑƒÐºÐ°Ð·Ð°Ð½Ð½Ñ‹Ð¹ Ð²Ð°Ð¼Ð¸ Ð½Ð¾Ð¼ÐµÑ€ Ñ‚ÐµÐ»ÐµÑ„Ð¾Ð½Ð° ÑÐ²Ð»ÑÐµÑ‚ÑÑ Ð²ÐµÑ€Ð¸Ñ„Ð¸ÐºÐ°Ñ†Ð¸Ð¾Ð½Ð½Ñ‹Ð¼, Ð¸ Ð½Ð° Ð½ÐµÐ³Ð¾ Ð±ÑƒÐ´ÑƒÑ‚
                  Ð¾Ñ‚Ð¿Ñ€Ð°Ð²Ð»ÑÑ‚ÑŒÑÑ Ð¿Ð¾Ð´Ñ‚Ð²ÐµÑ€Ð¶Ð´Ð°ÑŽÑ‰Ð¸Ðµ Ð¡ÐœÐ¡. Ð¢Ð°ÐºÐ¶Ðµ Ð¾Ð½ ÑÐ²Ð»ÑÐµÑ‚ÑÑ Ð»Ð¾Ð³Ð¸Ð½Ð¾Ð¼ Ð² Ð»Ð¸Ñ‡Ð½Ð¾Ð¼ ÐºÐ°Ð±Ð¸Ð½ÐµÑ‚.
               </p>
            </>
         ) : (
            ""
         )}
         {step === 1 ? (
            <BlockWrapper className={signIn__wrapper}>
               <h2 className={signIn__title}>Ð’Ñ…Ð¾Ð´ Ð² Ð»Ð¸Ñ‡Ð½Ñ‹Ð¹ ÐºÐ°Ð±Ð¸Ð½ÐµÑ‚</h2>
               <p className={signIn__text}>Ð’Ð²ÐµÐ´Ð¸Ñ‚Ðµ Ð¿Ð¸Ð½-ÐºÐ¾Ð´, ÑƒÑÑ‚Ð°Ð½Ð¾Ð²Ð»ÐµÐ½Ð½Ñ‹Ð¹ Ð¿Ñ€Ð¸ Ñ€ÐµÐ³Ð¸ÑÑ‚Ñ€Ð°Ñ†Ð¸Ð¸</p>
               <form className={signIn__form + " " + signIn__form_pin} onSubmit={sendPassword} action="">
                  <PincodeInput
                     length={5}
                     type="password"
                     regexp={/[0-9]/}
                     value={password}
                     onChange={setPassword}
                     invalid={passwordError}
                  />
                  <Button className={signIn__button} onClick={sendPassword} disabled={password.length !== 5}>
                     Ð’Ð¾Ð¹Ñ‚Ð¸
                  </Button>
               </form>
               <p className={signIn__link} onClick={sendResetRequest}>
                  Ð—Ð°Ð±Ñ‹Ð»Ð¸ Ð¿Ð¸Ð½-ÐºÐ¾Ð´?
               </p>
            </BlockWrapper>
         ) : (
            ""
         )}
         {step === 2 ? (
            <BlockWrapper className={signIn__wrapper}>
               <h2 className={signIn__title}>Ð’Ð¾ÑÑÑ‚Ð°Ð½Ð¾Ð²Ð»ÐµÐ½Ð¸Ðµ Ð¿Ð¸Ð½-ÐºÐ¾Ð´Ð°</h2>
               <p className={signIn__text}>
                  ÐœÑ‹ Ð¾Ñ‚Ð¿Ñ€Ð°Ð²Ð¸Ð»Ð¸ Ð¿Ð¸ÑÑŒÐ¼Ð¾ ÑÐ¾ ÑÑÑ‹Ð»ÐºÐ¾Ð¹ Ð´Ð»Ñ ÑÐ¼ÐµÐ½Ñ‹ Ð¿Ð¸Ð½-ÐºÐ¾Ð´Ð° Ð½Ð° Ð°Ð´Ñ€ÐµÑ ÑÐ»ÐµÐºÑ‚Ñ€Ð¾Ð½Ð½Ð¾Ð¹ Ð¿Ð¾Ñ‡Ñ‚Ñ‹, ÐºÐ¾Ñ‚Ð¾Ñ€Ñ‹Ð¹ Ð’Ñ‹ ÑƒÐºÐ°Ð·Ð°Ð»Ð¸ Ð¿Ñ€Ð¸
                  Ñ€ÐµÐ³Ð¸ÑÑ‚Ñ€Ð°Ñ†Ð¸Ð¸.
               </p>
               <Button href="/">Ð’ÐµÑ€Ð½ÑƒÑ‚ÑŒÑÑ Ð½Ð° Ð³Ð»Ð°Ð²Ð½ÑƒÑŽ</Button>
            </BlockWrapper>
         ) : (
            ""
         )}
         {step === 3 ? (
            <BlockWrapper className={signIn__wrapper}>
               <h2 className={signIn__title}>Ð£ÑÑ‚Ð°Ð½Ð¾Ð²Ð¸Ñ‚Ðµ Ð¿Ð¸Ð½-ÐºÐ¾Ð´</h2>
               <p className={signIn__text}>Ð­Ñ‚Ð¾Ñ‚ Ð¿Ð¸Ð½-ÐºÐ¾Ð´ Ð±ÑƒÐ´ÐµÑ‚ Ð¸ÑÐ¿Ð¾Ð»ÑŒÐ·Ð¾Ð²Ð°Ñ‚ÑŒÑÑ Ð´Ð»Ñ Ð²Ñ…Ð¾Ð´Ð° Ð² Ð’Ð°Ñˆ Ð»Ð¸Ñ‡Ð½Ñ‹Ð¹ ÐºÐ°Ð±Ð¸Ð½ÐµÑ‚</p>
               <form className={signIn__form + " " + signIn__form_pin} onSubmit={goToEmail} action="">
                  <PincodeInput length={5} type="password" regexp={/[0-9]/} value={password} onChange={setPassword} />
                  <Button className={signIn__button} onClick={goToEmail} disabled={password.length !== 5}>
                     Ð”Ð°Ð»ÐµÐµ
                  </Button>
               </form>
            </BlockWrapper>
         ) : (
            ""
         )}
         {step === 4 ? (
            <BlockWrapper className={signIn__wrapper}>
               <h2 className={signIn__title}>Ð£ÑÑ‚Ð°Ð½Ð¾Ð²Ð¸Ñ‚Ðµ ÑÐ». Ð¿Ð¾Ñ‡Ñ‚Ñƒ</h2>
               <p className={signIn__text}>
                  Ð­Ð». Ð¿Ð¾Ñ‡Ñ‚Ð° Ð±ÑƒÐ´ÐµÑ‚ Ð¸ÑÐ¿Ð¾Ð»ÑŒÐ·Ð¾Ð²Ð°Ñ‚ÑŒÑÑ Ð´Ð»Ñ Ð²Ð¾ÑÑÑ‚Ð°Ð½Ð¾Ð²Ð»ÐµÐ½Ð¸Ñ Ð¿Ð°Ñ€Ð¾Ð»Ñ Ð¸ Ð¿Ð¾Ð»ÑƒÑ‡ÐµÐ½Ð¸Ñ ÑƒÐ²ÐµÐ´Ð¾Ð¼Ð»ÐµÐ½Ð¸Ð¹
               </p>
               <form className={signIn__form} onSubmit={sendPasswordEmail} action="">
                  <Input
                     className={signIn__input}
                     placeholder="ÐÐ´Ñ€ÐµÑ ÑÐ». Ð¿Ð¾Ñ‡Ñ‚Ñ‹"
                     name="email"
                     type="email"
                     value={email}
                     onChange={setEmail}
                     error={emailError}
                  />
                  <Button className={signIn__button} onClick={sendPasswordEmail} disabled={!checkEmail(email)}>
                     Ð“Ð¾Ñ‚Ð¾Ð²Ð¾
                  </Button>
               </form>
            </BlockWrapper>
         ) : (
            ""
         )}
      </div>
   );
}

export default SignIn;