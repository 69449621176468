import EmptyData from "../../../components/EmptyData";
import Container from "../../Components/Container";

import css from "./styles.module.scss";

const {
   dashboard,
   dashboard__release
} = css;

const Dashboard = () => {
   return (
      <Container className={dashboard}>
         <div className={dashboard__release}>
            <EmptyData title="Ð£Ð¶Ðµ ÑÐºÐ¾Ñ€Ð¾!" text="Ð¢ÑƒÑ‚ Ð¿Ð¾ÑÐ²Ð¸Ñ‚ÑÑ Ð°ÐºÑ‚ÑƒÐ°Ð»ÑŒÐ½Ð°Ñ ÑÑ‚Ð°Ñ‚Ð¸ÑÑ‚Ð¸ÐºÐ° Ð¿Ð¾ Ð²ÑÐµÐ¹ ÑÐ¸ÑÑ‚ÐµÐ¼Ðµ." />
         </div>
      </Container>
   );
};

export default Dashboard;
