import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import InputRange from "../../../components/InputRange";
import checkAuth from "../../../helpers/checkAuth";
import api from "../../../helpers/fetch";
import formatPhone from "../../../helpers/formatPhone";
import getFIO from "../../../helpers/getFIO";
import Container from "../../Components/Container";
import PopUp from "../../Components/PopUp";

import scss from "./index.module.scss";

const {
   bio,
   block,
   field,
   field__wait,
   passport,
   incomes,
   actions,
   red,
   buttons,
   attantion,
   wrapper,
   wrapper__accept,
   attantion_small,
   passport_row,
   block_column,
   button_action,
} = scss;

function Application() {
   const params = useParams();

   const [applicatiobData, setStae] = useState("");

   const {
      bday,
      bplace,
      childrenCount,
      date_created,
      date_decision,
      date_end,
      date_start,
      duration,
      email,
      familyCount,
      familyState,
      id_application,
      id_client,
      id_employer,
      id_loan,
      id_user,
      income,
      inn,
      isOnReg,
      jobAddress,
      jobPhone,
      jobPlace,
      jobPosition,
      jobType,
      liveWithParents,
      living,
      loans,
      midname,
      name,
      nextIncome,
      number,
      pasportDateGet,
      pasportOrg,
      pasportOrgCode,
      pasport,
      photo1,
      photo2,
      photo3,
      photo4,
      rate,
      registration,
      status,
      surname,
      total_amount,
      phone_number,
   } = applicatiobData;

   const data = {};
   const navigate = useNavigate();

   const [ranges, setRanges] = useState(rate);
   const [amounts, setAmounts] = useState("");
   const [amountError, setAmountError] = useState("");
   const [error, setError] = useState(false);
   const [numbers, setNumbers] = useState("");
   const [numbersError, setNumbersError] = useState("");
   const amountArray = [];
   for (let i = 2000; i <= 100000; i += 1000) amountArray.push(i);
   const [durations, setDurations] = useState(
      data.duration || data.duration === 0 ? data.duration : 7
   );
   const durationArray = [];
   for (let i = 7; i <= 30; i++) durationArray.push(i);
   const [isActiveDismiss, setisActiveDismiss] = useState(false);
   const [errorDenied, setErrorDenied] = useState(false);
   const [wait, setWait] = useState(false);

   const loansArray = [
      "ÐÐµÑ‚ Ð¿Ð»Ð°Ñ‚ÐµÐ¶ÐµÐ¹ Ð¿Ð¾ ÐºÑ€ÐµÐ´Ð¸Ñ‚Ð°Ð¼",
      "Ð”Ð¾ 10 000 â‚½",
      "10 000 â‚½ - 20 000 â‚½",
      "20 000 â‚½ - 35 000 â‚½",
      "35 000 â‚½ - 50 000 â‚½",
      "Ð‘Ð¾Ð»ÐµÐµ 50 000 â‚½",
   ];

   const incomeArray = [
      "Ð”Ð¾ 10 000 â‚½",
      "10 000 â‚½ - 20 000 â‚½",
      "20 000 â‚½ - 35 000 â‚½",
      "35 000 â‚½ - 50 000 â‚½",
      "Ð‘Ð¾Ð»ÐµÐµ 50 000 â‚½",
   ];

   const nextIncomeArray = [
      "1Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "2Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "3Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "4Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "5Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "6Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "7Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "8Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "9Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "10Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "11Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "12Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "13Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "14Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "15Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "16Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "17Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "18Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "19Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "20Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "21Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "22Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "23Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "24Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "25Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "26Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "27Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "28Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "29Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "30Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "31Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
   ];

   const jobTypeArray = ["Ð Ð°Ð±Ð¾Ñ‚Ð°ÑŽ", "ÐÐµ Ñ€Ð°Ð±Ð¾Ñ‚Ð°ÑŽ", "ÐŸÐµÐ½ÑÐ¸Ð¾Ð½ÐµÑ€"];

   const onCloaseDismiss = () => {
      document.body.style.overflowY = null;
      setisActiveDismiss(!isActiveDismiss);
   };

   const onOpenDismiss = () => {
      document.body.style.overflowY = "hidden";
      setisActiveDismiss(!isActiveDismiss);
   };

   const [isActiveAccept, setisActiveAccept] = useState(false);

   const onCloaseAccept = () => {
      document.body.style.overflowY = null;
      setisActiveAccept(!isActiveAccept);
      setAmountError("");
      setNumbersError("");
   };

   const onOpenAccept = () => {
      document.body.style.overflowY = "hidden";
      setisActiveAccept(!isActiveAccept);
   };

   const getData = async () => {
      const auth = checkAuth();
      const response = await new api(`manage/applications/${params.id}`, auth).send();
      setStae(response.data[0]);
      setAmounts(response.data[0].total_amount / 100);
      setDurations(response.data[0].duration);
      setRanges(response.data[0].rate);
   };

   const getPasport = () => {
      if (pasport) {
         let pas = pasport.toString();
         return pas.slice(0, 4) + "-" + pas.slice(4, 10);
      } else return 0;
   };

   const getCode = () => {
      if (pasportOrgCode) {
         let pas = pasportOrgCode.toString();
         return pas.slice(0, 3) + "-" + pas.slice(3, 6);
      } else return 0;
   };

   const onSubmitDismiss = async (e) => {
      e.preventDefault();
      setWait(true);
      const auth = checkAuth();
      const response = await new api(`manage/loans/${id_loan}/decline`, auth, {
         method: "PUT",
      }).send();
      if (response.status === "success") {
         onCloaseDismiss();
         setWait(false);
         navigate("/manage/applications");
      } else {
         setErrorDenied(true);
         setWait(false);
      }
      onCloaseDismiss();
      setWait(false);
   };

   const onSubmit = async (e) => {
      e.preventDefault();
      setWait(true);
      setAmountError(
         amounts.toString().trim().length > 0 ? "" : "ÐŸÐ¾Ð»Ðµ Ð´Ð¾Ð»Ð¶Ð½Ð¾ Ð±Ñ‹Ñ‚ÑŒ Ð·Ð°Ð¿Ð¾Ð»Ð½ÐµÐ½Ñ‹Ð¼!"
      );
      setAmountError(parseInt(amounts) <= 100000 ? "" : "Ð¡Ð»Ð¸ÑˆÐºÐ¾Ð¼ Ð±Ð¾Ð»ÑŒÑˆÐ¾Ðµ Ð·Ð½Ð°Ñ‡ÐµÐ½Ð¸Ðµ");
      setNumbersError(
         numbers.toString().trim().length > 0 ? "" : "ÐŸÐ¾Ð»Ðµ Ð´Ð¾Ð»Ð¶Ð½Ð¾ Ð±Ñ‹Ñ‚ÑŒ Ð·Ð°Ð¿Ð¾Ð»Ð½ÐµÐ½Ñ‹Ð¼!"
      );
      if (
         amounts.toString().trim().length > 0 &&
         numbers.toString().trim().length > 0 &&
         parseInt(amounts) <= 100000
      ) {
         const auth = checkAuth();
         const body = JSON.stringify({
            amount: amounts * 100,
            duration: durations,
            rate: parseFloat(ranges),
            number: numbers,
         });
         const response = await new api(`manage/loans/${id_loan}/accept`, auth, {
            method: "PUT",
            body,
         }).send();
         if (response.status === "success") {
            onCloaseAccept();
            setWait(false);
            navigate("/manage/applications");
         } else {
            setErrorDenied(true);
            setWait(false);
         }
      }
      setWait(false);
   };

   const familyCountArray = ["1", "2", "3", "4", "5", "Ð‘Ð¾Ð»ÐµÐµ 5"];

   const familyStateArray = [
      "Ð¥Ð¾Ð»Ð¾ÑÑ‚ / Ð½Ðµ Ð·Ð°Ð¼ÑƒÐ¶ÐµÐ¼",
      "Ð“Ñ€Ð°Ð¶Ð´Ð°Ð½ÑÐºÐ¸Ð¹ Ð±Ñ€Ð°Ðº",
      "Ð–ÐµÐ½Ð°Ñ‚ / Ð·Ð°Ð¼ÑƒÐ¶ÐµÐ¼",
      "Ð’ Ñ€Ð°Ð·Ð²Ð¾Ð´Ðµ",
   ];

   const childrenCountArray = ["ÐÐµÑ‚", "1", "2", "3", "4", "5", "Ð‘Ð¾Ð»ÐµÐµ 5"];

   useEffect(() => {
      getData();
   }, []);

   return (
      <>
         <Container>
            <h2>Ð‘Ð¸Ð¾Ð³Ñ€Ð°Ñ„Ð¸Ñ‡ÐµÑÐºÐ¸Ðµ Ð´Ð°Ð½Ð½Ñ‹Ðµ</h2>
            <div className={bio}>
               <div className={block}>
                  <span>Ð¤.Ð˜.Ðž:</span>
                  <span
                     className={name ? field : field__wait}
                     onClick={() =>
                        navigator.clipboard.writeText(
                           getFIO(name, surname, midname, "ser-name-mid")
                        )
                     }
                  >
                     {name ? getFIO(name, surname, midname, "ser-name-mid") : null}
                  </span>
               </div>
               <div className={block}>
                  <span>ÐÐ¾Ð¼ÐµÑ€ Ñ‚ÐµÐ»ÐµÑ„Ð¾Ð½Ð°:</span>
                  <a
                     href={"tel:+" + phone_number}
                     title={"ÐŸÐ¾Ð·Ð²Ð¾Ð½Ð¸Ñ‚ÑŒ Ð¿Ð¾ Ð½Ð¾Ð¼ÐµÑ€Ñƒ " + phone_number}
                     className={phone_number ? field : field__wait}
                     onClick={() => navigator.clipboard.writeText(phone_number)}
                  >
                     {phone_number ? formatPhone(phone_number) : null}
                  </a>
               </div>
               <div className={block}>
                  <span>E-mail:</span>
                  <a
                     href={"mailto:" + email}
                     title={"ÐÐ°Ð¿Ð¸ÑÐ°Ñ‚ÑŒ Ð½Ð° Ð°Ð´Ñ€ÐµÑ ÑÐ»ÐµÑ‚Ñ€Ð¾Ð½Ð½Ð¾Ð¹ Ð¿Ð¾Ñ‡Ñ‚Ñ‹ " + email}
                     className={email ? field : field__wait}
                     onClick={() => navigator.clipboard.writeText(email)}
                  >
                     {email ? email : null}
                  </a>
               </div>
            </div>
         </Container>
         <Container>
            <h2>ÐŸÐ°ÑÐ¿Ð¾Ñ€Ñ‚Ð½Ñ‹Ðµ Ð´Ð°Ð½Ð½Ñ‹Ðµ</h2>
            <div className={passport}>
               <div className={block}>
                  <span>ÐŸÐ°ÑÐ¿Ð¾Ñ€Ñ‚:</span>
                  <span
                     className={pasport ? field : field__wait}
                     onClick={() => navigator.clipboard.writeText(pasport)}
                  >
                     {pasport ? getPasport() : null}
                  </span>
               </div>
               <div className={block}>
                  <span>Ð”Ð°Ñ‚Ð° Ñ€Ð¾Ð¶Ð´ÐµÐ½Ð¸Ñ:</span>
                  <span
                     className={bday ? field : field__wait}
                     onClick={() => navigator.clipboard.writeText(bday)}
                  >
                     {bday ? new Date(bday).toLocaleDateString("ru-RU") : null}
                  </span>
               </div>
               <div className={block}>
                  <span>ÐœÐµÑÑ‚Ð¾ Ñ€Ð¾Ð¶Ð´ÐµÐ½Ð¸Ñ:</span>
                  <span
                     className={bplace ? field : field__wait}
                     onClick={() => navigator.clipboard.writeText(bplace)}
                  >
                     {bplace ? bplace : null}
                  </span>
               </div>
               <div className={block}>
                  <span>ÐšÐ¾Ð´ Ð¿Ð¾Ð´Ñ€Ð°Ð·Ð´ÐµÐ»ÐµÐ½Ð¸Ñ:</span>
                  <span
                     className={pasportOrgCode ? field : field__wait}
                     onClick={() => navigator.clipboard.writeText(pasportOrgCode)}
                  >
                     {pasportOrgCode ? getCode(pasportOrgCode) : null}
                  </span>
               </div>
               <div className={block}>
                  <span>Ð”Ð°Ñ‚Ð° Ð²Ñ‹Ð´Ð°Ñ‡Ð¸:</span>
                  <span
                     className={pasportDateGet ? field : field__wait}
                     onClick={() => navigator.clipboard.writeText(pasportDateGet)}
                  >
                     {pasportDateGet
                        ? new Date(pasportDateGet).toLocaleDateString("ru-RU")
                        : null}
                  </span>
               </div>
               <div className={block}>
                  <span>ÐšÐµÐ¼ Ð²Ñ‹Ð´Ð°Ð½Ð¾:</span>
                  <span
                     className={pasportOrg ? field : field__wait}
                     onClick={() => navigator.clipboard.writeText(pasportOrg)}
                  >
                     {pasportOrg ? pasportOrg : null}
                  </span>
               </div>
               {inn ? (
                  <>
                     <hr />
                     <div className={block}>
                        <span>Ð˜ÐÐ:</span>
                        <span
                           className={inn ? field : field__wait}
                           onClick={() => navigator.clipboard.writeText(inn)}
                        >
                           {inn ? inn : null}
                        </span>
                     </div>
                     <hr />
                  </>
               ) : null}
               <div className={block}>
                  <span>ÐÐ´Ñ€ÐµÑ Ñ€ÐµÐ³Ð¸ÑÑ‚Ñ€Ð°Ñ†Ð¸Ð¸:</span>
                  <span
                     className={registration ? field : field__wait}
                     onClick={() => navigator.clipboard.writeText(registration)}
                  >
                     {registration ? registration : null}
                  </span>
               </div>
               {isOnReg === 1 ? (
                  <div className={block}>
                     <span>ÐÐ´Ñ€ÐµÑ Ð¿Ñ€Ð¾Ð¶Ð¸Ð²Ð°Ð½Ð¸Ñ:</span>
                     <span
                        className={living ? field : field__wait}
                        onClick={() => navigator.clipboard.writeText(living)}
                     >
                        {living ? living : null}
                     </span>
                  </div>
               ) : null}
               <hr />
               <div className={passport_row}>
                  <div className={block + " " + block_column}>
                     <span>Ð¤Ð¾Ñ‚Ð¾Ð³Ñ€Ð°Ñ„Ð¸Ñ Ñ€Ð°Ð·Ð²Ð¾Ñ€Ð¾Ñ‚Ð°:</span>
                     <a
                        href={photo1}
                        target={"_blank"}
                        title={"ÐžÑ‚ÐºÑ€Ñ‹Ñ‚ÑŒ Ð² Ð½Ð¾Ð²Ð¾Ð¹ Ð²ÐºÐ»Ð°Ð´ÐºÐµ " + photo1}
                        rel="noreferrer"
                        className={photo1 ? field : field__wait}
                     >
                        <img
                           src={
                              photo1
                                 ? photo1
                                 : "https://upload.wikimedia.org/wikipedia/commons/4/46/%D0%A1%D0%B5%D1%80%D1%8B%D0%B9_%D1%86%D0%B2%D0%B5%D1%82-_2014-03-15_18-16.jpg"
                           }
                           alt="Ð¤Ð¾Ñ‚Ð¾Ð³Ñ€Ð°Ñ„Ð¸Ñ Ñ€Ð°Ð·Ð²Ð¾Ñ€Ð¾Ñ‚Ð°"
                           width={"256px"}
                           height={"128px"}
                        />
                     </a>
                  </div>
                  <div className={block + " " + block_column}>
                     <span>Ð¤Ð¾Ñ‚Ð¾Ð³Ñ€Ð°Ñ„Ð¸Ñ Ñ€ÐµÐ³Ð¸ÑÑ‚Ñ€Ð°Ñ†Ð¸Ð¸:</span>
                     <a
                        href={photo2}
                        target={"_blank"}
                        title={"ÐžÑ‚ÐºÑ€Ñ‹Ñ‚ÑŒ Ð² Ð½Ð¾Ð²Ð¾Ð¹ Ð²ÐºÐ»Ð°Ð´ÐºÐµ " + photo2}
                        rel="noreferrer"
                        className={photo2 ? field : field__wait}
                     >
                        <img
                           src={
                              photo2
                                 ? photo2
                                 : "https://upload.wikimedia.org/wikipedia/commons/4/46/%D0%A1%D0%B5%D1%80%D1%8B%D0%B9_%D1%86%D0%B2%D0%B5%D1%82-_2014-03-15_18-16.jpg"
                           }
                           alt="Ð¤Ð¾Ñ‚Ð¾Ð³Ñ€Ð°Ñ„Ð¸Ñ Ñ€ÐµÐ³Ð¸ÑÑ‚Ñ€Ð°Ñ†Ð¸Ð¸"
                           width={"256px"}
                           height={"128px"}
                        />
                     </a>
                  </div>
                  <div className={block + " " + block_column}>
                     <span>Ð¤Ð¾Ñ‚Ð¾Ð³Ñ€Ð°Ñ„Ð¸Ñ Ñ Ð¿Ð°ÑÐ¿Ð¾Ñ€Ñ‚Ð¾Ð¼:</span>
                     <a
                        href={photo3}
                        target={"_blank"}
                        title={"ÐžÑ‚ÐºÑ€Ñ‹Ñ‚ÑŒ Ð² Ð½Ð¾Ð²Ð¾Ð¹ Ð²ÐºÐ»Ð°Ð´ÐºÐµ " + photo3}
                        rel="noreferrer"
                        className={photo3 ? field : field__wait}
                     >
                        <img
                           src={
                              photo3
                                 ? photo3
                                 : "https://upload.wikimedia.org/wikipedia/commons/4/46/%D0%A1%D0%B5%D1%80%D1%8B%D0%B9_%D1%86%D0%B2%D0%B5%D1%82-_2014-03-15_18-16.jpg"
                           }
                           alt="Ð¤Ð¾Ñ‚Ð¾Ð³Ñ€Ð°Ñ„Ð¸Ñ Ñ Ð¿Ð°ÑÐ¿Ð¾Ñ€Ñ‚Ð¾Ð¼"
                           width={"256px"}
                           height={"128px"}
                        />
                     </a>
                  </div>
                  <div className={block + " " + block_column}>
                     <span>Ð¤Ð¾Ñ‚Ð¾Ð³Ñ€Ð°Ñ„Ð¸Ñ Ð¡ÐÐ˜Ð›Ð¡Ð°:</span>
                     <a
                        href={photo4}
                        target={"_blank"}
                        title={"ÐžÑ‚ÐºÑ€Ñ‹Ñ‚ÑŒ Ð² Ð½Ð¾Ð²Ð¾Ð¹ Ð²ÐºÐ»Ð°Ð´ÐºÐµ " + photo4}
                        rel="noreferrer"
                        className={photo4 ? field : field__wait}
                     >
                        <img
                           src={
                              photo4
                                 ? photo4
                                 : "https://upload.wikimedia.org/wikipedia/commons/4/46/%D0%A1%D0%B5%D1%80%D1%8B%D0%B9_%D1%86%D0%B2%D0%B5%D1%82-_2014-03-15_18-16.jpg"
                           }
                           alt="Ð¤Ð¾Ñ‚Ð¾Ð³Ñ€Ð°Ñ„Ð¸Ñ Ð¡ÐÐ˜Ð›Ð¡Ð°"
                           width={"256px"}
                           height={"128px"}
                        />
                     </a>
                  </div>
               </div>
               <hr />
               <div className={block}>
                  <span>Ð¡ÐµÐ¼ÐµÐ¹Ð½Ð¾Ðµ Ð¿Ð¾Ð»Ð¾Ð¶ÐµÐ½Ð¸Ðµ:</span>
                  <span
                     className={familyState || familyState === 0 ? field : field__wait}
                     onClick={() => navigator.clipboard.writeText(familyState)}
                  >
                     {familyState || familyState === 0
                        ? familyStateArray[familyState]
                        : null}
                  </span>
               </div>
               <div className={block}>
                  <span>ÐšÐ¾Ð»-Ð²Ð¾ Ñ‡Ð»ÐµÐ½Ð¾Ð² ÑÐµÐ¼ÑŒÐ¸:</span>
                  <span
                     className={familyCount || familyCount === 0 ? field : field__wait}
                     onClick={() => navigator.clipboard.writeText(familyCount)}
                  >
                     {familyCount || familyCount === 0
                        ? familyCountArray[familyCount]
                        : null}
                  </span>
               </div>
               <div className={block}>
                  <span>ÐšÐ¾Ð»-Ð²Ð¾ Ð´ÐµÑ‚ÐµÐ¹ Ð´Ð¾ 18 Ð»ÐµÑ‚:</span>
                  <span
                     className={
                        childrenCount || childrenCount === 0 ? field : field__wait
                     }
                     onClick={() => navigator.clipboard.writeText(childrenCount)}
                  >
                     {childrenCount || childrenCount === 0
                        ? childrenCountArray[childrenCount]
                        : null}
                  </span>
               </div>
               <div className={block}>
                  <span>ÐŸÑ€Ð¾Ð¶Ð¸Ð²Ð°ÑŽ Ñ Ñ€Ð¾Ð´Ð¸Ñ‚ÐµÐ»ÑÐ¼Ð¸:</span>
                  <span
                     className={
                        liveWithParents || liveWithParents === 0 ? field : field__wait
                     }
                     onClick={() =>
                        navigator.clipboard.writeText(
                           liveWithParents || liveWithParents === 0
                              ? liveWithParents === 0
                                 ? "Ð”Ð°"
                                 : "ÐÐµÑ‚"
                              : null
                        )
                     }
                  >
                     {liveWithParents || liveWithParents === 0
                        ? liveWithParents === 0
                           ? "Ð”Ð°"
                           : "ÐÐµÑ‚"
                        : null}
                  </span>
               </div>
            </div>
         </Container>
         <Container>
            <h2>Ð”Ð°Ð½Ð½Ñ‹Ðµ Ð¾ Ð´Ð¾Ñ…Ð¾Ð´Ðµ</h2>
            <div className={incomes}>
               <div className={block}>
                  <span>Ð’Ð¸Ð´ Ð·Ð°Ð½ÑÑ‚Ð¾ÑÑ‚Ð¸:</span>
                  <span
                     className={jobType !== "" ? field : field__wait}
                     onClick={
                        jobType !== ""
                           ? () => navigator.clipboard.writeText(jobTypeArray[jobType])
                           : () => {}
                     }
                  >
                     {jobType !== "" ? jobTypeArray[jobType] : "ÐÐµÑ‚ Ð´Ð°Ð½Ð½Ñ‹Ñ…"}
                  </span>
               </div>
               {jobType === 0 ? (
                  <>
                     <div className={block}>
                        <span>ÐœÐµÑÑ‚Ð¾ Ñ€Ð°Ð±Ð¾Ñ‚Ñ‹:</span>
                        <span
                           className={jobPlace ? field : field__wait}
                           onClick={() => navigator.clipboard.writeText(jobPlace)}
                           dangerouslySetInnerHTML={{
                              __html: jobPlace ? jobPlace : null,
                           }}
                        ></span>
                     </div>
                     <div className={block}>
                        <span>Ð”Ð¾Ð»Ð¶Ð½Ð¾ÑÑ‚ÑŒ:</span>
                        <span
                           className={jobPosition ? field : field__wait}
                           onClick={() => navigator.clipboard.writeText(jobPosition)}
                        >
                           {jobPosition ? jobPosition : null}
                        </span>
                     </div>
                     <div className={block}>
                        <span>ÐÐ´Ñ€ÐµÑ Ð¾Ñ€Ð³Ð°Ð½Ð¸Ð·Ð°Ñ†Ð¸Ð¸:</span>
                        <span
                           className={jobAddress ? field : field__wait}
                           onClick={() => navigator.clipboard.writeText(jobAddress)}
                        >
                           {jobAddress ? jobAddress : null}
                        </span>
                     </div>
                     <div className={block}>
                        <span>ÐÐ¾Ð¼ÐµÑ€ Ñ‚ÐµÐ»ÐµÑ„Ð¾Ð½Ð°:</span>
                        <a
                           href={"tel:+" + jobPhone}
                           title={"ÐŸÐ¾Ð·Ð²Ð¾Ð½Ð¸Ñ‚ÑŒ Ð¿Ð¾ Ð½Ð¾Ð¼ÐµÑ€Ñƒ " + jobPhone}
                           className={jobPhone ? field : field__wait}
                        >
                           {jobPhone ? formatPhone(jobPhone) : null}
                        </a>
                     </div>
                     <div className={block}>
                        <span>Ð‘Ð»Ð¸Ð¶Ð°Ð¹ÑˆÐ°Ñ Ð²Ñ‹Ð¿Ð»Ð°Ñ‚Ð°:</span>
                        <span
                           className={
                              nextIncome || nextIncome === 0 ? field : field__wait
                           }
                           onClick={() =>
                              navigator.clipboard.writeText(
                                 nextIncome || nextIncome === 0
                                    ? nextIncomeArray[nextIncome]
                                    : ""
                              )
                           }
                        >
                           {nextIncome || nextIncome === 0
                              ? nextIncomeArray[nextIncome]
                              : null}
                        </span>
                     </div>
                  </>
               ) : null}
               <div className={block}>
                  <span>Ð•Ð¶ÐµÐ¼ÐµÑÑÑ‡Ð½Ñ‹Ð¹ Ð´Ð¾Ñ…Ð¾Ð´Ðµ:</span>
                  <span
                     className={income || income === 0 ? field : field__wait}
                     onClick={
                        income || income === 0
                           ? () => navigator.clipboard.writeText(incomeArray[income])
                           : () => {}
                     }
                  >
                     {income || income === 0 ? incomeArray[income] : null}
                  </span>
               </div>
               <div className={block}>
                  <span>ÐšÑ€ÐµÐ´Ð¸Ñ‚Ð½Ñ‹Ðµ Ð¾Ð±ÑÐ·Ð°Ñ‚ÐµÐ»ÑŒÑÑ‚Ð²Ð°:</span>
                  <span
                     className={loans || loans === 0 ? field : field__wait}
                     onClick={() =>
                        navigator.clipboard.writeText(
                           loans || loans === 0 ? loansArray[loans] : ""
                        )
                     }
                  >
                     {loans || loans === 0 ? loansArray[loans] : null}
                  </span>
               </div>
               <hr />
               <div className={block}>
                  <span>Ð–ÐµÐ»Ð°ÐµÐ¼Ð°Ñ ÑÑƒÐ¼Ð¼Ð°:</span>
                  <span
                     className={total_amount ? field : field__wait}
                     onClick={() => navigator.clipboard.writeText(total_amount / 100)}
                  >
                     {total_amount
                        ? (total_amount / 100).toLocaleString("ru-RU") + " â‚½"
                        : null}
                  </span>
               </div>
               <div className={block}>
                  <span>Ð–ÐµÐ»Ð°ÐµÐ¼Ñ‹Ð¹ ÑÑ€Ð¾Ðº:</span>
                  <span
                     className={duration ? field : field__wait}
                     onClick={() => navigator.clipboard.writeText(duration)}
                  >
                     {duration ? duration : null}
                  </span>
               </div>
            </div>
         </Container>
         <div className={actions}>
            <Button
               isWaiting={!name ? true : false}
               variant={"gray"}
               onClick={() => onOpenDismiss()}
            >
               ÐžÑ‚ÐºÐ»Ð¾Ð½Ð¸Ñ‚ÑŒ Ð·Ð°ÑÐ²ÐºÑƒ
            </Button>
            <Button isWaiting={!name ? true : false} onClick={() => onOpenAccept()}>
               ÐŸÐµÑ€ÐµÐ¹Ñ‚Ð¸ Ðº Ð¾Ñ„Ð¾Ñ€Ð¼Ð»ÐµÐ½Ð¸ÑŽ
            </Button>
         </div>
         {isActiveDismiss ? (
            <PopUp title={"ÐŸÐ¾Ð´Ñ‚Ð²ÐµÑ€Ð´Ð¸Ñ‚Ðµ ÑƒÑÐ»Ð¾Ð²Ð¸Ñ Ð·Ð°Ð¹Ð¼Ð°"} onCloase={onCloaseDismiss}>
               <form action="" onSubmit={onSubmitDismiss} className={wrapper}>
                  {!errorDenied ? (
                     <p className={attantion}>
                        Ð’ Ð·Ð°Ð¹Ð¼Ðµ Ð±ÑƒÐ´ÐµÑ‚ Ð¾Ñ‚ÐºÐ°Ð·Ð°Ð½Ð¾. Ð•ÑÐ»Ð¸ Ð²Ñ‹ Ð¿Ð¾Ð´Ñ‚Ð²ÐµÑ€Ð¶Ð´Ð°ÐµÑ‚Ðµ Ð¾Ñ‚ÐºÐ°Ð· Ð² Ð·Ð°Ð¹Ð¼Ðµ, Ñ‚Ð¾
                        Ð´ÐµÐ¹ÑÑ‚Ð²Ð¸Ðµ Ð±ÑƒÐ´ÐµÑ‚ Ð½ÐµÐ¾Ð±Ñ€Ð°Ñ‚Ð¸Ð¼Ð¾. <br />
                        <br /> Ð’Ñ‹ Ð¿Ð¾Ð´Ñ‚Ð²ÐµÑ€Ð¶Ð´Ð°ÐµÑ‚Ðµ Ð²Ñ‹Ð±Ð¾Ñ€?
                     </p>
                  ) : (
                     <>
                        <p className={attantion}>Ð§Ñ‚Ð¾-Ñ‚Ð¾ Ð¿Ð¾ÑˆÐ»Ð¾ Ð½Ðµ Ð¿Ð¾ Ð¿Ð»Ð°Ð½Ñƒ!</p>
                        <p className={attantion}>
                           ÐŸÑ€Ð¾Ð±ÑƒÐ¹Ñ‚Ðµ Ð¿Ð¾Ð·Ð¶Ðµ Ð¸Ð»Ð¸ Ð¾Ð±Ñ€Ð°Ñ‚Ð¸Ñ‚ÐµÑÑŒ Ð² ÑÐ»ÑƒÐ¶Ð±Ñƒ Ð¿Ð¾Ð´Ð´ÐµÑ€Ð¶ÐºÐ¸.
                        </p>
                     </>
                  )}
                  <div className={buttons}>
                     <Button variant={"gray"} onClick={() => onCloaseDismiss()}>
                        ÐžÑ‚Ð¼ÐµÐ½Ð°
                     </Button>
                     {!errorDenied ? (
                        <Button isWaiting={wait} className={red} type={"submit"}>
                           ÐžÑ‚ÐºÐ°Ð·Ð°Ñ‚ÑŒ Ð² Ð·Ð°Ð¹Ð¼Ðµ
                        </Button>
                     ) : (
                        <Button onClick={() => window.location.reload(false)}>
                           ÐžÐ±Ð½Ð¾Ð²Ð¸Ñ‚ÑŒ ÑÑ‚Ñ€Ð°Ð½Ð¸Ñ†Ñƒ
                        </Button>
                     )}
                  </div>
               </form>
            </PopUp>
         ) : null}
         {isActiveAccept ? (
            <PopUp title={"ÐŸÐ¾Ð´Ñ‚Ð²ÐµÑ€Ð´Ð¸Ñ‚Ðµ ÑƒÑÐ»Ð¾Ð²Ð¸Ñ Ð·Ð°Ð¹Ð¼Ð°"} onCloase={onCloaseAccept}>
               <form action="" onSubmit={onSubmit} className={wrapper__accept}>
                  {!error ? (
                     <>
                        <Input
                           label={"ÐÐ¾Ð¼ÐµÑ€ Ð´Ð¾Ð³Ð¾Ð²Ð¾Ñ€Ð°"}
                           placeholder={"123456"}
                           className={field}
                           type={"text"}
                           regex={/^[0-9\-]{1,}$/}
                           value={numbers}
                           onChange={setNumbers}
                           error={numbersError}
                        />
                        <Input
                           label={"ÐŸÑ€ÐµÐ´Ð»Ð°Ð³Ð°ÐµÐ¼Ð°Ñ ÑÑƒÐ¼Ð¼Ð°"}
                           placeholder={"10000"}
                           className={field}
                           type={"number"}
                           value={amounts}
                           onChange={setAmounts}
                           error={amountError}
                        />
                        <InputRange
                           label={"ÐŸÑ€ÐµÐ´Ð»Ð°Ð³Ð°ÐµÐ¼Ñ‹Ð¹ ÑÑ€Ð¾Ðº"}
                           length={30}
                           showValue={durations + " Ð´Ð½ÐµÐ¹"}
                           step={1}
                           min={7}
                           value={durations}
                           onChange={setDurations}
                        />
                        <InputRange
                           label={"ÐŸÑ€ÐµÐ´Ð»Ð°Ð³Ð°ÐµÐ¼Ñ‹Ð¹ Ð¿Ñ€Ð¾Ñ†ÐµÐ½Ñ‚"}
                           length={1.0}
                           showValue={ranges + " %"}
                           step={0.1}
                           min={0.0}
                           value={ranges}
                           onChange={setRanges}
                        />
                        <p className={attantion_small}>
                           Ð•ÑÐ»Ð¸ Ñ…Ð¾Ñ‚Ð¸Ñ‚Ðµ Ð¿Ñ€ÐµÐ´Ð»Ð¾Ð¶Ð¸Ñ‚ÑŒ Ð´Ñ€ÑƒÐ³Ð¸Ðµ ÑƒÑÐ»Ð¾Ð²Ð¸Ñ, Ñ‚Ð¾ Ð¸Ð·Ð¼ÐµÐ½Ð¸Ñ‚Ðµ ÑƒÑÐ»Ð¾Ð²Ð¸Ñ
                           Ð·Ð°Ð¹Ð¼Ð° ÑÐµÐ¹Ñ‡Ð°Ñ. Ð›Ð¸Ð±Ð¾, Ð¾ÑÑ‚Ð°Ð²ÑŒÑ‚Ðµ Ð²ÑÐµ ÐºÐ°Ðº ÐµÑÑ‚ÑŒ.
                        </p>
                     </>
                  ) : (
                     <>
                        <p className={attantion_small}>Ð§Ñ‚Ð¾-Ñ‚Ð¾ Ð¿Ð¾ÑˆÐ»Ð¾ Ð½Ðµ Ð¿Ð¾ Ð¿Ð»Ð°Ð½Ñƒ!</p>
                        <p className={attantion_small}>
                           ÐŸÑ€Ð¾Ð±ÑƒÐ¹Ñ‚Ðµ Ð½ÐµÐ¼Ð½Ð¾Ð³Ð¾ Ð¿Ð¾Ð·Ð¶Ðµ Ð¸Ð»Ð¸ Ð¾Ð±Ñ€Ð°Ñ‚Ð¸Ñ‚ÐµÑÑŒ Ð² ÑÐ»ÑƒÐ¶Ð±Ñƒ Ð¿Ð¾Ð´Ð´ÐµÑ€Ð¶ÐºÐ¸.
                        </p>
                     </>
                  )}
                  <div className={buttons}>
                     <Button variant={"gray"} onClick={() => onCloaseAccept()}>
                        ÐžÑ‚Ð¼ÐµÐ½Ð°
                     </Button>
                     {!error ? (
                        <Button isWaiting={wait} type={"submit"}>
                           ÐžÐ´Ð¾Ð±Ñ€Ð¸Ñ‚ÑŒ Ð·Ð°ÑÐ²ÐºÑƒ
                        </Button>
                     ) : (
                        <Button onClick={() => window.location.reload(false)}>
                           ÐžÐ±Ð½Ð¾Ð²Ð¸Ñ‚ÑŒ ÑÑ‚Ñ€Ð°Ð½Ð¸Ñ†Ñƒ
                        </Button>
                     )}
                  </div>
               </form>
            </PopUp>
         ) : null}
      </>
   );
}

export default Application;
