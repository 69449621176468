import React from "react";
import scss from "./index.module.scss";

const { useState, useEffect } = React;
const { timer, timer__block, timer__number, timer__label } = scss;

function Timer({ date }) {
   const [finishTime] = useState(date);
   const [[diffDays, diffH, diffM, diffS], setDiff] = useState([0, 0, 0, 0]);
   const [tick, setTick] = useState(false);

   useEffect(() => {
      const diff = (finishTime - new Date()) / 1000;
      if (diff < 0) return; // Ð²Ñ€ÐµÐ¼Ñ Ð²Ñ‹ÑˆÐ»Ð¾
      setDiff([
         Math.floor(diff / 86400), // Ð´Ð½Ð¸
         Math.floor((diff / 3600) % 24),
         Math.floor((diff / 60) % 60),
         Math.floor(diff % 60),
      ]);
   }, [tick, finishTime]);

   useEffect(() => {
      const timerID = setInterval(() => setTick(!tick), 1000);
      return () => clearInterval(timerID);
   }, [tick]);

   return (
      <time className={timer}>
         <div className={timer__block}>
            <span className={timer__number}>{`${diffH.toString().padStart(2, "0")}`}</span>
            <span className={timer__label}>Ñ‡Ð°ÑÐ¾Ð²</span>
         </div>
         <div className={timer__block}>
            <span className={timer__number}>{`${diffM.toString().padStart(2, "0")}`}</span>
            <span className={timer__label}>Ð¼Ð¸Ð½ÑƒÑ‚</span>
         </div>
         <div className={timer__block}>
            <span className={timer__number}>{`${diffS.toString().padStart(2, "0")}`}</span>
            <span className={timer__label}>ÑÐµÐºÑƒÐ½Ð´</span>
         </div>
      </time>
   );
}

export default Timer;
