import Button from "../../../components/Button";
import scss from "./index.module.scss";

const { cards__text, cards__grid, cards__grid_white, cards__grid_actions } = scss;

function CurrentCard(status, loan) {
   switch (status) {
      case 1:
      case 2:
      case 3: {
         return <span className={cards__text}>Ð£ Ð²Ð°Ñ Ð½ÐµÑ‚ Ð°ÐºÑ‚Ð¸Ð²Ð½Ñ‹Ñ… Ð·Ð°ÑÐ²Ð¾Ðº</span>;
      }
      case 4: {
         return (
            <>
               <div className={cards__grid + " " + cards__grid_white}>
                  <span>
                     Ð¡ÑƒÐ¼Ð¼Ð° Ð·Ð°Ð¹Ð¼Ð°:{" "}
                     <strong>
                        {parseFloat(loan.current.total / 100).toLocaleString("ru-RU")} â‚½
                     </strong>
                  </span>
                  <span>
                     Ð¡Ñ‚Ð°Ð²ÐºÐ° Ð² Ð´ÐµÐ½ÑŒ:{" "}
                     <strong>{loan.current.rate.toLocaleString("ru-RU")}%</strong>
                  </span>
               </div>
               <div
                  className={
                     cards__grid + " " + cards__grid_white + " " + cards__grid_actions
                  }
               >
                  <Button
                     variant={"white"}
                     href="/payment-method/total"
                     disabled={loan.current.balance <= 0}
                  >
                     {loan.current.balance > 0 ? "ÐŸÐ¾Ð³Ð°ÑÐ¸Ñ‚ÑŒ Ð·Ð°Ñ‘Ð¼" : "Ð—Ð°Ñ‘Ð¼ Ð¿Ð¾Ð³Ð°ÑˆÐµÐ½"}
                  </Button>
                  <span>
                     ÐžÑÑ‚Ð°Ñ‚Ð¾Ðº:{" "}
                     <strong>
                        {parseFloat(
                           loan.current.balance > 0 ? loan.current.balance / 100 : 0
                        ).toLocaleString("ru-RU")}{" "}
                        â‚½
                     </strong>
                     <br /> <span>Ð’ÐºÐ»ÑŽÑ‡Ð°Ñ Ð¿Ñ€Ð¾Ñ†ÐµÐ½Ñ‚Ñ‹</span>
                  </span>
               </div>
            </>
         );
      }
      default: {
         return (
            <>
               <span className={cards__text}>Ð£ Ð²Ð°Ñ Ð½ÐµÑ‚ Ð°ÐºÑ‚Ð¸Ð²Ð½Ñ‹Ñ… Ð·Ð°ÑÐ²Ð¾Ðº</span>
               <Button variant={"white"} href="/application">
                  ÐžÑ„Ð¾Ñ€Ð¼Ð¸Ñ‚ÑŒ Ð·Ð°Ñ‘Ð¼
               </Button>
            </>
         );
      }
   }
}

export default CurrentCard;
