import { useEffect } from "react";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import Button from "../../../components/Button";
import DropDownList from "../../../components/DropDownList";
import EmptyData from "../../../components/EmptyData";
import IconCopy from "../../../components/Icons/Copy";
import Input from "../../../components/Input";
import Table from "../../../components/Table";
import checkAuth from "../../../helpers/checkAuth";
import api from "../../../helpers/fetch";
import formatPhone from "../../../helpers/formatPhone";
import getFIO from "../../../helpers/getFIO";
import Accordion from "../../Components/Accordion";
import AccordionItem from "../../Components/AccordionItem";
import Card from "../../Components/Card";
import IconButton from "../../Components/IconButton";
import IconEdit from "../../Components/Icons/IconEdit";
import IconShieldCheckmark from "../../Components/Icons/IconShieldCheckmark";
import PopUp from "../../Components/PopUp";

import scss from "./index.module.scss";

const {
   container,
   avatar,
   cards,
   card,
   card__title,
   card__link,
   card__link_status,
   card__link_tel,
   card__link_email,
   card__wrapper,
   card__actions,
   link,
   link__header,
   link__block,
   link__title,
   link__subtitle,
   wrapper__accept,
   attantion_small,
   buttons,
} = scss;

function Employee() {
   const params = useParams();
   const [state, setState] = useState({
      id_user: 0,
      name: "",
      surname: "",
      midname: "",
      status: "",
      role: "",
      phone_number: 0,
      email: "",
      accepted: 0,
      denied: 0,
   });

   const statusesTransaction = {
      confirmed: "ÐžÐ´Ð¾Ð±Ñ€ÐµÐ½Ð°",
      processing: "ÐžÐ±Ñ€Ð°Ð±Ð°Ñ‚Ñ‹Ð²Ð°ÐµÑ‚ÑÑ",
      denied: "ÐžÑ‚ÐºÐ»Ð¾Ð½Ñ‘Ð½Ð°",
   };

   const statusesLoans = {
      confirmed: "ÐžÐ´Ð¾Ð±Ñ€ÐµÐ½",
      signed: "ÐŸÐ¾Ð´Ð¿Ð¸ÑÐ°Ð½",
      completed: "Ð’Ñ‹Ð¿Ð»Ð°Ñ‡ÐµÐ½",
      closed: "Ð—Ð°ÐºÑ€Ñ‹Ñ‚",
      denied: "ÐžÑ‚ÐºÐ»Ð¾Ð½Ñ‘Ð½",
      unpayed: "ÐÑƒÐ¶Ð½Ð° Ð²Ñ‹Ð¿Ð»Ð°Ñ‚Ð°",
   };

   const [wait, setWait] = useState(false);

   const [name, setName] = useState("");
   const [surname, setSurname] = useState("");
   const [midname, setMidname] = useState("");
   const [phone_number, setPhone_number] = useState("");
   const [email, setEmail] = useState("");
   const [status, setStatus] = useState("");
   const [role, setRole] = useState(1);
   const roleArray = ["ÐœÐµÐ½ÐµÐ´Ð¶ÐµÑ€", "Ð ÐµÐ´Ð°ÐºÑ‚Ð¾Ñ€", "ÐÐ´Ð¼Ð¸Ð½Ð¸ÑÑ‚Ñ€Ð°Ñ‚Ð¾Ñ€"];
   const statusArray = ["Ð£Ð²Ð¾Ð»ÐµÐ½", "Ð Ð°Ð±Ð¾Ñ‚Ð°ÐµÑ‚ Ð² Ð¾Ñ€Ð³Ð°Ð½Ð¸Ð·Ð°Ñ†Ð¸Ð¸"];
   const [sort, setSort] = useState([]);
   const [sortLoans, setSortLoans] = useState([]);
   const [nameError, setNameError] = useState("");
   const [surnameError, setSurnameError] = useState("");
   const [phone_numberError, setPhone_numberError] = useState("");
   const [emailError, setEmailError] = useState("");

   const [isActive, setIsActive] = useState(false);

   const [transStory, setTransStory] = useState([]);

   const [loanStory, setLoanStory] = useState([]);

   const [pagination, setPagination] = useState({});

   const [paginationPage, setPaginationPage] = useState(1);

   const [pagination2, setPagination2] = useState({});

   const [paginationPage2, setPaginationPage2] = useState(1);

   const toTable = (rows = []) => {
      const tmpArray = [];
      rows.forEach((el, key) => {
         tmpArray.push([
            {
               type: "text",
               value: getFIO(el.name, el.surname, el.midname, "ser-name-mid"),
            },
            {
               type: "text",
               value: el.id_loan_transaction,
            },
            {
               type: "date",
               value: el.date_created,
            },
            {
               type: "money",
               value: el.amount,
            },
            {
               type: el.status === "confirmed" ? "success" : "error",
               value: statusesTransaction[el.status],
            },
         ]);
      });
      return tmpArray;
   };

   const toTableLoans = (rows = []) => {
      const tmpArray = [];
      rows.forEach((el, key) => {
         tmpArray.push([
            {
               type: "text",
               value: getFIO(el.name, el.surname, el.midname, "ser-name-mid"),
            },
            {
               type: el.date_created ? "date" : "text",
               value: el.date_created ? el.date_created : "â€”",
            },
            {
               type: "text",
               value: el.duration ? el.duration + " Ð´Ð½ÐµÐ¹" : "â€”",
            },
            {
               type: el.total_amount ? "money" : "text",
               value: el.total_amount ? el.total_amount : "â€”",
            },
            {
               type: "text",
               value: el.rate ? el.rate.toLocaleString("ru-RU") + " %" : "â€”",
            },
            {
               type: el.status === "denied" ? "error" : "success",
               value: el.status === "denied" ? "ÐžÑ‚ÐºÐ»Ð¾Ð½Ñ‘Ð½" : "ÐžÐ´Ð¾Ð±Ñ€ÐµÐ½",
            },
            {
               type: "component",
               value:
                  el.status === "confirmed" || el.status === "signed" || el.status === "unpayed" ? (
                     <Link to={"/manage/loans/" + el.id_loan}>ÐŸÐ¾Ð´Ñ€Ð¾Ð±Ð½ÐµÐµ</Link>
                  ) : (
                     <Link to={"/manage/archive/" + el.id_loan}>ÐŸÐ¾Ð´Ñ€Ð¾Ð±Ð½ÐµÐµ</Link>
                  ),
            },
         ]);
      });
      return tmpArray;
   };

   const getData = async () => {
      const auth = checkAuth();
      const response = await new api(`manage/users/${params.id}`, auth).send();
      if (response.status === "success") {
         setState(response?.data);
         setName(response?.data.name);
         setSurname(response?.data.surname);
         setMidname(response?.data.midname);
         setPhone_number(response?.data.phone_number.toString());
         setEmail(response?.data.email);
         setStatus(response?.data.status);
         setRole(response?.data.role - 1);
      }
   };

   const getTrans = async () => {
      const auth = checkAuth();
      let queryParams = getSortQuery("surname");
      const response = await new api(
         `manage/transactions?id_employer=${params.id}${queryParams}` +
            "&page=" +
            (paginationPage !== 0 && paginationPage ? paginationPage : 1),
         auth
      ).send();
      if (response.status === "success") {
         setTransStory(toTable(response.data.data));
         setPagination(response.data.pagination);
      }
   };

   const getLoans = async () => {
      const auth = checkAuth();
      let queryParams = getSortQuery("surname");
      const response = await new api(
         `manage/loans?total&id_employer=${params?.id}${queryParams ? queryParams : ""}` +
            "&page=" +
            (paginationPage2 !== 0 && paginationPage2 ? paginationPage2 : 1),
         auth
      ).send();
      if (response.status === "success") {
         setLoanStory(toTableLoans(response.data.data));
         setPagination2(response.data.pagination);
      }
   };

   const getSortQuery = (nameField) => {
      if (sort.length === 0) {
         setSort([nameField, "asc"]);
      } else {
         return "&sort_param=" + sort[0] + "&sort_direction=" + sort[1];
      }
      return "";
   };

   useEffect(() => {
      getData();
      getTrans();
      getLoans();
   }, []);

   useEffect(() => {
      getTrans();
   }, [sort, paginationPage]);

   const onPaginate = (number) => {
      setPaginationPage(number);
   };

   useEffect(() => {
      getLoans();
   }, [sortLoans, paginationPage2]);

   const onPaginate2 = (number) => {
      setPaginationPage2(number);
   };

   const onSubmit = async (e) => {
      e.preventDefault();
      setWait(true);
      setNameError("");
      setSurnameError("");
      setPhone_numberError("");
      setEmailError("");
      if (!name) {
         setNameError("ÐŸÐ¾Ð»Ðµ Ð´Ð¾Ð»Ð¶Ð½Ð¾ Ð±Ñ‹Ñ‚ÑŒ Ð·Ð°Ð¿Ð¾Ð»Ð½ÐµÐ½Ð¾!");
      }
      if (!surname) {
         setSurnameError("ÐŸÐ¾Ð»Ðµ Ð´Ð¾Ð»Ð¶Ð½Ð¾ Ð±Ñ‹Ñ‚ÑŒ Ð·Ð°Ð¿Ð¾Ð»Ð½ÐµÐ½Ð¾!");
      }
      if (!phone_number) {
         setPhone_numberError("ÐŸÐ¾Ð»Ðµ Ð´Ð¾Ð»Ð¶Ð½Ð¾ Ð±Ñ‹Ñ‚ÑŒ Ð·Ð°Ð¿Ð¾Ð»Ð½ÐµÐ½Ð¾!");
      }
      if (!email) {
         setEmailError("ÐŸÐ¾Ð»Ðµ Ð´Ð¾Ð»Ð¶Ð½Ð¾ Ð±Ñ‹Ñ‚ÑŒ Ð·Ð°Ð¿Ð¾Ð»Ð½ÐµÐ½Ð¾!");
      }
      if (name && surname && phone_number && email) {
         const auth = checkAuth();
         const body = JSON.stringify({
            status: status,
            role: role + 1,
            name: name,
            surname: surname,
            midname: midname,
            phone_number: phone_number,
            email: email,
         });
         const response = await new api(`manage/users/${state.id_user}/put`, auth, {
            method: "PUT",
            body,
         }).send();
         if (response.status === "success") {
            onCloas();
            getData();
            setWait(false);
         } else {
            setPhone_numberError("Ð¢Ð°ÐºÐ¾Ð¹ Ð¿Ð¾Ð»ÑŒÐ·Ð¾Ð²Ð°Ñ‚ÐµÐ»ÑŒ ÑƒÐ¶Ðµ ÑÑƒÑ‰ÐµÑÑ‚Ð²ÑƒÐµÑ‚!");
            setEmailError("Ð¢Ð°ÐºÐ¾Ð¹ Ð¿Ð¾Ð»ÑŒÐ·Ð¾Ð²Ð°Ñ‚ÐµÐ»ÑŒ ÑƒÐ¶Ðµ ÑÑƒÑ‰ÐµÑÑ‚Ð²ÑƒÐµÑ‚!");
            setWait(false);
         }
      }
      setWait(false);
   };

   const onCloas = () => {
      setIsActive(!isActive);
      document.body.style.overflowY = null;
      getData();
   };

   const onResetPassword = async (e) => {
      e.preventDefault();
      const auth = checkAuth();
      const response = await new api(`manage/users/${state.id_user}/reset_password`, auth, {
         method: "POST",
      }).send();
      if (response.status === "success") {
         onCloas();
         getData();
      }
   };

   return (
      <section className={container}>
         <div className={cards}>
            <Card type={state?.id_user ? "wrapper" : ""}>
               <div className={card}>
                  <img
                     src="/assets/base_avatar.jpg"
                     className={avatar}
                     alt="ÐÐ²Ð°Ñ‚Ð°Ñ€ Ð¿Ð¾Ð»ÑŒÐ·Ð¾Ð²Ð°Ñ‚ÐµÐ»Ñ"
                     width="64"
                     height="64"
                  />
                  <h2 className={card__title}>{getFIO(state?.name, state?.surname, state?.midname, "ser-name-mid")}</h2>
                  <div className={card__wrapper}>
                     <span className={card__link + " " + card__link_status}>
                        {statusArray[state?.status] + " | " + roleArray[state?.role - 1]}
                     </span>
                     <span className={card__link + " " + card__link_tel}>
                        <a href={"tel:+" + state?.phone_number}>{formatPhone(state?.phone_number)}</a>
                        <IconCopy onClick={() => navigator.clipboard.writeText(formatPhone(state?.phone_number))} />
                     </span>
                     <span className={card__link + " " + card__link_email}>
                        <a href={"mailto:" + state?.email}>{state?.email}</a>
                        <IconCopy onClick={() => navigator.clipboard.writeText(state?.email)} />
                     </span>
                  </div>
                  <div className={card__actions}>
                     <IconButton type={"button"} onClick={() => setIsActive(!isActive)}>
                        <IconEdit />
                     </IconButton>
                     {status === 0 ? null : (
                        <IconButton type={"button"} onClick={onResetPassword}>
                           <IconShieldCheckmark />
                        </IconButton>
                     )}
                  </div>
               </div>
            </Card>
            <Card type={state?.id_user ? "wrapper" : ""} className={link}>
               <span className={link__header}>Ð¡Ñ‚Ð°Ñ‚Ð¸ÑÑ‚Ð¸ÐºÐ° ÑÐ¾Ñ‚Ñ€ÑƒÐ´Ð½Ð¸ÐºÐ°</span>
               <div className={link__block}>
                  <span className={link__title}>ÐžÐ´Ð¾Ð±Ñ€ÐµÐ½Ð¾</span>
                  <span className={link__subtitle}>{state?.accepted.toLocaleString("ru-RU")} %</span>
               </div>
               <div className={link__block}>
                  <span className={link__title}>ÐžÑ‚ÐºÐ»Ð¾Ð½ÐµÐ½Ð¾</span>
                  <span className={link__subtitle}>{state?.denied.toLocaleString("ru-RU")} %</span>
               </div>
            </Card>
         </div>
         <Accordion>
            <AccordionItem title={"Ð ÐµÑˆÐµÐ½Ð¸Ñ Ð¿Ð¾ Ñ‚Ñ€Ð°Ð½Ð·Ð°ÐºÑ†Ð¸ÑÐ¼"}>
               <Table
                  rows={transStory}
                  colums={[
                     {
                        paramName: "surname",
                        name: "ÐšÐ»Ð¸ÐµÐ½Ñ‚",
                     },
                     {
                        paramName: "id_loan_transaction",
                        name: "ÐÐ¾Ð¼ÐµÑ€ Ñ‚Ñ€Ð°Ð½Ð·Ð°ÐºÑ†Ð¸Ð¸",
                     },
                     {
                        paramName: "date_created",
                        name: "Ð”Ð°Ñ‚Ð°",
                     },
                     {
                        paramName: "amount",
                        name: "Ð¡ÑƒÐ¼Ð¼Ð°",
                     },
                     {
                        paramName: "status",
                        name: "Ð¡Ñ‚Ð°Ñ‚ÑƒÑ",
                     },
                  ]}
                  onSort={(param, direction) => setSort([param, direction])}
                  sortParam={sort[0]}
                  sortDirection={sort[1]}
                  baseColumnsWidth={[null, 260, 200, 200]}
                  blockOnEmpty={
                     <EmptyData
                        title="ÐÐµÑ‚ Ð´Ð°Ð½Ð½Ñ‹Ñ…"
                        text="Ð”Ð°Ð½Ð½Ñ‹Ðµ Ð¾Ñ‚ÑÑƒÑ‚Ð²ÑƒÑŽÑ‚. Ð’ÐµÑ€Ð¾ÑÑ‚Ð½Ð¾, Ñ‡Ñ‚Ð¾ ÑÐ¾Ñ‚Ñ€ÑƒÐ´Ð½Ð¸Ðº ÐµÑ‰Ðµ Ð½Ðµ ÑÐ´ÐµÐ»Ð° Ð½Ð¸ Ð¾Ð´Ð½Ð¾Ð³Ð¾ Ñ€ÐµÑˆÐµÐ½Ð¸Ñ Ð¿Ð¾ Ñ‚Ñ€Ð°Ð½Ð·Ð°ÐºÑ†Ð¸ÑÐ¼."
                     />
                  }
                  pagination={pagination}
                  onPaginate={onPaginate}
               />
            </AccordionItem>
            <AccordionItem title={"Ð ÐµÑˆÐµÐ½Ð¸Ñ Ð¿Ð¾ Ð·Ð°Ð¹Ð¼Ð°Ð¼"}>
               <Table
                  rows={loanStory}
                  colums={[
                     {
                        paramName: "surname",
                        name: "ÐšÐ»Ð¸ÐµÐ½Ñ‚",
                     },
                     {
                        paramName: "date_created",
                        name: "Ð”Ð°Ñ‚Ð° Ð¿Ð¾Ð´Ð°Ñ‡Ð¸",
                     },

                     {
                        paramName: "duration",
                        name: "Ð¡Ñ€Ð¾Ðº",
                     },
                     {
                        paramName: "total_amount",
                        name: "Ð¡ÑƒÐ¼Ð¼Ð°",
                     },
                     {
                        paramName: "rate",
                        name: "ÐŸÑ€Ð¾Ñ†ÐµÐ½Ñ‚",
                     },
                     {
                        paramName: "status",
                        name: "Ð¡Ñ‚Ð°Ñ‚ÑƒÑ",
                     },
                     {
                        paramName: "actions",
                        name: "Ð”ÐµÐ¹ÑÑ‚Ð²Ð¸Ñ",
                     },
                  ]}
                  onSort={(param, direction) => setSortLoans([param, direction])}
                  sortParam={sortLoans[0]}
                  sortDirection={sortLoans[1]}
                  baseColumnsWidth={[null, 200, 200, 200, 160, 160, 160, 100]}
                  blockOnEmpty={<EmptyData title="ÐÐµÑ‚ Ð´Ð°Ð½Ð½Ñ‹Ñ…" text="ÐÐµ ÑƒÐ´Ð°Ð»Ð¾ÑÑŒ Ð½Ð¸Ñ‡ÐµÐ³Ð¾ Ð½Ð°Ð¹Ñ‚Ð¸ Ð¿Ð¾ Ð²Ð°ÑˆÐµÐ¼Ñƒ Ð·Ð°Ð¿Ñ€Ð¾ÑÑƒ." />}
                  pagination={pagination2}
                  onPaginate={onPaginate2}
               />
            </AccordionItem>
         </Accordion>
         {isActive ? (
            <PopUp title={"ÐÐ¾Ð²Ñ‹Ð¹ ÑÐ¾Ñ‚Ñ€ÑƒÐ´Ð½Ð¸Ðº"} onCloase={() => onCloas()}>
               <form action="" onSubmit={onSubmit} className={wrapper__accept}>
                  {state.role !== 3 ? (
                     <>
                        <DropDownList label={"Ð”Ð¾Ð»Ð¶Ð½Ð¾ÑÑ‚ÑŒ"} options={roleArray} value={role} onChange={setRole} />
                        <DropDownList label={"Ð¡Ñ‚Ð°Ñ‚ÑƒÑ"} options={statusArray} value={status} onChange={setStatus} />
                     </>
                  ) : null}
                  <Input
                     label={"Ð˜Ð¼Ñ*"}
                     placeholder={"Ð˜Ð¼Ñ"}
                     regex={/^[Ð°-ÑÐ-Ð¯Ñ‘Ð \-]{0,}$/u}
                     type={"text"}
                     value={name}
                     onChange={setName}
                     error={nameError}
                  />
                  <Input
                     label={"Ð¤Ð°Ð¼Ð¸Ð»Ð¸Ñ*"}
                     placeholder={"Ð¤Ð°Ð¼Ð¸Ð»Ð¸Ñ"}
                     regex={/^[Ð°-ÑÐ-Ð¯Ñ‘Ð \-]{0,}$/u}
                     type={"text"}
                     value={surname}
                     onChange={setSurname}
                     error={surnameError}
                  />
                  <Input
                     label={"ÐžÑ‚Ñ‡ÐµÑÑ‚Ð²Ð¾"}
                     placeholder={"ÐžÑ‚Ñ‡ÐµÑÑ‚Ð²Ð¾"}
                     regex={/^[Ð°-ÑÐ-Ð¯Ñ‘Ð \-]{0,}$/u}
                     type={"text"}
                     value={midname}
                     onChange={setMidname}
                  />
                  <Input
                     label={"Ð¢ÐµÐ»ÐµÑ„Ð¾Ð½*"}
                     placeholder={"+7 (906) 800 35-35"}
                     mask={"+7 (XXX) XXX-XX-XX"}
                     name="tel"
                     regex={/[0-9]/}
                     type={"text"}
                     value={phone_number}
                     onChange={setPhone_number}
                     error={phone_numberError}
                  />
                  <Input
                     label={"Ð­Ð»ÐµÐºÑ‚Ñ€Ð¾Ð½Ð½Ð°Ñ Ð¿Ð¾Ñ‡Ñ‚Ð°*"}
                     placeholder={"Ð­Ð»ÐµÐºÑ‚Ñ€Ð¾Ð½Ð½Ð°Ñ Ð¿Ð¾Ñ‡Ñ‚Ð°"}
                     type={"text"}
                     value={email}
                     onChange={setEmail}
                     error={emailError}
                  />
                  <p className={attantion_small}>Ð’Ð½Ð¸Ð¼Ð°Ñ‚ÐµÐ»ÑŒÐ½Ð¾ Ð¿Ñ€Ð¾Ð²ÐµÑ€ÑÐ¹Ñ‚Ðµ Ð´Ð°Ð½Ð½Ñ‹Ðµ ÑÐ¾Ñ‚Ñ€ÑƒÐ´Ð½Ð¸ÐºÐ° Ð¿ÐµÑ€ÐµÐ´ ÑÐ¾Ñ…Ñ€Ð°Ð½ÐµÐ½Ð¸ÐµÐ¼.</p>
                  <div className={buttons}>
                     <Button variant={"gray"} onClick={() => onCloas()}>
                        ÐžÑ‚Ð¼ÐµÐ½Ð°
                     </Button>
                     <Button isWaiting={wait} type={"submit"}>
                        Ð¡Ð¾Ñ…Ñ€Ð°Ð½Ð¸Ñ‚ÑŒ
                     </Button>
                  </div>
               </form>
            </PopUp>
         ) : null}
      </section>
   );
}

export default Employee;
