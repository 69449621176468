import { useNavigate } from "react-router-dom";
import Button from "../Button";
import IconCopy from "../Icons/Copy";
import scss from "./index.module.scss";

const { head, head__container, head__subtitle, head__icon, head__title } = scss;

function ContentHead({
   title,
   subtitle,
   bottomSubtitle,
   topSubtitle,
   backButton,
   buttonClick,
   buttonText,
}) {
   const navigate = useNavigate();
   return (
      <div className={head}>
         <div className={head__container}>
            {topSubtitle ? (
               <div className={head__subtitle}>
                  <span>{subtitle}</span>
                  <IconCopy
                     className={head__icon}
                     onClick={() => navigator.clipboard.writeText(subtitle)}
                  />
               </div>
            ) : null}
            <h2 className={head__title}>{title}</h2>
            {bottomSubtitle ? (
               <div className={head__subtitle}>
                  <span>{subtitle}</span>
               </div>
            ) : null}
         </div>
         {backButton ? (
            <Button type="button" variant={"gray"} onClick={() => navigate(-1)}>
               ÐÐ°Ð·Ð°Ð´
            </Button>
         ) : null}
         {buttonClick ? (
            <Button type="button" onClick={buttonClick}>
               {buttonText ? buttonText : "ÐÐ¾Ð²Ð°Ñ Ñ‚Ñ€Ð°Ð½Ð·Ð°ÐºÑ†Ð¸Ñ"}
            </Button>
         ) : null}
      </div>
   );
}

export default ContentHead;
