import classes from "./index.module.scss";
import Button from "../Button";
import { useRef } from "react";

const { card, card__button, card__container, card__header, card__title, card__overview, card__icon, card__subtext } =
   classes;

function Card({
      icon,
      amount,
      rate,
      duration,
      editable = false,
      onChange= () => {}
   }) {

   const defaultAmount = useRef(amount);
   const defaultRate = useRef(rate);
   const defaultDuration = useRef(duration);

   return (
      <article className={card}>
         <div className={card__container}>
            <div className={card__header}>
               <h3
                  className={card__title}
                  contentEditable={editable} suppressContentEditableWarning={true}
                  value={amount}
                  onChange={e => onChange("amount", e.target.textContent)}
                  onBlur={e => onChange("amount", e.target.textContent)}
               >{defaultAmount.current}</h3>
               <span
                  className={card__overview}
                  contentEditable={editable} suppressContentEditableWarning={true}
                  value={rate}
                  onChange={e => onChange("rate", e.target.textContent)}
                  onBlur={e => onChange("rate", e.target.textContent)}
               >{defaultRate.current}</span>
            </div>
            <div className={card__icon}>{icon ? icon : null}</div>
            <span
               className={card__subtext}
               contentEditable={editable} suppressContentEditableWarning={true}
               value={amount}
               onChange={e => onChange("duration", e.target.textContent)}
               onBlur={e => onChange("duration", e.target.textContent)}
            >{defaultDuration.current}</span>
         </div>
         <Button
            href={editable ? null : "/application"}
            className={card__button}
            style={editable ? {cursor: "default"} : {}}
         >Ð’Ñ‹Ð±Ñ€Ð°Ñ‚ÑŒ</Button>
      </article>
   );
}

export default Card;
