import Error from "../pages/Error";
import CheckkAuth from "./Components/CheckAuth";
import Application from "./pages/Application";
import Applications from "./pages/Applications";
import Archive from "./pages/Archive";
import Archives from "./pages/Archives";
import Dashboard from "./pages/Dashboard";
import Loan from "./pages/Loan";
import Loans from "./pages/Loans";
import MainManagePage from "./pages/Main";
import Pages from "./pages/Pages";
import Settings from "./pages/Settings";
import Staff from "./pages/Staff";
import Customers from "./pages/Customers";
import Customer from "./pages/Customer";
import Employee from "./pages/Employee";
import Page from "./pages/Page";
import SettingsPage from "./pages/SettingsPage";
import Support from "./pages/Support";

const manageRouter = [
   {
      path: "/",
      breadcrumb: "",
   },
   {
      path: "/manage",
      element: (
         <CheckkAuth>
            <MainManagePage />
         </CheckkAuth>
      ),
      elementError: <Error />,
      breadcrumb: "ÐŸÐ°Ð½ÐµÐ»ÑŒ ÑƒÐ¿Ñ€Ð°Ð²Ð»ÐµÐ½Ð¸Ñ",
      children: [
         {
            index: true,
            element: <Dashboard />,
         },
         {
            path: "pages",
            breadcrumb: "Ð¡Ñ‚Ñ€Ð°Ð½Ð¸Ñ†Ñ‹",
            element: <Pages />,
         },
         {
            path: "pages/:id",
            breadcrumb: "Ð¡Ñ‚Ñ€Ð°Ð½Ð¸Ñ†Ð°",
            element: <Page />,
         },
         {
            path: "applications",
            breadcrumb: "Ð—Ð°ÑÐ²ÐºÐ¸",
            element: <Applications />,
         },
         {
            path: "applications/:id",
            breadcrumb: "Ð—Ð°ÑÐ²ÐºÐ°",
            element: <Application />,
         },
         {
            path: "loans",
            breadcrumb: "Ð—Ð°Ð¹Ð¼Ñ‹",
            element: <Loans />,
         },
         {
            path: "loans/:id",
            breadcrumb: "Ð—Ð°Ð¹Ð¼",
            element: <Loan />,
         },
         {
            path: "archive",
            breadcrumb: "ÐÑ€Ñ…Ð¸Ð²",
            element: <Archives />,
         },
         {
            path: "archive/:id",
            breadcrumb: "ÐÑ€Ñ…Ð¸Ð²Ð½Ñ‹Ð¹ Ð·Ð°Ð¹Ð¼",
            element: <Archive />,
         },
         {
            path: "customers",
            breadcrumb: "ÐšÐ»Ð¸ÐµÐ½Ñ‚Ñ‹",
            element: <Customers />,
         },
         {
            path: "customers/:id",
            breadcrumb: "ÐšÐ»Ð¸ÐµÐ½Ñ‚",
            element: <Customer />,
         },
         {
            path: "staff",
            breadcrumb: "Ð¡Ð¾Ñ‚Ñ€ÑƒÐ´Ð½Ð¸ÐºÐ¸",
            element: <Staff />,
         },
         {
            path: "staff/:id",
            breadcrumb: "Ð¡Ð¾Ñ‚Ñ€ÑƒÐ´Ð½Ð¸Ðº",
            element: <Employee />,
         },
         {
            path: "education",
            breadcrumb: "ÐžÐ±ÑƒÑ‡ÐµÐ½Ð¸Ðµ",
            element: <></>,
         },
         {
            path: "support",
            breadcrumb: "ÐŸÐ¾Ð´Ð´ÐµÑ€Ð¶ÐºÐ°",
            element: <Support />,
         },
         {
            path: "settings",
            breadcrumb: "ÐÐ°ÑÑ‚Ñ€Ð¾Ð¹ÐºÐ¸",
            element: <Settings />,
            children: [
               {
                  path: ":slug",
                  breadcrumb: " ",
                  element: <SettingsPage />,
               },
            ],
         },
      ],
   },
];

export default manageRouter;
