import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReactLoading from "react-loading";

import Button from "../../components/Button";
import EmptyData from "../../components/EmptyData";
import IconCopy from "../../components/Icons/Copy";
import IconPage from "../../components/Icons/Page";
import PACardWrapper from "../../components/PersonalArea/CardWrapper";
import Table from "../../components/Table";

import checkAuth from "../../helpers/checkAuth";
import api from "../../helpers/fetch";
import TextOverflow from "../../helpers/text-overflow";

import scss from "./index.module.scss";
import logOut from "../../helpers/logout";

const {
   aboutLoan,
   aboutLoan__header,
   aboutLoan__menu,
   aboutLoan__text,
   aboutLoan__subtext,
   aboutLoan__icon,
   aboutLoan__title,
   aboutLoan__dashboard,
   aboutLoan__cards,
   aboutLoan__card,
   aboutLoan__cardTitle,
   aboutLoan__cardData,
   aboutLoan__payCard,
   aboutLoan__document,
   list,
   list__item,
   list__icon,
   list__text,
   cards__pay,
   cards__title,
   cards__title_black,
   cards__grid,
   cards__actions,
} = scss;

function AboutLoan() {
   const navigate = useNavigate();
   const params = useParams();

   const [loan, setLoan] = useState(false);
   const [transactionSort, setTransactionSort] = useState(null);

   useEffect(() => {
      getLoan();
   }, []);

   const paymentsToRows = (payments = []) => {
      const rows = [];
      payments.forEach((payment) => {
         const paymentStatus =
            payment.status === "confirmed" ? "success" : payment.status === "denied" ? "error" : "text";

         const paymentStatusText =
            payment.status === "confirmed"
               ? "ÐŸÐ¾Ð´Ñ‚Ð²ÐµÑ€Ð¶Ð´Ñ‘Ð½"
               : payment.status === "denied"
               ? "ÐžÑ‚ÐºÐ»Ð¾Ð½Ñ‘Ð½"
               : "ÐžÐ±Ñ€Ð°Ð±Ð°Ñ‚Ñ‹Ð²Ð°ÐµÑ‚ÑÑ";

         rows.push([
            {
               type: "text",
               value: "â„– " + payment.number,
            },
            {
               type: "money",
               value: payment.amount,
            },
            {
               type: "date",
               value: payment.date,
            },
            {
               type: paymentStatus,
               value: paymentStatusText,
            },
         ]);
      });

      return rows;
   };

   const getLoan = async () => {
      const auth = checkAuth();

      if (!auth) logOut();

      let queryParams = "";

      if (transactionSort === null) {
         setTransactionSort(["date_created", "asc"]);
      } else {
         queryParams = "?sort_param=" + transactionSort[0] + "&sort_direction=" + transactionSort[1];
      }

      const resp = await new api("loan" + (params.idLoan ? "/" + params.idLoan : "") + queryParams, auth).send();

      if (resp.status === "success") {
         if (queryParams === "") {
            resp.data.payments = paymentsToRows(resp.data.payments);
            setLoan(resp.data);
         } else {
            const loanTmp = loan;
            loanTmp.payments = paymentsToRows(resp.data.payments);
            setLoan(loanTmp);
         }
      } else {
         navigate("/404");
      }
   };

   useEffect(() => {
      if (loan) {
         getLoan();
      }
   }, [transactionSort]);

   const copyLoanNumber = () => {
      navigator.clipboard.writeText(loan.number);
   };

   return (
      <div className={aboutLoan}>
         {!loan ? (
            <div style={{ minHeight: "70vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
               <ReactLoading type="spin" color="#2D9614" />
            </div>
         ) : (
            <>
               <section className={aboutLoan__header}>
                  <div className={aboutLoan__menu}>
                     <div className={aboutLoan__text}>
                        <div className={aboutLoan__subtext}>
                           <span>Ð—Ð°Ð¹Ð¼ â„–{loan.number}</span>
                           <IconCopy className={aboutLoan__icon} onClick={copyLoanNumber} />
                        </div>
                        <h2 className={aboutLoan__title}>Ð˜Ð½Ñ„Ð¾Ñ€Ð¼Ð°Ñ†Ð¸Ñ Ð¿Ð¾ Ð·Ð°Ð¹Ð¼Ñƒ</h2>
                     </div>
                     <Button type="button" variant={"gray"} onClick={() => navigate(-1)}>
                        ÐÐ°Ð·Ð°Ð´
                     </Button>
                  </div>
                  <div className={aboutLoan__dashboard}>
                     <div className={aboutLoan__cards}>
                        <article className={aboutLoan__card}>
                           <h3 className={aboutLoan__cardTitle}>Ð¡Ñ‚Ð°Ñ‚ÑƒÑ</h3>
                           <span className={aboutLoan__cardData}>
                              {loan.status === "signed" ? "ÐÐºÑ‚Ð¸Ð²ÐµÐ½" : ""}
                              {loan.status === "completed" ? "Ð’Ñ‹Ð¿Ð»Ð°Ñ‡ÐµÐ½" : ""}
                           </span>
                        </article>
                        <article className={aboutLoan__card}>
                           <h3 className={aboutLoan__cardTitle}>Ð¡ÑƒÐ¼Ð¼Ð° Ð·Ð°Ð¹Ð¼Ð°</h3>
                           <span className={aboutLoan__cardData}>
                              {parseFloat(loan.total_amount / 100).toLocaleString("ru-RU")} â‚½
                           </span>
                        </article>
                        <article className={aboutLoan__card}>
                           <h3 className={aboutLoan__cardTitle}>Ð¡Ñ‚Ð°Ð²ÐºÐ° Ð² Ð´ÐµÐ½ÑŒ</h3>
                           <span className={aboutLoan__cardData}>{parseFloat(loan.rate).toLocaleString("ru-RU")}%</span>
                        </article>
                     </div>
                     <div className={aboutLoan__payCard}>
                        <PACardWrapper className={cards__pay}>
                           <h3 className={cards__title + " " + cards__title_black}>ÐœÐ¸Ð½Ð¸Ð¼Ð°Ð»ÑŒÐ½Ñ‹Ð¹ Ð¿Ð»Ð°Ñ‚Ñ‘Ð¶</h3>
                           <div className={cards__grid}>
                              {loan.status === "signed" ? (
                                 <>
                                    {loan.next_payment_amount > 0 ? (
                                       <span>
                                          Ð¡ÑƒÐ¼Ð¼Ð°&nbsp;Ð¿Ð»Ð°Ñ‚ÐµÐ¶Ð°:
                                          <br />
                                          <strong>
                                             {parseFloat(
                                                loan.next_payment_amount > 0 ? loan.next_payment_amount / 100 : 0
                                             ).toLocaleString("ru-RU")}{" "}
                                             â‚½
                                          </strong>
                                       </span>
                                    ) : (
                                       ""
                                    )}
                                    {loan.next_payment_date > 0 ? (
                                       <span>
                                          ÐžÐ¿Ð»Ð°Ñ‚Ð¸Ñ‚ÑŒ&nbsp;Ð´Ð¾:
                                          <br />
                                          <strong>
                                             {new Date(loan.next_payment_date).toLocaleDateString("ru-RU")}
                                          </strong>
                                       </span>
                                    ) : (
                                       ""
                                    )}
                                 </>
                              ) : (
                                 ""
                              )}
                           </div>
                           <div className={cards__actions}>
                              <Button
                                 href={"/payment-method"}
                                 variant={"gray"}
                                 disabled={loan.next_payment_amount <= 0 || loan.status === "completed"}
                              >
                                 {loan.next_payment_amount > 0 && loan.status === "signed"
                                    ? "Ð’Ð½ÐµÑÑ‚Ð¸ Ð¿Ð»Ð°Ñ‚Ñ‘Ð¶"
                                    : "ÐŸÐ»Ð°Ñ‚Ñ‘Ð¶ Ð¾Ð¿Ð»Ð°Ñ‡ÐµÐ½"}
                              </Button>
                              <Button href="/payment-method/total" disabled={loan.status !== "signed"}>
                                 {loan.status === "signed" ? "ÐŸÐ¾Ð³Ð°ÑÐ¸Ñ‚ÑŒ Ð·Ð°Ñ‘Ð¼" : "Ð—Ð°Ð¹Ð¼ Ð¿Ð¾Ð³Ð°ÑˆÐµÐ½"}
                              </Button>
                           </div>
                        </PACardWrapper>
                     </div>
                  </div>
               </section>
               <section className={aboutLoan__document}>
                  <div className={aboutLoan__menu}>
                     <div className={aboutLoan__text}>
                        <h2 className={aboutLoan__title}>Ð”Ð¾ÐºÑƒÐ¼ÐµÐ½Ñ‚Ñ‹</h2>
                        <div className={aboutLoan__subtext}>
                           <span>ÐŸÐ¾Ð¶Ð°Ð»ÑƒÐ¹ÑÑ‚Ð° Ð¾Ð·Ð½Ð°ÐºÐ¾Ð¼Ð¸Ñ‚ÐµÑÑŒ Ñ Ð¿Ð°ÐºÐµÑ‚Ð¾Ð¼ Ð´Ð¾ÐºÑƒÐ¼ÐµÐ½Ñ‚Ð¾Ð², ÐºÐ¾Ñ‚Ð¾Ñ€Ñ‹Ð¹ Ð´Ð»Ñ Ð²Ð°Ñ ÑÑ„Ð¾Ñ€Ð¼Ð¸Ñ€Ð¾Ð²Ð°Ð½:</span>
                        </div>
                     </div>
                  </div>
                  <div className={list}>
                     {loan.documents.map(({ name, url }, i) => (
                        <a
                           className={list__item}
                           href={url}
                           target={"_blank"}
                           rel="noreferrer"
                           title={'Ð¡ÐºÐ°Ñ‡Ð°Ñ‚ÑŒ "' + name + '"'}
                           key={i}
                        >
                           <IconPage className={list__icon} />
                           <p className={list__text}>{TextOverflow(name, 120)}</p>
                        </a>
                     ))}
                  </div>
               </section>
               <section className={aboutLoan__document}>
                  <div className={aboutLoan__menu}>
                     <div className={aboutLoan__text}>
                        <h2 className={aboutLoan__title}>ÐŸÐ»Ð°Ñ‚ÐµÐ¶Ð¸</h2>
                     </div>
                  </div>
                  <Table
                     className=""
                     colums={[
                        {
                           paramName: "id_loan_transaction",
                           name: "ÐÐ¾Ð¼ÐµÑ€ Ñ‚Ñ€Ð°Ð½Ð·Ð°ÐºÑ†Ð¸Ð¸",
                        },
                        {
                           paramName: "amount",
                           name: "Ð¡ÑƒÐ¼Ð¼Ð°",
                        },
                        {
                           paramName: "date_created",
                           name: "Ð”Ð°Ñ‚Ð° Ð¿Ð»Ð°Ñ‚ÐµÐ¶Ð°",
                        },
                        {
                           paramName: "status",
                           name: "Ð¡Ñ‚Ð°Ñ‚ÑƒÑ",
                        },
                     ]}
                     rows={loan?.payments}
                     baseColumnsWidth={[null, null, 260, 200]}
                     onSort={(param, direction) => setTransactionSort([param, direction])}
                     sortParam={transactionSort[0]}
                     sortDirection={transactionSort[1]}
                     blockOnEmpty={
                        <EmptyData
                           title="ÐÐµÑ‚ Ð¿Ð»Ð°Ñ‚ÐµÐ¶ÐµÐ¹"
                           text="Ð’Ñ‹ ÐµÑ‰Ñ‘ Ð½Ðµ Ð²Ð½ÐµÑÐ»Ð¸ Ð½Ð¸ Ð¾Ð´Ð½Ð¾Ð³Ð¾ Ð¿Ð»Ð°Ñ‚ÐµÐ¶Ð°. ÐšÐ°Ðº Ñ‚Ð¾Ð»ÑŒÐºÐ¾ Ð’Ñ‹ Ð²Ð½ÐµÑÑ‘Ñ‚Ðµ ÑÐ²Ð¾Ð¹ Ð¿ÐµÑ€Ð²Ñ‹Ð¹ Ð¿Ð»Ð°Ñ‚Ñ‘Ð¶, Ð¾Ð½ Ð¿Ð¾ÑÐ²Ð¸Ñ‚ÑÑ Ð² ÑÑ‚Ð¾Ð¼ ÑÐ¿Ð¸ÑÐºÐµ."
                        />
                     }
                  />
               </section>
            </>
         )}
      </div>
   );
}

export default AboutLoan;
