import { useEffect } from "react";
import { useState } from "react";
import { Outlet, useLocation } from "react-router";
import { Link } from "react-router-dom";
import IconLogo from "../../../components/Icons/Logo";
import checkAuth from "../../../helpers/checkAuth";
import api from "../../../helpers/fetch";
import logOut from "../../../helpers/logout";
import Aside from "../../Components/Aside";
import AsideList from "../../Components/AsideList";
import AsideUserCard from "../../Components/AsideUserCard";
import Header from "../../Components/Header";
import Main from "../../Components/Main";

import scss from "./index.module.scss";

const {
   wrapper,
   wrapper__header,
   wrapper__aside,
   wrapper__main,
   link,
   link__logo,
   wrapper__settings,
} = scss;

const MainManagePage = () => {
   const location = useLocation();
   const params = location.pathname.split("/");
   const [username, setUsername] = useState("");
   const [menu, setMenu] = useState([]);

   const getUserData = async () => {
      const auth = checkAuth();

      if (!auth) logOut();

      const resp = await new api("user", auth).send();

      if (resp.status === "success") {
         setUsername(resp.data.name);
      } else {
         logOut();
      }
   };

   const getMenu = (userRole) => {
      switch (userRole) {
         case "2": {
            return [
               { title: "ÐŸÐ°Ð½ÐµÐ»ÑŒ ÑƒÐ¿Ñ€Ð°Ð²Ð»ÐµÐ½Ð¸Ñ", link: "/manage", disabled: false },
               { title: "Ð¡Ñ‚Ñ€Ð°Ð½Ð¸Ñ†Ñ‹", link: "pages", disabled: false },
               // { title: "Ð—Ð°ÑÐ²ÐºÐ¸", link: "applications", disabled: false},
               // { title: "Ð—Ð°Ð¹Ð¼Ñ‹", link: "loans", disabled: false},
               // { title: "ÐÑ€Ñ…Ð¸Ð²", link: "archive", disabled: false},
               // { title: "ÐšÐ»Ð¸ÐµÐ½Ñ‚Ñ‹", link: "customers", disabled: false},
               { title: "ÐžÐ±ÑƒÑ‡ÐµÐ½Ð¸Ðµ", link: "education", disabled: true },
               { title: "ÐŸÐ¾Ð´Ð´ÐµÑ€Ð¶ÐºÐ°", link: "support", disabled: false },
            ];
         }
         case "3": {
            return [
               { title: "ÐŸÐ°Ð½ÐµÐ»ÑŒ ÑƒÐ¿Ñ€Ð°Ð²Ð»ÐµÐ½Ð¸Ñ", link: "/manage", disabled: false },
               { title: "Ð¡Ñ‚Ñ€Ð°Ð½Ð¸Ñ†Ñ‹", link: "pages", disabled: false },
               { title: "Ð—Ð°ÑÐ²ÐºÐ¸", link: "applications", disabled: false },
               { title: "Ð—Ð°Ð¹Ð¼Ñ‹", link: "loans", disabled: false },
               { title: "ÐÑ€Ñ…Ð¸Ð²", link: "archive", disabled: false },
               { title: "ÐšÐ»Ð¸ÐµÐ½Ñ‚Ñ‹", link: "customers", disabled: false },
               { title: "Ð¡Ð¾Ñ‚Ñ€ÑƒÐ´Ð½Ð¸ÐºÐ¸", link: "staff", disabled: false },
               { title: "ÐžÐ±ÑƒÑ‡ÐµÐ½Ð¸Ðµ", link: "education", disabled: true },
               { title: "ÐŸÐ¾Ð´Ð´ÐµÑ€Ð¶ÐºÐ°", link: "support", disabled: false },
               { title: "ÐÐ°ÑÑ‚Ñ€Ð¾Ð¹ÐºÐ¸", link: "settings", disabled: false },
            ];
         }
         default: {
            return [
               { title: "ÐŸÐ°Ð½ÐµÐ»ÑŒ ÑƒÐ¿Ñ€Ð°Ð²Ð»ÐµÐ½Ð¸Ñ", link: "/manage", disabled: false },
               { title: "Ð—Ð°ÑÐ²ÐºÐ¸", link: "applications", disabled: false },
               { title: "Ð—Ð°Ð¹Ð¼Ñ‹", link: "loans", disabled: false },
               { title: "ÐÑ€Ñ…Ð¸Ð²", link: "archive", disabled: false },
               { title: "ÐšÐ»Ð¸ÐµÐ½Ñ‚Ñ‹", link: "customers", disabled: false },
               { title: "ÐžÐ±ÑƒÑ‡ÐµÐ½Ð¸Ðµ", link: "education", disabled: true },
               { title: "ÐŸÐ¾Ð´Ð´ÐµÑ€Ð¶ÐºÐ°", link: "support", disabled: false },
            ];
         }
      }
   };

   useEffect(() => {
      setMenu(getMenu(localStorage.getItem("role") ? localStorage.getItem("role") : 0));
      getUserData();
   }, []);

   return (
      <div className={wrapper}>
         <Header className={wrapper__header} />
         <Aside className={wrapper__aside}>
            <AsideUserCard username={username} />
            {menu ? <AsideList data={menu} /> : null}
            <Link to={"/"} className={link}>
               <IconLogo className={link__logo} />
            </Link>
         </Aside>
         <Main className={params[2] !== "settings" ? wrapper__main : wrapper__settings}>
            <Outlet />
         </Main>
      </div>
   );
};

export default MainManagePage;
