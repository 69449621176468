import { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router";

import Button from "../../components/Button";
import ConfirmTelephone from "../../components/ConfirmTelephone";
import ContentHead from "../../components/ContentHead";
import DocumentList from "../../components/DocumentList";
import DropDownList from "../../components/DropDownList";
import IconSbp from "../../components/Icons/Sbp";
import InfoCards from "../../components/InfoCards";
import Input from "../../components/Input";
import InputRadio from "../../components/InputRadio";
import List from "../../components/List";
import checkAuth from "../../helpers/checkAuth";
import api from "../../helpers/fetch";
import formatPhone from "../../helpers/formatPhone";
import logOut from "../../helpers/logout";

import scss from "./index.module.scss";

const {
   approved,
   approved__header,
   approved__confirm,
   approved__document,
   approved__service,
   approved__form,
   block,
   block__sbp,
   block__text,
   container,
   container__button,
} = scss;

function Approved() {

   const navigate = useNavigate();
   const [loanData, setLoanData] = useState(null);

   const [isOnReg, setIsOnReg] = useState(1);
   const yesNoArray = ["Ð”Ð°", "ÐÐµÑ‚"];

   const [bank, setBank] = useState(0);
   const [telephone, setTelephone] = useState(0);

   const [codeSended, setCodeSended] = useState(false);
   const [codeError, setCodeError]   = useState(false);

   const [phoneError, setPhoneError] = useState(false);

   const getLoanData = async () => {
      const auth = checkAuth();

      if (!auth) logOut();

      const resp = await new api("sign", auth).send();

      if (resp.status === "success") {
         setLoanData(resp.data);
      }
      else {
         navigate("/profile");
      }
   }

   useEffect(() => {
      getLoanData();
   }, []);

   const confirm = async (code = 0) => {
      if (/^[0-9]{6}$/.test(code)) {
         setCodeSended(true);

         const body = new FormData();
         body.append("code", code);

         const auth = checkAuth();

         if (!auth) logOut();

         const resp = await new api("sign", auth, {
            method: "POST",
            body
         }).send();

         if (resp.status === "success") {
            setLoanData({
               phone_number: loanData.phone_number,
               banks: resp.data.banks
            });
            setCodeSended(false);
         }
         else {
            setCodeSended(false);
            if (
               resp.message === "Code is empty" ||
               resp.message === "Code is invalid"
            )
            {
               setCodeError("Ð£ÐºÐ°Ð·Ð°Ð½Ð½Ñ‹Ð¹ ÐºÐ¾Ð´ Ð½Ðµ ÑÐ¾Ð²Ð¿Ð°Ð´Ð°ÐµÑ‚ Ñ ÐºÐ¾Ð´Ð¾Ð¼, Ð¾Ñ‚Ð¿Ñ€Ð°Ð²Ð»ÐµÐ½Ð½Ñ‹Ð¼ Ð² Ð¡ÐœÐ¡.");
            }
            else {
               navigate("/profile");
            }
         }
      }
   }

   const phoneInput = (e) => {
      setPhoneError(false);
      setTelephone(e);
   }

   const payout = async (e) => {
      e.preventDefault();

      const auth = checkAuth();
      if (!auth) logOut();

      const body = new FormData();

      const phone = isOnReg === 0 ? loanData.phone_number : telephone;

      body.append("phone", phone);
      body.append("bank", loanData.banks[bank]);

      const resp = await new api("payout", auth, {
         method: "POST",
         body
      }).send();

      if (resp.status === "success") {
         localStorage.setItem("resultPage", JSON.stringify({
            title: "Ð”Ð¾Ð³Ð¾Ð²Ð¾Ñ€ Ð¿Ð¾Ð´Ð¿Ð¸ÑÐ°Ð½",
            text: "Ð—Ð°Ñ‘Ð¼ Ð±ÑƒÐ´ÐµÑ‚ Ð·Ð°Ñ‡Ð¸ÑÐ»ÐµÐ½ Ð½Ð° Ð’Ð°Ñˆ ÑÑ‡Ñ‘Ñ‚ Ð²&nbsp;Ð±Ð°Ð½ÐºÐµ <b style=\"white-space: nowrap;\">\""+loanData.banks[bank]+"\"</b> Ñ‡ÐµÑ€ÐµÐ· ÑÐ¸ÑÑ‚ÐµÐ¼Ñƒ Ð±Ñ‹ÑÑ‚Ñ€Ñ‹Ñ… Ð¿Ð»Ð°Ñ‚ÐµÐ¶ÐµÐ¹ Ð¿Ð¾&nbsp;Ð½Ð¾Ð¼ÐµÑ€Ñƒ <b style=\"white-space: nowrap;\">"+formatPhone(phone)+"</b> Ð²&nbsp;Ñ‚ÐµÑ‡ÐµÐ½Ð¸Ð¸ 24 Ñ‡Ð°ÑÐ¾Ð².",
            buttonText: "Ð’ÐµÑ€Ð½ÑƒÑ‚ÑŒÑÑ Ð² Ð»Ð¸Ñ‡Ð½Ñ‹Ð¹ ÐºÐ°Ð±Ð¸Ð½ÐµÑ‚",
            buttonLink: "/profile",
         }));
         navigate("/message");
      }
      else {
         if (resp.message === "Phone is empty" || resp.message === "Phone is invalid") {
            setPhoneError("ÐŸÑ€Ð¾Ð²ÐµÑ€ÑŒÑ‚Ðµ Ð¿Ñ€Ð°Ð²Ð¸Ð»ÑŒÐ½Ð¾ Ð»Ð¸ ÑƒÐºÐ°Ð·Ð°Ð½ Ð½Ð¾Ð¼ÐµÑ€ Ñ‚ÐµÐ»ÐµÑ„Ð¾Ð½Ð°.");
         }
         else if (resp.message === "Bank is empty" || resp.message === "Bank is invalid") {

         }
         else if (resp.message === "Can't payout loan") {
            navigate(0);
         }
         else {
            navigate("/profile");
         }
      }
   }

   return loanData !== null ? (
      !loanData.banks ? (
         <div className={approved}>
            <section className={approved__header}>
               <ContentHead
                  title={"ÐŸÐ¾Ð·Ð´Ñ€Ð°Ð²Ð»ÑÐµÐ¼! Ð’Ð°Ð¼ Ð¾Ð´Ð¾Ð±Ñ€ÐµÐ½ Ð·Ð°Ñ‘Ð¼ Ð½Ð° Ð»ÑƒÑ‡ÑˆÐ¸Ñ… ÑƒÑÐ»Ð¾Ð²Ð¸ÑÑ…!"}
                  subtitle={"Ð—Ð°Ð¹Ð¼ â„–" + loanData.number}
                  topSubtitle
               />
               <InfoCards data={[
                  {
                     title: "Ð¡ÑƒÐ¼Ð¼Ð° Ð·Ð°Ð¹Ð¼Ð°: ",
                     subtitle: loanData.total_amount / 100,
                     symbol: "â‚½"
                  },
                  {
                     title: "Ð¡Ñ‚Ð°Ð²ÐºÐ° Ð² Ð´ÐµÐ½ÑŒ: ",
                     subtitle: loanData.rate,
                     symbol: "%"
                  }
               ]} />
            </section>
            <section className={approved__confirm}>
               <ConfirmTelephone
                  number={loanData.phone_number}
                  onConfirm={confirm}
                  disableInput={codeSended}
                  inputError={codeError}
               />
            </section>
            {
               loanData.documents.length > 0 ? (
                  <section className={approved__document}>
                     <ContentHead
                        title={"Ð˜Ð½Ñ„Ð¾Ñ€Ð¼Ð°Ñ†Ð¸Ñ Ð¿Ð¾ Ð´Ð¾Ð³Ð¾Ð²Ð¾Ñ€Ñƒ"}
                        subtitle={"ÐŸÐ¾Ð¶Ð°Ð»ÑƒÐ¹ÑÑ‚Ð° Ð¾Ð·Ð½Ð°ÐºÐ¾Ð¼Ð¸Ñ‚ÐµÑÑŒ Ñ Ð¿Ð°ÐºÐµÑ‚Ð¾Ð¼ Ð´Ð¾ÐºÑƒÐ¼ÐµÐ½Ñ‚Ð¾Ð², ÐºÐ¾Ñ‚Ð¾Ñ€Ñ‹Ð¹ Ð´Ð»Ñ Ð²Ð°Ñ ÑÑ„Ð¾Ñ€Ð¼Ð¸Ñ€Ð¾Ð²Ð°Ð½:"}
                        bottomSubtitle
                     />
                     <DocumentList data={loanData.documents} />
                  </section>
               ) : ""
            }
            <section className={approved__service}>
               <ContentHead
                  title={"*Ð’ Ñ€Ð°Ð¼ÐºÐ°Ñ… Ð´Ð¾Ð¿Ð¾Ð»Ð½Ð¸Ñ‚ÐµÐ»ÑŒÐ½Ð¾Ð¹ ÑƒÑÐ»ÑƒÐ³Ð¸ \"Ð£Ð»ÑƒÑ‡ÑˆÐµÐ½Ð¸Ðµ ÐºÑ€ÐµÐ´Ð¸Ñ‚Ð½Ð¾Ð¹Â Ð¸ÑÑ‚Ð¾Ñ€Ð¸Ð¸\""}
                  subtitle={
                     "Ð’Ñ‹ ÑÐ¼Ð¾Ð¶ÐµÑ‚Ðµ ÑƒÐ»ÑƒÑ‡ÑˆÐ¸Ñ‚ÑŒ ÑÐ²Ð¾ÑŽ ÐºÑ€ÐµÐ´Ð¸Ñ‚Ð½ÑƒÑŽ Ð¸ÑÑ‚Ð¾Ñ€Ð¸ÑŽ Ð¸ Ð¿Ð¾ÐºÐ°Ð·Ð°Ñ‚ÑŒ ÑÐ²Ð¾ÑŽ Ñ„Ð¸Ð½Ð°Ð½ÑÐ¾Ð²ÑƒÑŽ Ð´Ð¸ÑÑ†Ð¸Ð¿Ð»Ð¸Ð½Ñƒ Ð´Ð»Ñ Ð¿Ð¾Ð»ÑƒÑ‡ÐµÐ½Ð¸Ñ Ð»ÑƒÑ‡ÑˆÐ¸Ñ… ÑƒÑÐ»Ð¾Ð²Ð¸Ð¹ Ð¿Ð¾ ÐºÑ€ÐµÐ´Ð¸Ñ‚ÑƒÂ Ð¸Ð»Ð¸Â Ð·Ð°Ð¹Ð¼Ñƒ."
                  }
                  bottomSubtitle
               />
               <List
                  text={"Ð ÐµÑ‰Ñ‘ Ð’Ð°ÑˆÐ° ÐºÑ€ÐµÐ´Ð¸Ñ‚Ð½Ð°Ñ Ð¸ÑÑ‚Ð¾Ñ€Ð¸Ñ:"}
                  data={[
                     "Ð‘ÑƒÐ´ÐµÑ‚ Ð²Ð¾ÑÑÑ‚Ð°Ð½Ð¾Ð²Ð»ÐµÐ½Ð° Ð¿Ð¾ÑÐ»Ðµ ÑƒÑÐ¿ÐµÑˆÐ½Ð¾Ð³Ð¾ Ð¿Ð¾Ð³Ð°ÑˆÐµÐ½Ð¸Ñ Ð·Ð°Ð¹Ð¼Ð°.",
                     "ÐŸÐ¾Ð»ÑƒÑ‡Ð¸Ñ‚ÑŒ Ð²Ð¾Ð·Ð¼Ð¾Ð¶Ð½Ð¾ÑÑ‚ÑŒ Ð¿Ð¾Ð»ÑŒÐ·Ð¾Ð²Ð°Ñ‚ÑŒÑÑ Ð¿ÐµÑ€ÑÐ¾Ð½Ð°Ð»ÑŒÐ½Ð¾Ð¹ Ð¿Ñ€Ð¾Ñ†ÐµÐ½Ñ‚Ð½Ð¾Ð¹Â ÑÑ‚Ð°Ð²ÐºÐ¾Ð¹.",
                  ]}
                  subtext={"ÐŸÐ¾Ð´Ñ€Ð¾Ð±Ð½ÐµÐµ Ð¾Ð·Ð½Ð°ÐºÐ¾Ð¼Ð¸Ñ‚ÑŒÑÑ ÑÐ¾ Ð²ÑÐµÐ¼Ð¸ ÑƒÑÐ»Ð¾Ð²Ð¸ÑÐ¼Ð¸ Ð²Ñ‹ Ð¼Ð¾Ð¶ÐµÑ‚Ðµ Ð½Ð° Ð½Ð°ÑˆÐµÐ¼ ÑÐ°Ð¹Ñ‚Ðµ Ð½Ð° ÑÑ‚Ñ€Ð°Ð½Ð¸Ñ†Ðµ: Ð”Ð¾Ð¿Ð¾Ð»Ð½Ð¸Ñ‚ÐµÐ»ÑŒÐ½Ñ‹Ðµ ÑƒÑÐ»ÑƒÐ³Ð¸ Ð² Ñ€Ð°Ð¼ÐºÐ°Ñ… ÑƒÐ»ÑƒÑ‡ÑˆÐµÐ½Ð¸Ñ ÐºÑ€ÐµÐ´Ð¸Ñ‚Ð½Ð¾Ð¹Â Ð¸ÑÑ‚Ð¾Ñ€Ð¸Ð¸."}
               />
            </section>
         </div>
      ) : (
         <div className={approved}>
            <section className={approved__header}>
               <ContentHead title={"Ð’Ñ‹Ð¿Ð»Ð°Ñ‚Ð° Ð·Ð°Ð¹Ð¼Ð°"} />
               <div className={block}>
                  <span className={block__text}>
                     Ð’Ñ‹Ð¿Ð»Ð°Ñ‚Ð° Ð·Ð°Ð¹Ð¼Ð° Ð¿Ñ€Ð¾Ð¸Ð·Ð²Ð¾Ð´Ð¸Ñ‚ÑÑ Ñ‡ÐµÑ€ÐµÐ· ÑÐ¸ÑÑ‚ÐµÐ¼Ñƒ Ð±Ñ‹ÑÑ‚Ñ€Ñ‹Ñ… Ð¿Ð»Ð°Ñ‚ÐµÐ¶ÐµÐ¹. Ð”ÐµÐ½ÑŒÐ³Ð¸ Ð¿Ð¾ÑÑ‚ÑƒÐ¿ÑÑ‚ Ð½Ð° Ð’Ð°Ñˆ ÑÑ‡Ñ‘Ñ‚ Ð² Ñ‚ÐµÑ‡ÐµÐ½Ð¸Ð¸ 24
                     Ñ‡Ð°ÑÐ¾Ð².
                  </span>
                  <IconSbp className={block__sbp} />
               </div>
            </section>
            <form action="" method="POST" className={approved__form} onSubmit={payout}>
               <InputRadio
                  className={""}
                  label="ÐÐ¾Ð¼ÐµÑ€ Ñ‚ÐµÐ»ÐµÑ„Ð¾Ð½Ð° Ð² Ð¡Ð‘ÐŸ ÑÐ¾Ð²Ð¿Ð°Ð´Ð°ÐµÑ‚ Ñ Ð½Ð¾Ð¼ÐµÑ€Ð¾Ð¼ Ñ‚ÐµÐ»ÐµÑ„Ð¾Ð½Ð° ÑƒÐºÐ°Ð·Ð°Ð½Ð½Ñ‹Ð¼ Ð¿Ñ€Ð¸ Ñ€ÐµÐ³Ð¸ÑÑ‚Ñ€Ð°Ñ†Ð¸Ð¸?"
                  options={yesNoArray}
                  onChange={setIsOnReg}
                  name={"isOnReg"}
                  value={isOnReg}
               />
               <div className={container}>
                  <Input
                     label={"ÐÐ¾Ð¼ÐµÑ€ Ñ‚ÐµÐ»ÐµÑ„Ð¾Ð½Ð°"}
                     placeholder="+7 (900) 800-70-60"
                     value={isOnReg === 0 ? loanData.phone_number.toString() : telephone}
                     key={isOnReg}
                     onChange={isOnReg === 0 ? () => {} : phoneInput}
                     mask="+7 (XXX) XXX-XX-XX"
                     regex={/^[0-9]$/}
                     error={phoneError}
                     disabled={!isOnReg ? true : false}
                  />
                  <DropDownList
                     label={"Ð‘Ð°Ð½Ðº Ð¿Ð¾Ð»ÑƒÑ‡Ð°Ñ‚ÐµÐ»Ñ"}
                     options={loanData.banks}
                     value={bank}
                     onChange={setBank}
                  />
               </div>
               <Button
                  className={container__button}
                  type="submit"
                  disabled={
                     (
                        (!/^[0-9]{10}$/.test(telephone) && isOnReg === 1) ||
                        (!/^[0-9]{10}$/.test(loanData.phone_number) && isOnReg === 0)
                     ) ||
                     !/^[0-9Ð°-ÑÐ-Ð¯Ñ‘Ða-zA-Z \-.()]{3,100}$/.test(loanData.banks[bank])
                  }
               >
                  ÐŸÐ¾Ð»ÑƒÑ‡Ð¸Ñ‚ÑŒ
               </Button>
            </form>
         </div>
      )
   ) : (
      <div style={{minHeight: "70vh", display: "flex", alignItems: "center", justifyContent: "center"}}>
         <ReactLoading type="spin" color="#2D9614" />
      </div>
   );
}

export default Approved;
