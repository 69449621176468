import { Link } from "react-router-dom";
import IconDropDownArrow from "../../Icons/DropDownArrow";
import IconMoneyWind from "../../Icons/MoneyWind";
import IconOk from "../../Icons/Ok";
import IconPensive from "../../Icons/Pensive";

import scss from "./index.module.scss";

const {
   list,
   list__item,
   list__title,
   list__text,
   list__time,
   container,
   icon,
   icon_chevron,
} = scss;

function LoanHistoryList(data) {
   const ICONS = [
      <IconPensive className={icon} />,
      <IconMoneyWind className={icon} />,
      <IconOk className={icon} />,
      <IconMoneyWind className={icon} />,
      <IconOk className={icon} />,
   ];

   const getStatus = (status) => {
      switch (status) {
         case 0: {
            return "ÐžÑ‚ÐºÐ°Ð· Ð¿Ð¾ Ð·Ð°Ð¹Ð¼Ñƒ";
         }
         case 1: {
            return "Ð—Ð°ÑÐ²ÐºÐ° Ð½Ð° Ð½Ð¾Ð²Ñ‹Ð¹ Ð·Ð°Ñ‘Ð¼";
         }
         case 2: {
            return "Ð—Ð°Ñ‘Ð¼ Ð¾Ð´Ð¾Ð±Ñ€ÐµÐ½";
         }
         case 3: {
            return "ÐŸÐ¾Ð»ÑƒÑ‡ÐµÐ½ Ð·Ð°Ñ‘Ð¼";
         }
         case 4: {
            return "Ð’Ñ‹ Ð²Ñ‹Ð¿Ð»Ð°Ñ‚Ð¸Ð»Ð¸ Ð·Ð°Ñ‘Ð¼";
         }
         default: {
            return "â€”";
         }
      }
   };

   return (
      <div className={list}>
         {data.data.map((item, key) =>
            item.status !== 3 && item.status !== 4 ? (
               <div className={list__item} key={key}>
                  {ICONS[item.status]}
                  <article className={container}>
                     <h3 className={list__title}>{getStatus(item.status)}</h3>
                     <p className={list__text}>
                        {item.amount
                           ? "Ð—Ð°Ñ‘Ð¼ Ð½Ð° ÑÑƒÐ¼Ð¼Ñƒ " +
                             (item.amount / 100).toLocaleString("ru-RU") +
                             " â‚½" +
                             (item.date_end
                                ? " Ð´Ð¾ " +
                                  new Date(item.date_end).toLocaleDateString("ru-RU") +
                                  " Ð¿Ð¾Ð´ " +
                                  item.rate +
                                  "% Ð² Ð´ÐµÐ½ÑŒ"
                                : "")
                           : ""}
                     </p>
                     <time className={list__time}>
                        {new Date(item.timestamp).toLocaleDateString("ru-RU")}
                     </time>
                  </article>
               </div>
            ) : (
               <Link className={list__item} to={"/loan/" + item.id_loan} key={key}>
                  {ICONS[item.status]}
                  <article className={container}>
                     <h3 className={list__title}>{getStatus(item.status)}</h3>
                     <p className={list__text}>
                        {item.amount
                           ? "Ð—Ð°Ñ‘Ð¼ Ð½Ð° ÑÑƒÐ¼Ð¼Ñƒ " +
                             (item.amount / 100).toLocaleString("ru-RU") +
                             " â‚½" +
                             (item.date_end
                                ? " Ð´Ð¾ " +
                                  new Date(item.date_end).toLocaleDateString("ru-RU") +
                                  " Ð¿Ð¾Ð´ " +
                                  item.rate +
                                  "% Ð² Ð´ÐµÐ½ÑŒ"
                                : "")
                           : ""}
                     </p>
                     <time className={list__time}>
                        {new Date(item.timestamp).toLocaleDateString("ru-RU")}
                     </time>
                  </article>
                  <IconDropDownArrow className={icon_chevron} />
               </Link>
            )
         )}
      </div>
   );
}

export default LoanHistoryList;
