import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../Button";
import Container from "../Container";

import scss from "./index.module.scss";

const { confirm, confirm__text, confirm_red, red } = scss;

function Confirm({ title, subtext, content, wait, number, amount, params }) {
   const token = localStorage.getItem("token");
   const navigate = useNavigate();
   const [denide, setDenide] = useState(false);

   const confirmPayment = async () => {
      if (!params.token) {
         if (token) {
            const body = new FormData();
            body.append("number", number);
            body.append("amount", amount);
            const json = await (
               await fetch(global.api + "payments", {
                  method: "POST",
                  headers: {
                     authorization: token,
                  },
                  body,
               })
            ).json();

            if (json?.status === "success") {
               localStorage.setItem("resultPage", "paymentDone");
               navigate("/payment-method/done");
            } else {
               setDenide(true);
            }
         }
      } else {
         const body = new FormData();
         body.append("number", number);
         body.append("amount", amount);
         body.append("token",  params.token);
         const json = await (
            await fetch(global.api + "payments", {
               method: "POST",
               body,
            })
         ).json();

         if (json?.status === "success") {
            localStorage.setItem("resultPage", "paymentDone");
            navigate("/oplatit/done");
         } else {
            setDenide(true);
         }
      }
   };

   return !denide ? (
      <Container title={title} subtext={subtext}>
         <div className={confirm}>
            <p className={confirm__text}>{content.text}</p>
            <Button isWaiting={wait ? false : true} onClick={confirmPayment} disabled={!number && !amount}>
               {content.buttonText}
            </Button>
         </div>
      </Container>
   ) : (
      <Container
         red
         title={"Ð§Ñ‚Ð¾-Ñ‚Ð¾ Ð¿Ð¾ÑˆÐ»Ð¾ Ð½Ðµ Ñ‚Ð°Ðº!"}
         subtext={
            "Ð¡ÐµÐ¹Ñ‡Ð°Ñ Ð½Ðµ Ð²Ð¾Ð·Ð¼Ð¾Ð¶Ð½Ð¾ Ð¿Ð¾Ð´Ñ‚Ð²ÐµÑ€Ð´Ð¸Ñ‚ÑŒ Ð¾Ð¿Ð»Ð°Ñ‚Ñƒ Ð¿Ð¾ Ñ‚ÐµÑ…Ð½Ð¸Ñ‡ÐµÑÐºÐ¸Ð¼ Ð¿Ñ€Ð¸Ñ‡Ð¸Ð½Ð°Ð¼. Ð’Ñ‹ Ð¼Ð¾Ð¶ÐµÑ‚Ðµ Ð¿Ð¾Ð¿Ñ€Ð¾Ð²Ð°Ñ‚ÑŒ Ð¿Ð¾Ð·Ð¶Ðµ Ð¸Ð»Ð¸ Ð¾Ð±Ñ€Ð°Ñ‚Ð¸Ñ‚ÑŒÑÑ Ð² ÑÐ»ÑƒÐ¶Ð±Ñƒ Ð¿Ð¾Ð´Ð´ÐµÑ€Ð¶ÐºÐ¸ ÐºÐ»Ð¸ÐµÐ½Ñ‚Ð¾Ð²."
         }
      >
         <div className={confirm + " " + confirm_red}>
            <p className={confirm__text}>ÐŸÑ€Ð¸Ð½Ð¾ÑÐ¸Ð¼ ÑÐ²Ð¾Ð¸ Ð¸Ð·Ð²Ð¸Ð½ÐµÐ½Ð¸Ñ. ÐŸÐ¾Ð´Ñ‚Ð²ÐµÑ€Ð¶Ð´ÐµÐ½Ð¸Ðµ Ð¿Ð»Ð°Ñ‚ÐµÐ¶Ð° Ð¼Ð¾Ð¶ÐµÑ‚ Ð·Ð°Ð½ÑÑ‚ÑŒ Ð´Ð¾Ð»ÑŒÑˆÐµ Ð²Ñ€ÐµÐ¼ÐµÐ½Ð¸.</p>
            <Button className={red} href={!params?.token ? "/profile" : "/"}>
               {!params?.token ? "Ð’ÐµÑ€Ð½ÑƒÑ‚ÑŒÑÑ Ð² Ð¿Ñ€Ð¾Ñ„Ð¸Ð»ÑŒ" : "ÐÐ° Ð³Ð»Ð°Ð²Ð½ÑƒÑŽ"}
            </Button>
         </div>
      </Container>
   );
}

export default Confirm;
