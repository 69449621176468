import formatPhone from "../../helpers/formatPhone";
import IconArrowSort from "../../manage/Components/Icons/IconArrowSort";
import Pagination from "../../manage/Components/Pagination";
import EmptyData from "../EmptyData";
import css from "./styles.module.scss";

const {
   table,
   table__head,
   table__headRow,
   table__headCell,
   table__sortButton,
   table__sortIcon,
   table__sortIcon_asc,
   table__sortIcon_desc,
   table__body,
   table__row,
   table__cell,
   table__cellSuccess,
   table__cellError,
} = css;

const EmptyBlock = <EmptyData title="ÐÐµÑ‚ Ð´Ð°Ð½Ð½Ñ‹Ñ…" />;

const Table = ({
   className = "",
   rows = [],
   colums = [],
   baseColumnsWidth = [],
   pagination = 0,
   onSort = () => {},
   sortParam = colums.length > 0 ? colums[0].paramName : null,
   sortDirection = "asc",
   blockOnEmpty = EmptyBlock,
   onPaginate,
}) => {
   let tmpClassName = table;
   tmpClassName += className ? " " + className : "";
   const scaleIndex =
      window.innerWidth >= 1600
         ? 1
         : window.innerWidth > 920
         ? 1920 / window.innerWidth
         : window.innerWidth > 525
         ? 744 / window.innerWidth
         : 320 / window.innerWidth;
   return rows.length > 0 ? (
      <>
         <table className={tmpClassName}>
            {colums.length > 0 ? (
               <thead className={table__head}>
                  <tr className={table__headRow}>
                     {colums.map(({ name, paramName }, i) => (
                        <td
                           key={i}
                           className={table__headCell}
                           valign="middle"
                           style={{
                              width: baseColumnsWidth[i] ? baseColumnsWidth[i] * scaleIndex + "px" : null,
                              minWidth: !baseColumnsWidth[i] ? 100 / colums.length + "%" : null,
                           }}
                        >
                           {name}

                           <button
                              className={table__sortButton}
                              type="button"
                              onClick={() =>
                                 onSort(
                                    paramName,
                                    paramName === sortParam ? (sortDirection === "asc" ? "desc" : "asc") : "asc"
                                 )
                              }
                           >
                              <IconArrowSort
                                 className={
                                    table__sortIcon +
                                    (sortParam === paramName
                                       ? " " + (sortDirection === "asc" ? table__sortIcon_asc : table__sortIcon_desc)
                                       : "")
                                 }
                              />
                           </button>
                        </td>
                     ))}
                  </tr>
               </thead>
            ) : (
               ""
            )}
            <tbody className={table__body}>
               {rows.map((cells, i) => (
                  <tr className={table__row} key={i}>
                     {cells.map(({ type = "text", value }, j) => (
                        <td
                           className={table__cell}
                           key={j}
                           style={{
                              maxWidth: baseColumnsWidth[j] ? baseColumnsWidth[j] * scaleIndex + "px" : null,
                              width: !baseColumnsWidth[j] ? 100 / colums.length + "%" : null,
                           }}
                        >
                           {type === "text" ? value : ""}
                           {type === "money" ? parseFloat(value / 100).toLocaleString("ru-RU") + " â‚½" : ""}
                           {type === "date" ? new Date(value).toLocaleDateString("ru-RU") : ""}
                           {type === "success" ? <span className={table__cellSuccess}>{value}</span> : ""}
                           {type === "error" ? <span className={table__cellError}>{value}</span> : ""}
                           {type === "tel" ? formatPhone(value) : ""}
                           {type === "component" ? value : ""}
                           {type === "link" ? (
                              <a
                                 href={value}
                                 title={"ÐžÑ‚ÐºÑ€Ñ‹Ñ‚ÑŒ Ð² ÑÐ¾ÑÐµÐ´Ð½ÐµÐ¹ ÑÐºÐ»Ð°Ð´ÐºÐµ: " + value}
                                 target={"_blank"}
                                 rel="noreferrer noopener"
                              >
                                 {value}
                              </a>
                           ) : (
                              ""
                           )}
                        </td>
                     ))}
                  </tr>
               ))}
            </tbody>
         </table>
         <Pagination data={pagination} paginate={onPaginate} />
      </>
   ) : (
      blockOnEmpty
   );
};

export default Table;
