import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import IconClock from "../../components/Icons/Clock";
import IconOops from "../../components/Icons/IconOops";

import scss from "./index.module.scss";

const { confirmed, confirmed__card, confirmed__icon, confirmed__title, confirmed__text } =
   scss;

function Confirmed({
   title = "Ð’Ð°ÑˆÐ° Ð·Ð°ÑÐ²ÐºÐ° Ð¿Ñ€Ð¸Ð½ÑÑ‚Ð° Ð² Ñ€Ð°Ð±Ð¾Ñ‚Ñƒ",
   text = "ÐšÐ°Ðº Ñ‚Ð¾Ð»ÑŒÐºÐ¾ Ñ€ÐµÑˆÐµÐ½Ð¸Ðµ Ð¿Ð¾ Ð’Ð°ÑˆÐµÐ¹ Ð·Ð°ÑÐ²ÐºÐµ Ð±ÑƒÐ´ÐµÑ‚ Ð¿Ñ€Ð¸Ð½ÑÑ‚Ð¾, Ð¼Ñ‹ Ð¾Ð¿Ð¾Ð²ÐµÑÑ‚Ð¸Ð¼ Ð’Ð°Ñ Ð¾Ð± ÑÑ‚Ð¾Ð¼ Ð² SMS. Ð’Ñ‹ Ð¼Ð¾Ð¶ÐµÑ‚Ðµ ÑÐ»ÐµÐ´Ð¸Ñ‚ÑŒ Ð·Ð° ÑÑ‚Ð°Ñ‚ÑƒÑÐ¾Ð¼ Ð²Ð°ÑˆÐµÐ¹ Ð·Ð°ÑÐ²ÐºÐ¸ Ð² Ð»Ð¸Ñ‡Ð½Ð¾Ð¼ ÐºÐ°Ð±Ð¸Ð½ÐµÑ‚Ðµ.",
   buttonText = "ÐŸÐµÑ€ÐµÐ¹Ñ‚Ð¸ Ð² Ð»Ð¸Ñ‡Ð½Ñ‹Ð¹ ÐºÐ°Ð±Ð¸Ð½ÐµÑ‚",
   buttonLink = "/profile",
}) {
   const navigation = useNavigate();
   const [access] = useState(localStorage.getItem("resultPage"));

   const [content, setContent] = useState({
      title,
      text,
      buttonText,
      buttonLink,
   });

   useEffect(() => {
      localStorage.removeItem("resultPage");
      if (!access) {
         navigation("/404");
      } else if (access !== true) {
         try {
            const data = JSON.parse(access);

            if (Object.keys(data).length > 0) {
               setContent({
                  title: data.title ? data.title : "Ð£Ð¿Ñ...",
                  text: data.text ? data.text : "",
                  buttonText: data.buttonText
                     ? data.buttonText
                     : "Ð’ÐµÑ€Ð½ÑƒÑ‚ÑŒÑÑ Ð² Ð»Ð¸Ñ‡Ð½Ñ‹Ð¹ ÐºÐ°Ð±Ð¸Ð½ÐµÑ‚",
                  buttonLink: data.buttonLink ? data.buttonLink : "/profile",
               });
            }
         } catch {}
      }
   }, []);

   return access ? (
      <section className={confirmed}>
         <article className={confirmed__card}>
            {content.title === "Ð›Ð¸Ñ‡Ð½Ñ‹Ð¹ ÐºÐ°Ð±Ð¸Ð½ÐµÑ‚ Ð·Ð°Ð±Ð»Ð¾ÐºÐ¸Ñ€Ð¾Ð²Ð°Ð½" ? (
               <IconOops className={confirmed__icon} />
            ) : (
               <IconClock className={confirmed__icon} />
            )}
            <h2
               className={confirmed__title}
               dangerouslySetInnerHTML={{ __html: content.title }}
            />
            <p
               className={confirmed__text}
               dangerouslySetInnerHTML={{ __html: content.text }}
            />
            <Button
               href={content.buttonLink}
               onClick={() => localStorage.removeItem("resultPage")}
            >
               {content.buttonText}
            </Button>
         </article>
      </section>
   ) : null;
}

export default Confirmed;
