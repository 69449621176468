import { async } from "q";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import BlockWrapper from "../../../components/BlockWrapper";
import Button from "../../../components/Button";
import IconLogo from "../../../components/Icons/Logo";
import Input from "../../../components/Input";
import api from "../../../helpers/fetch";

import css from "./styles.module.scss";

const {
    login,
    login__wrapper,
    login__logo,
    login__form,
    login__input,
    login__button,
    login__link
} = css;

const LoginManagePage = () => {

    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [errors, setErrors] = useState([]);

    const submit = async (e) => {
        e.preventDefault();

        if (validation()) {

            const body = new FormData();

            body.append("email", email);
            body.append("password", password);

            const resp = await (new api("manage/auth", false, {
                "method": "POST",
                body
            })).send();

            if (resp.status === "success") {
                localStorage.setItem("token", resp.data.token);
                localStorage.setItem("expireIn", resp.data.expire_in);
                localStorage.setItem("role", resp.data.role);
          
                navigate(0);
            }
            else {

                const errorsTmp = [null, null];

                if (
                    resp.message === "email is empty" ||
                    resp.message === "email is invalid" ||
                    resp.message === "password or email are invalid"
                ) {
                    errorsTmp[0] = "Ð­Ð». Ð¿Ð¾Ñ‡Ñ‚Ð° ÑƒÐºÐ°Ð·Ð°Ð½Ð° Ð½Ðµ Ð²ÐµÑ€Ð½Ð¾";
                }
                if (
                    resp.message === "password is empty" ||
                    resp.message === "password is invalid" ||
                    resp.message === "password or email are invalid"
                ) {
                    errorsTmp[1] = "ÐŸÐ°Ñ€Ð¾Ð»ÑŒ ÑƒÐºÐ°Ð·Ð°Ð½ Ð½Ðµ Ð²ÐµÑ€Ð½Ð¾";
                }
                if (resp.message === "user not found") {
                    errorsTmp[0] = "ÐÐµ ÑƒÐ´Ð°Ð»Ð¾ÑÑŒ Ð½Ð°Ð¹Ñ‚Ð¸ Ð¿Ð¾Ð»ÑŒÐ·Ð¾Ð²Ð°Ñ‚ÐµÐ»Ñ Ñ ÑƒÐºÐ°Ð·Ð°Ð½Ð½Ñ‹Ð¼Ð¸ Ð´Ð°Ð½Ð½Ñ‹Ð¼Ð¸";
                }
                if (resp.message === "can't authorize") {
                    errorsTmp[0] = "ÐÐµ ÑƒÐ´Ð°Ð»Ð¾ÑÑŒ Ð²Ð¾Ð¹Ñ‚Ð¸ Ð² ÑÐ¸ÑÑ‚ÐµÐ¼Ñƒ. ÐŸÐ¾Ð²Ñ‚Ð¾Ñ€Ð¸Ñ‚Ðµ Ð¿Ð¾Ð¿Ñ‹Ñ‚ÐºÑƒ Ð¿Ð¾Ð·Ð¶Ðµ.";
                }

                setErrors(errorsTmp);
            }
        }
    }

    const inputEmail = (e) => {
        const errorsTmp = errors;
        errorsTmp[0] = null;
        setErrors(errorsTmp);
        setEmail(e);
    }
    
    const inputPassword = (e) => {
        const errorsTmp = errors;
        errorsTmp[1] = null;
        setErrors(errorsTmp);
        setPassword(e);
    }

    const validation = () => {
        return (
            /^(\w|-|\.|_){2,}@(\w|-|\.|_){2,}\.\w{2,}$/.test(email) &&
            /^[a-zA-Z0-9Ð°-ÑÐ-Ð¯Ñ‘Ð\-_@!#$]{8,}$/.test(password)
        );
    }

    return (
        <div className={login}>
            <BlockWrapper className={login__wrapper}>
                <IconLogo className={login__logo} />
                <form
                    action="/"
                    method="POST"
                    className={login__form}
                    onSubmit={submit}
                >
                    <Input
                        className={login__input}
                        label="Ð­Ð». Ð¿Ð¾Ñ‡Ñ‚Ð°"
                        placeholder="ivanov-ivan@mail.ru"
                        type="text"
                        value={email}
                        name="email"
                        onChange={inputEmail}
                        error={errors[0] ? errors[0] : null}
                    />
                    <Input
                        className={login__input}
                        label="ÐŸÐ°Ñ€Ð¾Ð»ÑŒ"
                        type="password"
                        value={password}
                        name="password"
                        onChange={inputPassword}
                        error={errors[1] ? errors[1] : null}
                    />
                    <Button
                        type="submit"
                        className={login__button}
                        disabled={!validation()}
                    >Ð’Ð¾Ð¹Ñ‚Ð¸</Button>
                </form>
                <Link to={"/manage/reset-password/"} className={login__link}>Ð—Ð°Ð±Ñ‹Ð»Ð¸ Ð¿Ð°Ñ€Ð¾Ð»ÑŒ?</Link>
            </BlockWrapper>
        </div>
    )
}

export default LoginManagePage;