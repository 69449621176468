import { useState } from "react";
import { Link } from "react-router-dom";
import IconCharacterNumber from "../Icons/CharacterNumber";
import IconGreyScaleCard from "../Icons/GreyScaleCard";
import IconWalletCreditCard from "../Icons/IconWalletCreditCard";
import IconScan from "../Icons/Scan";

import scss from "./index.module.scss";

const { payments, payments__item, payments__container, payments__content, icon } = scss;

function PaymentList() {
   const [payment] = useState([
      {
         name: "ÐŸÐ¾ QR Ð² Ð¿Ñ€Ð¸Ð»Ð¾Ð¶ÐµÐ½Ð¸Ð¸ Ð±Ð°Ð½ÐºÐ°",
         time: "ÐžÑ‚ 1-Ð³Ð¾ Ð´Ð¾ 3-Ñ… Ð´Ð½ÐµÐ¹",
         link: "qr-code",
         isActive: true,
      },
      {
         name: "ÐŸÐ¾ Ñ€ÐµÐºÐ²Ð¸Ð·Ð¸Ñ‚Ð°Ð¼",
         time: "ÐžÑ‚ 1-Ð³Ð¾ Ð´Ð¾ 3-Ñ… Ð´Ð½ÐµÐ¹",
         link: "requisites",
         isActive: true,
      },
      {
         name: "ÐŸÐ¾ Ñ€ÐµÐºÐ²Ð¸Ð·Ð¸Ñ‚Ð°Ð¼ Ð±Ð°Ð½ÐºÐ¾Ð²ÑÐºÐ¾Ð¹ ÐºÐ°Ñ€Ñ‚Ñ‹",
         time: "ÐœÐ³Ð½Ð¾Ð²ÐµÐ½Ð½Ð¾Ðµ Ð¿Ð¾Ð³Ð°ÑˆÐµÐ½Ð¸Ðµ",
         link: "bank-card",
         isActive: true,
      },
      {
         name: "ÐšÐ°Ñ€Ñ‚Ð¾Ð¹ Ð¾Ð½Ð»Ð°Ð¹Ð½",
         time: "Ð¡ÐºÐ¾Ñ€Ð¾",
         link: "",
         isActive: false,
      },
   ]);

   const getIcon = (payment) => {
      switch (payment.name) {
         case "ÐŸÐ¾ QR Ð² Ð¿Ñ€Ð¸Ð»Ð¾Ð¶ÐµÐ½Ð¸Ð¸ Ð±Ð°Ð½ÐºÐ°": {
            return <IconScan className={icon} />;
         }
         case "ÐŸÐ¾ Ñ€ÐµÐºÐ²Ð¸Ð·Ð¸Ñ‚Ð°Ð¼": {
            return <IconCharacterNumber className={icon} />;
         }
         case "ÐšÐ°Ñ€Ñ‚Ð¾Ð¹ Ð¾Ð½Ð»Ð°Ð¹Ð½": {
            return <IconGreyScaleCard className={icon} />;
         }
         case "ÐŸÐ¾ Ñ€ÐµÐºÐ²Ð¸Ð·Ð¸Ñ‚Ð°Ð¼ Ð±Ð°Ð½ÐºÐ¾Ð²ÑÐºÐ¾Ð¹ ÐºÐ°Ñ€Ñ‚Ñ‹": {
            return <IconWalletCreditCard className={icon} />;
         }
         default:
            break;
      }
   };

   return (
      <div className={payments}>
         {payment.map((pay, key) =>
            pay.isActive ? (
               <Link
                  className={payments__item + " " + payments__container}
                  to={pay.link}
                  key={key}
               >
                  {getIcon(pay)}
                  <div className={payments__content}>
                     <h3>{pay.name}</h3>
                     <span>{pay.time}</span>
                  </div>
               </Link>
            ) : (
               <div
                  className={payments__item + " " + payments__container}
                  to={pay.link}
                  key={key}
               >
                  {getIcon(pay)}
                  <div className={payments__content}>
                     <h3>{pay.name}</h3>
                     <span>{pay.time}</span>
                  </div>
               </div>
            )
         )}
      </div>
   );
}

export default PaymentList;
