import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link, animateScroll as scroll } from "react-scroll";
import AboutAdvantages from "../../components/AboutAdvantages";
import ConfirmTelephone from "../../components/ConfirmTelephone";
import DocumentList from "../../components/DocumentList";
import Picture from "../../components/Picture";
import checkAuth from "../../helpers/checkAuth";
import api from "../../helpers/fetch";
import logOut from "../../helpers/logout";
import ReactLoading from "react-loading";

import scss from "./index.module.scss";

const {
   about,
   approved,
   approved__confirm,
   approved__title,
   offer,
   content,
   content__title,
   content__text,
   content__button,
   content__button_green,
   content__container,
   content__actions,
   offer__picture,
} = scss;

function VirtualCard() {
   const navigate = useNavigate();
   const [codeSended, setCodeSended] = useState(false);
   const [codeError, setCodeError] = useState(false);
   const [ready, setReady] = useState(false);
   const [stateCard, setStateCard] = useState("");
   const data = {
      title: "Ð§Ñ‚Ð¾ Ñ‚Ð°ÐºÐ¾Ðµ Ð²Ð¸Ñ€Ñ‚ÑƒÐ°Ð»ÑŒÐ½Ð°Ñ ÐºÐ°Ñ€Ñ‚Ð°?",
      items: [
         "ÐžÐ¿Ð»Ð°Ñ‡Ð¸Ð²Ð°Ð¹Ñ‚Ðµ Ð¿Ð¾ÐºÑƒÐ¿ÐºÐ¸ Ð² Ð¸Ð½Ñ‚ÐµÑ€Ð½ÐµÑ‚ Ð¼Ð°Ð³Ð°Ð·Ð¸Ð½Ð°Ñ… Ð¸Ð»Ð¸ Ð¾Ð¿Ð»Ð°Ñ‡Ð¸Ð²Ð°Ð¹Ñ‚Ðµ Ð¿Ð¾Ð´Ð¿Ð¸ÑÐºÐ¸ Ð½Ð° Ð¾Ð½Ð»Ð°Ð¹Ð½ ÑÐµÑ€Ð²Ð¸ÑÑ‹",
         "ÐžÐ¿Ð»Ð°Ñ‡Ð¸Ð²Ð°Ð¹Ñ‚Ðµ Ñ‚Ð¾Ð²Ð°Ñ€Ñ‹ Ð² Ð¼Ð°Ð³Ð°Ð·Ð¸Ð½Ð°Ñ… Ñ Ð¸ÑÐ¿Ð¾Ð»ÑŒÐ·Ð¾Ð²Ð°Ð½Ð¸ÐµÐ¼ Mir Pay Ð¸Ð»Ð¸ ÐšÐ¾ÑˆÐµÐ»Ñ‘Ðº Pay",
         "Ð‘Ñ‹ÑÑ‚Ñ€Ð¾Ðµ Ð¸ Ð¿Ñ€Ð¾ÑÑ‚Ð¾Ðµ Ð¾Ñ„Ð¾Ñ€Ð¼Ð»ÐµÐ½Ð¸Ðµ Ð±ÐµÐ· Ð¿Ð¾Ñ…Ð¾Ð´Ð° Ð² Ð¾Ñ‚Ð´ÐµÐ»ÐµÐ½Ð¸Ðµ Ð±Ð°Ð½ÐºÐ°",
         "ÐšÐ¾Ð½Ñ„Ð¸Ð´ÐµÐ½Ñ†Ð¸Ð°Ð»ÑŒÐ½Ð¾ÑÑ‚ÑŒ Ð¸ Ð·Ð°Ñ‰Ð¸Ñ‚Ð° Ð»Ð¸Ñ‡Ð½Ð¾Ð¹ Ð¸Ð½Ñ„Ð¾Ñ€Ð¼Ð°Ñ†Ð¸Ð¸",
         "Ð Ð°ÑÑÐ¼Ð¾Ñ‚Ñ€ÐµÐ½Ð¸Ðµ Ð·Ð°ÑÐ²ÐºÐ¸ Ð¾Ñ‚ 1 Ð¼Ð¸Ð½ÑƒÑ‚Ñ‹",
         "Ð¡Ð½Ð¸Ð¶Ð°ÐµÐ¼ ÑÑ‚Ð°Ð²ÐºÑƒ Ð¿Ð¾ÑÑ‚Ð¾ÑÐ½Ð½Ñ‹Ð¼ ÐºÐ»Ð¸ÐµÐ½Ñ‚Ð°Ð¼",
      ],
   };

   const onConfirm = async (code = 0) => {
      if (/^[0-9]{6}$/.test(code)) {
         setCodeSended(true);
         const body = new FormData();
         body.append("code", code);
         const auth = checkAuth();
         if (!auth) logOut();
         const resp = await new api("virtual_card_promo/code", auth, {
            method: "POST",
            body,
         }).send();
         if (resp.status === "success") {
            setCodeSended(false);
            navigate("/profile");
         } else {
            setCodeSended(false);
            if (resp.message === "Code is empty" || resp.message === "Code is invalid") {
               setCodeError("Ð£ÐºÐ°Ð·Ð°Ð½Ð½Ñ‹Ð¹ ÐºÐ¾Ð´ Ð½Ðµ ÑÐ¾Ð²Ð¿Ð°Ð´Ð°ÐµÑ‚ Ñ ÐºÐ¾Ð´Ð¾Ð¼, Ð¾Ñ‚Ð¿Ñ€Ð°Ð²Ð»ÐµÐ½Ð½Ñ‹Ð¼ Ð² Ð¡ÐœÐ¡.");
            } else {
               navigate("/profile");
            }
         }
      }
   };

   const onStart = async () => {
      const auth = checkAuth();
      if (auth === false) {
         alert();
         logOut();
         return;
      }
      const response = await new api("virtual_card_promo", auth).send();
      if (response.status === "success") {
         const { data } = response;
         setStateCard(data);
         setReady(true);
         if (data.status !== "created") navigate("/404");
      } else {
         logOut();
      }
   };

   useEffect(() => {
      onStart();
   }, []);

   return ready ? (
      <>
         <section className={offer}>
            <div className={content}>
               <div className={content__container}>
                  <h1 className={content__title}>Ð’Ð¸Ñ€Ñ‚ÑƒÐ°Ð»ÑŒÐ½Ð°Ñ ÐºÐ°Ñ€Ñ‚Ð°</h1>
                  <p className={content__text}>
                     ÐžÑ„Ð¾Ñ€Ð¼Ð¸Ñ‚Ðµ Ð±ÐµÑÐ¿Ð»Ð°Ñ‚Ð½ÑƒÑŽ Ð²Ð¸Ñ€Ñ‚ÑƒÐ°Ð»ÑŒÐ½ÑƒÑŽ ÐºÐ°Ñ€Ñ‚Ñƒ
                     <br /> Ñ ÐºÑ€ÐµÐ´Ð¸Ñ‚Ð½Ñ‹Ð¼ Ð»Ð¸Ð¼Ð¸Ñ‚Ð¾Ð¼ Ð´Ð¾{" "}
                     {stateCard.card_limit.toLocaleString("ru-RU")} â‚½ Ð¾Ð½Ð»Ð°Ð¹Ð½
                     <br /> Ð·Ð° Ð¿Ð°Ñ€Ñƒ Ð¼Ð¸Ð½ÑƒÑ‚!
                  </p>
               </div>
               <div className={content__actions}>
                  <Link
                     className={content__button + " " + content__button_green}
                     to={"confirm"}
                     spy={true}
                     smooth={true}
                     offset={-70}
                     duration={500}
                  >
                     ÐžÑ„Ð¾Ñ€Ð¼Ð¸Ñ‚ÑŒ
                  </Link>
                  <Link
                     className={content__button}
                     to={"advantages"}
                     spy={true}
                     smooth={true}
                     offset={-70}
                     duration={500}
                  >
                     ÐŸÐ¾Ð´Ñ€Ð¾Ð±Ð½ÐµÐµ
                  </Link>
               </div>
            </div>
            <Picture
               className={offer__picture}
               lazy
               fileName={"VirtualCardOffer"}
               alt={"Ð ÐµÐºÐ»Ð°Ð¼Ð½Ð¾Ðµ Ð¿Ñ€ÐµÐ´Ð»Ð¾Ð¶ÐµÐ½Ð¸Ðµ Ð¾Ð± Ð¾Ñ„Ð¾Ñ€Ð¼Ð»ÐµÐ½Ð¸Ð¸ Ð²Ð¸Ñ€Ñ‚ÑƒÐ°Ð»ÑŒÐ½Ð¾Ð¹ ÐºÐ°Ñ€Ñ‚Ñ‹."}
            />
         </section>
         <AboutAdvantages id={"advantages"} data={data} className={about} />
         <section id={"confirm"} className={approved}>
            <h2 className={approved__title}>ÐžÑ„Ð¾Ñ€Ð¼Ð¸Ñ‚ÑŒ Ð²Ð¸Ñ€Ñ‚ÑƒÐ°Ð»ÑŒÐ½ÑƒÑŽ ÐºÐ°Ñ€Ñ‚Ñƒ</h2>
            <DocumentList
               data={[
                  {
                     url: stateCard.document,
                     name: "Ð”Ð¾Ð³Ð¾Ð²Ð¾Ñ€ Ð¾ Ð¸ÑÐ¿Ð¾Ð»ÑŒÐ·Ð¾Ð²Ð°Ð½Ð¸Ð¸ Ð²Ð¸Ñ€Ñ‚ÑƒÐ°Ð»ÑŒÐ½Ð¾Ð¹ ÐºÐ°Ñ€Ñ‚Ñ‹",
                  },
               ]}
            />
            <div className={approved__confirm}>
               <ConfirmTelephone
                  number={stateCard.phone_number}
                  onConfirm={onConfirm}
                  disableInput={codeSended}
                  inputError={codeError}
                  VirtualCardGet={"virtual_card_promo/send_code"}
                  VirtualCardText={
                     "ÐÐ° Ð’Ð°Ñˆ Ð½Ð¾Ð¼ÐµÑ€ Ð±ÑƒÐ´ÐµÑ‚ Ð¾Ñ‚Ð¿Ñ€Ð°Ð²Ð»ÐµÐ½Ð¾ SMS-ÑÐ¾Ð¾Ð±Ñ‰ÐµÐ½Ð¸Ðµ Ñ ÐºÐ¾Ð´Ð¾Ð¼. Ð’Ð²ÐµÐ´Ð¸Ñ‚Ðµ Ð¿Ð¾Ð»ÑƒÑ‡ÐµÐ½Ð½Ñ‹Ð¹ ÐºÐ¾Ð´ Ð² Ð¿Ð¾Ð»Ðµ, Ñ‡Ñ‚Ð¾Ð±Ñ‹ Ð¿Ð¾Ð´Ð¿Ð¸ÑÐ°Ñ‚ÑŒ ÑÐ¾Ð³Ð»Ð°ÑÐ¸Ðµ Ñ Ð”Ð¾Ð³Ð¾Ð²Ð¾Ñ€ Ð¾ Ð¸ÑÐ¿Ð¾Ð»ÑŒÐ·Ð¾Ð²Ð°Ð½Ð¸Ð¸ Ð²Ð¸Ñ€Ñ‚ÑƒÐ°Ð»ÑŒÐ½Ð¾Ð¹ ÐºÐ°Ñ€Ñ‚Ñ‹."
                  }
               />
            </div>
         </section>
      </>
   ) : (
      <div
         style={{
            minHeight: "70vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
         }}
      >
         <ReactLoading type="spin" color="#2D9614" />
      </div>
   );
}

export default VirtualCard;
