import { useState } from "react";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import checkAuth from "../../../helpers/checkAuth";
import api from "../../../helpers/fetch";

import scss from "./index.module.scss";

const { help, help__title, help__description, list, list__item, form, form__textarea } =
   scss;

function Support() {
   const [topic, setTopic] = useState("");
   const [text, setText] = useState("");
   const [topicError, setTopicError] = useState("");
   const [textError, setTextError] = useState("");
   const [wait, setWait] = useState(false);

   const onSubmit = async (e) => {
      setWait(true);
      e.preventDefault();
      setTextError(validate(text ? text : "") ? "" : "ÐŸÐ¾Ð»Ðµ ÑÐ¾Ð´ÐµÑ€Ð¶ÐµÑ‚ Ð¾ÑˆÐ¸Ð±ÐºÑƒ Ð¸Ð»Ð¸ Ð¿ÑƒÑÑ‚Ð¾Ðµ");
      setTopicError(
         validate(topic ? topic : "") ? "" : "ÐŸÐ¾Ð»Ðµ ÑÐ¾Ð´ÐµÑ€Ð¶ÐµÑ‚ Ð¾ÑˆÐ¸Ð±ÐºÑƒ Ð¸Ð»Ð¸ Ð¿ÑƒÑÑ‚Ð¾Ðµ"
      );
      if (topic && text) {
         const auth = checkAuth();
         if (auth) {
            const body = new FormData();
            body.append("topic", topic);
            body.append("msg", text);
            const response = await new api("manage/support", auth, {
               method: "POST",
               body,
            }).send();
            if (response.status === "success") {
               setTopic("");
               setText("");
               setTextError("");
               setTopicError("");
            }
         }
      }
      setWait(false);
   };

   const validate = (text) => {
      let regexp = /^[A-Za-zÐ-Ð¯Ð°-ÑÐÑ‘0-9\s\.\,\-\(\)]{3,}$/.test(text);
      return regexp ? true : false;
   };

   return (
      <section className={help}>
         <h2 className={help__title}>Ð¢ÐµÑ…Ð½Ð¸Ñ‡ÐµÑÐºÐ°Ñ Ð¿Ð¾Ð´Ð´ÐµÑ€Ð¶ÐºÐ°</h2>
         <p className={help__description}>
            ÐžÐ¿Ð¸ÑˆÐ¸Ñ‚Ðµ Ð¿Ñ€Ð¾Ð±Ð»ÐµÐ¼Ñƒ, Ñ ÐºÐ¾Ñ‚Ð¾Ñ€Ð¾Ð¹ Ð²Ñ‹ ÑÑ‚Ð¾Ð»ÐºÐ½ÑƒÐ»Ð¸ÑÑŒ Ð² Ð¿Ñ€Ð¾Ñ†ÐµÑÑÐµ Ð¸ÑÐ¿Ð¾Ð»ÑŒÐ·Ð¾Ð²Ð°Ð½Ð¸Ñ ÑÐ¸ÑÑ‚ÐµÐ¼Ñ‹
            Ð¼Ð¾Ð´ÐµÑ€Ð°Ñ†Ð¸Ð¸. Ð”Ð»Ñ Ð½Ð°Ð¸Ð±Ð¾Ð»ÐµÐµ Ð¿Ð¾Ð»Ð½Ð¾Ð³Ð¾ Ð¿Ð¾Ð½Ð¸Ð¼Ð°Ð½Ð¸Ñ Ð¿Ñ€Ð¾Ð±Ð»ÐµÐ¼Ñ‹, Ñ ÐºÐ¾Ñ‚Ð¾Ñ€Ð¾Ð¹ Ð²Ñ‹ ÑÑ‚Ð¾Ð»ÐºÐ½ÑƒÐ»Ð¸ÑÑŒ
            Ð½Ð°Ð¼ Ð±ÑƒÐ´ÑƒÑ‚ Ð½ÐµÐ¾Ð±Ñ…Ð¾Ð´Ð¸Ð¼Ñ‹ Ð½ÐµÑÐºÐ¾Ð»ÑŒÐºÐ¾ Ð¼Ð¾Ð¼ÐµÐ½Ñ‚Ð¾Ð².
         </p>
         <ol className={list}>
            <li className={list__item}>
               ÐžÐ¿Ð¸ÑˆÐ¸Ñ‚Ðµ ÐºÐ°Ðº Ð¼Ð¾Ð¶Ð½Ð¾ Ð¿Ð¾Ð´Ñ€Ð¾Ð±Ð½ÐµÐµ Ð²Ð°ÑˆÐ¸ Ð´ÐµÐ¹ÑÑ‚Ð²Ð¸Ñ Ð´Ð¾ Ð²Ð¾Ð·Ð½Ð¸ÐºÐ½Ð¾Ð²ÐµÐ½Ð¸Ñ Ð¾ÑˆÐ¸Ð±ÐºÐ¸.
               (ÐÐ°Ð¿Ñ€Ð¸Ð¼ÐµÑ€: "1. Ð¯ Ð²Ð¾ÑˆÐµÐ» Ð² ÑÐ¸ÑÑ‚ÐµÐ¼Ñƒ. 2. ÐÐ°Ð¶Ð°Ð» Ð½Ð° ÐºÐ½Ð¾Ð¿ÐºÑƒ 'Ð—Ð°ÑÐ²ÐºÐ¸'... Ð¸ Ñ‚.Ð´.")
            </li>
            <li className={list__item}>
               Ð˜ÑÐ¿Ð¾Ð»ÑŒÐ·ÑƒÐ¹Ñ‚Ðµ Ð½ÑƒÐ¼ÐµÑ€Ð°Ñ†Ð¸ÑŽ Ð¸ Ð¿ÑƒÐ½ÐºÑ‚ÑƒÐ°Ñ†Ð¸ÑŽ, Ñ‡Ñ‚Ð¾Ð±Ñ‹ Ð¾Ð¿Ð¸ÑÐ°Ñ‚ÑŒ Ð¿Ñ€Ð¾Ð±Ð»ÐµÐ¼Ñƒ Ð¸Ð»Ð¸ Ð¿Ñ€Ð¾Ð±Ð»ÐµÐ¼Ñ‹.
            </li>
            <li className={list__item}>
               ÐœÑ‹ Ð¿Ð¾ÑÑ‚Ð°Ñ€Ð°ÐµÐ¼ÑÑ ÐºÐ°Ðº Ð¼Ð¾Ð¶Ð½Ð¾ ÑÐºÐ¾Ñ€ÐµÐµ Ñ€Ð°Ð·Ð¾Ð±Ñ€Ð°Ñ‚ÑŒÑÑ Ñ Ð¿Ñ€Ð¾Ð±Ð»ÐµÐ¼Ð¾Ð¹.
            </li>
         </ol>
         <form className={form} action="" method="post" onSubmit={onSubmit}>
            <Input
               label={"Ð¢ÐµÐ¼Ð° Ð¾Ð±Ñ€Ð°Ñ‰ÐµÐ½Ð¸Ñ"}
               placeholder={"ÐžÑˆÐ¸Ð±ÐºÐ° Ð¾Ñ‚Ð¾Ð±Ñ€Ð°Ð¶ÐµÐ½Ð¸Ñ ÑÑ‚Ñ€Ð°Ð½Ð¸Ñ†Ñ‹"}
               type={"text"}
               value={topic}
               onChange={setTopic}
               error={topicError}
               disabled={wait}
            />
            <Input
               className={form__textarea}
               label={"ÐžÐ¿Ð¸ÑˆÐ¸Ñ‚Ðµ Ð¿Ñ€Ð¾Ð±Ð»ÐµÐ¼Ñƒ"}
               type="textarea"
               value={text}
               onChange={(e) => setText(e[0])}
               error={textError}
               disabled={wait}
            />
            <Button isWaiting={wait} type="submit">
               ÐžÑ‚Ð¿Ñ€Ð°Ð²Ð¸Ñ‚ÑŒ Ð·Ð°ÑÐ²ÐºÑƒ
            </Button>
         </form>
      </section>
   );
}

export default Support;
