import Button from "../../components/Button";
import Header from "../../components/Header";
import NotFoundIcon from "../../components/Icons/NotFound";

import css from "./styles.module.scss";

const { errorPage, errorPage__img, errorPage__text, errorPage__button } = css;

function Error() {
   return (
      <>
         <Header />
         <main className={errorPage}>
            <NotFoundIcon className={errorPage__img} />
            <h1 className={errorPage__text}>Ð¡Ñ‚Ñ€Ð°Ð½Ð¸Ñ†Ð° Ð½Ðµ Ð½Ð°Ð¹Ð´ÐµÐ½Ð° Ð¸Ð»Ð¸&nbsp;Ð½ÐµÐ´Ð¾ÑÑ‚ÑƒÐ¿Ð½Ð°</h1>
            <Button href="/" className={errorPage__button}>
               Ð’ÐµÑ€Ð½ÑƒÑ‚ÑŒÑÑ Ð½Ð° Ð³Ð»Ð°Ð²Ð½ÑƒÑŽ
            </Button>
         </main>
      </>
   );
}

export default Error;
