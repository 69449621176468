import { useLocation, useNavigate, useParams } from "react-router-dom";
import Button from "../Button";
import scss from "./index.module.scss";

const {
   parent,
   parent__header,
   parent__title,
   parent__subtitle,
   parent__topButton,
   parent__bottomButton,
   parent__container,
} = scss;

function BackMenu({ children }) {
   const location = useLocation();
   const navigate = useNavigate();

   const getLocation = (pathname) => {
      const paths = pathname.split("/");

      if (paths[1] === "payment-method") {
         if (paths.length === 2) {
            return {
               title: "Ð’Ñ‹Ð±ÐµÑ€Ð¸Ñ‚Ðµ ÑÐ¿Ð¾ÑÐ¾Ð± Ð¾Ð¿Ð»Ð°Ñ‚Ñ‹",
               subtitle: "",
            };
         } else if (paths.length === 3) {
            if (paths[2] === "qr-code") {
               return {
                  title: "ÐžÐ¿Ð»Ð°Ñ‚Ð° Ð¿Ð¾ QR Ð² Ð¿Ñ€Ð¸Ð»Ð¾Ð¶ÐµÐ½Ð¸Ð¸ Ð±Ð°Ð½ÐºÐ°",
                  subtitle: "ÐžÑ‚ 1-Ð³Ð¾ Ð´Ð¾ 3-Ñ… Ð´Ð½ÐµÐ¹",
               };
            }
            if (paths[2] === "requisites") {
               return { title: "ÐžÐ¿Ð»Ð°Ñ‚Ð° Ð¿Ð¾ Ñ€ÐµÐºÐ²Ð¸Ð·Ð¸Ñ‚Ð°Ð¼", subtitle: "ÐžÑ‚ 1-Ð³Ð¾ Ð´Ð¾ 3-Ñ… Ð´Ð½ÐµÐ¹" };
            }
            if (paths[2] === "bank-card") {
               return {
                  title: "ÐžÐ¿Ð»Ð°Ñ‚Ð° Ð¿Ð¾ Ñ€ÐµÐºÐ²Ð¸Ð·Ð¸Ñ‚Ð°Ð¼ Ð±Ð°Ð½ÐºÐ¾Ð²ÑÐºÐ¾Ð¹ ÐºÐ°Ñ€Ñ‚Ñ‹",
                  subtitle: "ÐœÐ³Ð½Ð¾Ð²ÐµÐ½Ð½Ð¾Ðµ Ð¿Ð¾Ð³Ð°ÑˆÐµÐ½Ð¸Ðµ",
               };
            }

            return {
               title: "Ð’Ñ‹Ð±ÐµÑ€Ð¸Ñ‚Ðµ ÑÐ¿Ð¾ÑÐ¾Ð± Ð¾Ð¿Ð»Ð°Ñ‚Ñ‹",
               subtitle: "",
            };
         } else if (paths.length === 4) {
            if (paths[3] === "qr-code") {
               return {
                  title: "ÐžÐ¿Ð»Ð°Ñ‚Ð° Ð¿Ð¾ QR Ð² Ð¿Ñ€Ð¸Ð»Ð¾Ð¶ÐµÐ½Ð¸Ð¸ Ð±Ð°Ð½ÐºÐ°",
                  subtitle: "ÐœÐ³Ð½Ð¾Ð²ÐµÐ½Ð½Ð¾Ðµ Ð¿Ð¾Ð³Ð°ÑˆÐµÐ½Ð¸Ðµ",
               };
            }
            if (paths[3] === "requisites") {
               return { title: "ÐžÐ¿Ð»Ð°Ñ‚Ð° Ð¿Ð¾ Ñ€ÐµÐºÐ²Ð¸Ð·Ð¸Ñ‚Ð°Ð¼", subtitle: "ÐžÑ‚ 1-Ð³Ð¾ Ð´Ð¾ 3-Ñ… Ð´Ð½ÐµÐ¹" };
            }
            if (paths[3] === "bank-card") {
               return {
                  title: "ÐžÐ¿Ð»Ð°Ñ‚Ð° Ð¿Ð¾ Ñ€ÐµÐºÐ²Ð¸Ð·Ð¸Ñ‚Ð°Ð¼ Ð±Ð°Ð½ÐºÐ¾Ð²ÑÐºÐ¾Ð¹ ÐºÐ°Ñ€Ñ‚Ñ‹",
                  subtitle: "ÐœÐ³Ð½Ð¾Ð²ÐµÐ½Ð½Ð¾Ðµ Ð¿Ð¾Ð³Ð°ÑˆÐµÐ½Ð¸Ðµ",
               };
            }
         }
      } else if (paths[1] === "oplatit") {
         if (paths.length === 2) {
            return {
               title: "Ð’Ñ‹Ð±ÐµÑ€Ð¸Ñ‚Ðµ ÑÐ¿Ð¾ÑÐ¾Ð± Ð¾Ð¿Ð»Ð°Ñ‚Ñ‹",
               subtitle: "",
            };
         } else if (paths.length === 3) {
            if (paths[2] === "qr-code") {
               return {
                  title: "ÐžÐ¿Ð»Ð°Ñ‚Ð° Ð¿Ð¾ QR Ð² Ð¿Ñ€Ð¸Ð»Ð¾Ð¶ÐµÐ½Ð¸Ð¸ Ð±Ð°Ð½ÐºÐ°",
                  subtitle: "ÐžÑ‚ 1-Ð³Ð¾ Ð´Ð¾ 3-Ñ… Ð´Ð½ÐµÐ¹",
               };
            }
            if (paths[2] === "requisites") {
               return { title: "ÐžÐ¿Ð»Ð°Ñ‚Ð° Ð¿Ð¾ Ñ€ÐµÐºÐ²Ð¸Ð·Ð¸Ñ‚Ð°Ð¼", subtitle: "ÐžÑ‚ 1-Ð³Ð¾ Ð´Ð¾ 3-Ñ… Ð´Ð½ÐµÐ¹" };
            }
            if (paths[2] === "bank-card") {
               return {
                  title: "ÐžÐ¿Ð»Ð°Ñ‚Ð° Ð¿Ð¾ Ñ€ÐµÐºÐ²Ð¸Ð·Ð¸Ñ‚Ð°Ð¼ Ð±Ð°Ð½ÐºÐ¾Ð²ÑÐºÐ¾Ð¹ ÐºÐ°Ñ€Ñ‚Ñ‹",
                  subtitle: "ÐœÐ³Ð½Ð¾Ð²ÐµÐ½Ð½Ð¾Ðµ Ð¿Ð¾Ð³Ð°ÑˆÐµÐ½Ð¸Ðµ",
               };
            }

            return {
               title: "Ð’Ñ‹Ð±ÐµÑ€Ð¸Ñ‚Ðµ ÑÐ¿Ð¾ÑÐ¾Ð± Ð¾Ð¿Ð»Ð°Ñ‚Ñ‹",
               subtitle: "",
            };
         } else if (paths.length === 4) {
            if (paths[3] === "qr-code") {
               return {
                  title: "ÐžÐ¿Ð»Ð°Ñ‚Ð° Ð¿Ð¾ QR Ð² Ð¿Ñ€Ð¸Ð»Ð¾Ð¶ÐµÐ½Ð¸Ð¸ Ð±Ð°Ð½ÐºÐ°",
                  subtitle: "ÐžÑ‚ 1-Ð³Ð¾ Ð´Ð¾ 3-Ñ… Ð´Ð½ÐµÐ¹",
               };
            }
            if (paths[3] === "requisites") {
               return {
                  title: "ÐžÐ¿Ð»Ð°Ñ‚Ð° Ð¿Ð¾ Ñ€ÐµÐºÐ²Ð¸Ð·Ð¸Ñ‚Ð°Ð¼",
                  subtitle: "ÐžÑ‚ 1-Ð³Ð¾ Ð´Ð¾ 3-Ñ… Ð´Ð½ÐµÐ¹",
               };
            }
            if (paths[3] === "bank-card") {
               return {
                  title: "ÐžÐ¿Ð»Ð°Ñ‚Ð° Ð¿Ð¾ Ñ€ÐµÐºÐ²Ð¸Ð·Ð¸Ñ‚Ð°Ð¼ Ð±Ð°Ð½ÐºÐ¾Ð²ÑÐºÐ¾Ð¹ ÐºÐ°Ñ€Ñ‚Ñ‹",
                  subtitle: "ÐœÐ³Ð½Ð¾Ð²ÐµÐ½Ð½Ð¾Ðµ Ð¿Ð¾Ð³Ð°ÑˆÐµÐ½Ð¸Ðµ",
               };
            }

            return {
               title: "Ð’Ñ‹Ð±ÐµÑ€Ð¸Ñ‚Ðµ ÑÐ¿Ð¾ÑÐ¾Ð± Ð¾Ð¿Ð»Ð°Ñ‚Ñ‹",
               subtitle: "",
            };
         } else if (paths.length === 5) {
            if (paths[4] === "qr-code") {
               return {
                  title: "ÐžÐ¿Ð»Ð°Ñ‚Ð° Ð¿Ð¾ QR Ð² Ð¿Ñ€Ð¸Ð»Ð¾Ð¶ÐµÐ½Ð¸Ð¸ Ð±Ð°Ð½ÐºÐ°",
                  subtitle: "ÐžÑ‚ 1-Ð³Ð¾ Ð´Ð¾ 3-Ñ… Ð´Ð½ÐµÐ¹",
               };
            }
            if (paths[4] === "requisites") {
               return { title: "ÐžÐ¿Ð»Ð°Ñ‚Ð° Ð¿Ð¾ Ñ€ÐµÐºÐ²Ð¸Ð·Ð¸Ñ‚Ð°Ð¼", subtitle: "ÐžÑ‚ 1-Ð³Ð¾ Ð´Ð¾ 3-Ñ… Ð´Ð½ÐµÐ¹" };
            }
            if (paths[4] === "back-card") {
               return {
                  title: "ÐžÐ¿Ð»Ð°Ñ‚Ð° Ð¿Ð¾ Ñ€ÐµÐºÐ²Ð¸Ð·Ð¸Ñ‚Ð°Ð¼ Ð±Ð°Ð½ÐºÐ¾Ð²ÑÐºÐ¾Ð¹ ÐºÐ°Ñ€Ñ‚Ñ‹",
                  subtitle: "ÐœÐ³Ð½Ð¾Ð²ÐµÐ½Ð½Ð¾Ðµ Ð¿Ð¾Ð³Ð°ÑˆÐµÐ½Ð¸Ðµ",
               };
            }

            return {
               title: "Ð’Ñ‹Ð±ÐµÑ€Ð¸Ñ‚Ðµ ÑÐ¿Ð¾ÑÐ¾Ð± Ð¾Ð¿Ð»Ð°Ñ‚Ñ‹",
               subtitle: "",
            };
         }
      }
      navigate("/");
   };

   return (
      <div className={parent}>
         <div className={parent__header}>
            <h2 className={parent__title}>{getLocation(location.pathname).title}</h2>
            {getLocation(location.pathname).subtitle ? (
               <span className={parent__subtitle}>
                  {getLocation(location.pathname).subtitle}
               </span>
            ) : null}
         </div>
         <Button
            className={parent__topButton}
            variant={"gray"}
            onClick={() => navigate(-1)}
         >
            ÐÐ°Ð·Ð°Ð´
         </Button>
         <div className={parent__container}>{children}</div>
         <Button
            className={parent__bottomButton}
            variant={"gray"}
            onClick={() => navigate(-1)}
         >
            ÐÐ°Ð·Ð°Ð´
         </Button>
      </div>
   );
}

export default BackMenu;
