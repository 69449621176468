import IconCopy from "../../../components/Icons/Copy";
import Card from "../Card";
import scss from "./index.module.scss";

const { container, header, header__title, header__subtitle, wait, cards } = scss;

function InfoLoanHeader({ params, rate, amount, initials, id }) {
   return (
      <section className={container}>
         <div className={header}>
            <span className={header__title}>
               Ð—Ð°Ð¹Ð¼ â„–{params ? params : <div className={wait}></div>}{" "}
               <IconCopy onClick={() => navigator.clipboard.writeText(params)} />
            </span>
            <h2 className={header__subtitle}>Ð˜Ð½Ñ„Ð¾Ñ€Ð¼Ð°Ñ†Ð¸Ñ Ð¿Ð¾ Ð·Ð°Ð¹Ð¼Ñƒ</h2>
         </div>
         <div className={cards}>
            <Card
               type={amount !== 0 ? "base" : ""}
               title={"Ð¡Ñ‚Ð°Ð²ÐºÐ° Ð² Ð´ÐµÐ½ÑŒ"}
               text={rate.toLocaleString("ru-RU") + " %"}
            />
            <Card
               type={amount !== 0 ? "base" : ""}
               title={"Ð¡ÑƒÐ¼Ð¼Ð° Ð·Ð°Ð¹Ð¼Ð°"}
               text={amount.toLocaleString("ru-RU") + " â‚½"}
            />
            <Card type={amount !== 0 ? "linked" : ""} title={"ÐšÐ»Ð¸ÐµÐ½Ñ‚"} text={initials} id={id} />
         </div>
      </section>
   );
}

export default InfoLoanHeader;
