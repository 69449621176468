import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/Button";
import EmptyData from "../../../components/EmptyData";
import IconCopy from "../../../components/Icons/Copy";
import Input from "../../../components/Input";
import Table from "../../../components/Table";
import checkAuth from "../../../helpers/checkAuth";
import api from "../../../helpers/fetch";
import formatPhone from "../../../helpers/formatPhone";
import getFIO from "../../../helpers/getFIO";
import logOut from "../../../helpers/logout";
import Accordion from "../../Components/Accordion";
import AccordionItem from "../../Components/AccordionItem";
import Card from "../../Components/Card";
import IconArrowRight from "../../Components/Icons/IconArrowRight";
import LongNotice from "../../Components/longNotice";
import PopUp from "../../Components/PopUp";
import ReactLoading from "react-loading";

import scss from "./index.module.scss";

const {
   container,
   container__section_1,
   container__section_2,
   container__section_3,
   avatar,
   card__wrapper,
   card__title,
   card__link,
   card__link_tel,
   card__link_email,
   link__header,
   link__block,
   link__title,
   link__subtitle,
   passport,
   passport_row,
   passport__block,
   field,
   button,
   passport__block_col,
   form,
   form__text,
   form__buttons,
   form__fields,
   form__buttons_min,
   acord,
   textaria,
   notice,
   notice__text,
   notice__actions,
   notice__button,
   notice__button_red,
   about,
   about_item,
   about_text,
   about_text_nums,
   about_text_link,
   about_subtext,
} = scss;

function Customer() {
   const params = useParams();
   const navigate = useNavigate();
   const [isLoad, setIsLoad] = useState("");
   const [state, setState] = useState({});
   const [sort, setSort] = useState([]);
   const [row, setRow] = useState([]);
   const [password, setPassword] = useState([]);
   const [pagination, setPagination] = useState({});
   const [paginationPage, setPaginationPage] = useState(1);
   const [valueBlock, setValueBlock] = useState("");
   const [valueBlockError, setValueBlockError] = useState("");
   const [passwordError, setPasswordError] = useState("");

   const [isActive, setIsActive] = useState(false);
   const [isBlock, setIsBlock] = useState(false);
   const [isDelete, setIsDelite] = useState(false);

   const [isAddCard, setIsAddCard] = useState(false);
   const [isCancelCard, setIsCancelCard] = useState(false);
   const [cardNumber, setCardNumber] = useState("");
   const [cvc, setCVC] = useState("");
   const [cardMonth, setCardMonth] = useState("");
   const [cardYear, setCardYear] = useState("");
   const [cardLimit, setCardLimit] = useState("");
   const [ErrorCardNumber, setErrorCardNumber] = useState("");
   const [ErrorCvc, setErrorCVC] = useState("");
   const [ErrorCardMonth, setErrorCardMonth] = useState("");
   const [ErrorCardYear, setErrorCardYear] = useState("");
   const [ErrorCardLimit, setErrorCardLimit] = useState("");

   const [wait, setWait] = useState(false);

   const jobTypeArray = ["Ð Ð°Ð±Ð¾Ñ‚Ð°ÑŽ", "ÐÐµ Ñ€Ð°Ð±Ð¾Ñ‚Ð°ÑŽ", "ÐŸÐµÐ½ÑÐ¸Ð¾Ð½ÐµÑ€"];

   const nextIncomeArray = [
      "1Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "2Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "3Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "4Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "5Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "6Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "7Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "8Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "9Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "10Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "11Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "12Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "13Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "14Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "15Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "16Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "17Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "18Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "19Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "20Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "21Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "22Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "23Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "24Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "25Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "26Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "27Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "28Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "29Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "30Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
      "31Ð³Ð¾ Ñ‡Ð¸ÑÐ»Ð°",
   ];

   const incomeArray = [
      "Ð”Ð¾ 10 000 â‚½",
      "10 000 â‚½ - 20 000 â‚½",
      "20 000 â‚½ - 35 000 â‚½",
      "35 000 â‚½ - 50 000 â‚½",
      "Ð‘Ð¾Ð»ÐµÐµ 50 000 â‚½",
   ];

   const status = {
      confirmed: "ÐžÐ´Ð¾Ð±Ñ€ÐµÐ½",
      signed: "ÐŸÐ¾Ð´Ð¿Ð¸ÑÐ°Ð½",
      completed: "Ð’Ñ‹Ð¿Ð»Ð°Ñ‡ÐµÐ½",
      closed: "Ð—Ð°ÐºÑ€Ñ‹Ñ‚",
      denied: "ÐžÑ‚ÐºÐ»Ð¾Ð½Ñ‘Ð½",
      unpayed: "ÐÑƒÐ¶Ð½Ð° Ð²Ñ‹Ð¿Ð»Ð°Ñ‚Ð°",
   };

   const toTable = (rows = []) => {
      const tmpArray = [];
      rows.forEach((el, key) => {
         tmpArray.push([
            {
               type: "text",
               value: el.number,
            },
            {
               type: "money",
               value: el.total_amount,
            },
            {
               type: "text",
               value: status[el.status],
            },
            {
               type: "component",
               value:
                  el.status === "confirmed" ||
                  el.status === "signed" ||
                  el.status === "unpayed" ? (
                     <Link to={"/manage/loans/" + el.id_loan} className={button}>
                        ÐŸÐ¾Ð´Ñ€Ð¾Ð±Ð½ÐµÐµ
                     </Link>
                  ) : (
                     <Link to={"/manage/archive/" + el.id_loan} className={button}>
                        ÐŸÐ¾Ð´Ñ€Ð¾Ð±Ð½ÐµÐµ
                     </Link>
                  ),
            },
         ]);
      });
      return tmpArray;
   };

   const getData = async () => {
      const auth = checkAuth();
      if (!auth) logOut();
      const response = await new api(`manage/clients/${params.id}`, auth).send();
      if (response.status === "success") {
         setState(response.data.client);
         setIsLoad(true);
      } else navigate("/404");
   };

   const getTable = async () => {
      const auth = checkAuth();
      if (!auth) logOut();
      let queryParams = getSortQuery("status");
      const response = await new api(
         `manage/loans/?id_client=${params.id}&total&` +
            queryParams +
            "&page=" +
            (paginationPage !== 0 && paginationPage ? paginationPage : 1),
         auth
      ).send();
      if (response.status === "success") {
         setRow(toTable(response.data.data));
         setPagination(response.data.pagination);
      }
   };

   const getPasport = () => {
      if (state.pasport) {
         let pas = state.pasport.toString();
         return pas.slice(0, 4) + "-" + pas.slice(4, 10);
      } else return 0;
   };

   const getCode = () => {
      if (state.pasportOrgCode) {
         let pas = state.pasportOrgCode.toString();
         return pas.slice(0, 3) + "-" + pas.slice(3, 6);
      } else return 0;
   };

   const getSortQuery = (nameField) => {
      if (sort.length === 0) {
         setSort([nameField, "asc"]);
      } else {
         return "sort_param=" + sort[0] + "&sort_direction=" + sort[1];
      }
   };

   useEffect(() => {
      getData();
      getTable();
   }, []);

   useEffect(() => {
      getTable();
   }, [sort, paginationPage]);

   const onPaginate = (number) => {
      setPaginationPage(number);
   };

   const onOpenAddCard = () => {
      setIsAddCard(!isAddCard);
      document.body.style.overflowY = "hidden";
   };

   const onCloasAddCard = () => {
      setErrorCardNumber("");
      setErrorCardMonth("");
      setErrorCardYear("");
      setErrorCVC("");
      setErrorCardLimit("");
      setCardNumber("");
      setCVC("");
      setCardMonth("");
      setCardYear("");
      setCardLimit("");
      setIsAddCard(!isAddCard);
      document.body.style.overflowY = null;
   };

   const onOpenCancelCard = () => {
      setIsCancelCard(!isCancelCard);
      document.body.style.overflowY = "hidden";
   };

   const onCloasCancelCard = () => {
      setIsCancelCard(!isCancelCard);
      document.body.style.overflowY = null;
   };

   const onOpen = () => {
      setIsActive(!isActive);
      document.body.style.overflowY = "hidden";
   };

   const onCloas = () => {
      setIsActive(!isActive);
      document.body.style.overflowY = null;
      getData();
      getTable();
   };

   const onOpenBlock = () => {
      setIsBlock(!isBlock);
      document.body.style.overflowY = "hidden";
   };

   const onCloasBlock = () => {
      setIsBlock(!isBlock);
      document.body.style.overflowY = null;
      getData();
      getTable();
   };

   const onOpenDelete = () => {
      setIsDelite(!isDelete);
      document.body.style.overflowY = "hidden";
   };

   const onCloasDelete = () => {
      setIsDelite(!isDelete);
      document.body.style.overflowY = null;
   };

   const onSubmit = async (e) => {
      e.preventDefault();
      setWait(true);
      const auth = checkAuth();
      const response = await new api(
         `manage/clients/${state?.id_user ? state?.id_user : null}/reset_blocks`,
         auth,
         {
            method: "PUT",
         }
      ).send();
      if (response.status === "success") {
         onCloas();
         getData();
         setWait(false);
      } else {
         setWait(false);
      }
      setWait(false);
   };

   const onAddCard = async (e) => {
      e.preventDefault();
      setErrorCardNumber(
         /^[0-9]{16}$/.test(cardNumber) ? "" : "Ð’ Ð¿Ð¾Ð»Ðµ ÑÐ¾Ð´ÐµÑ€Ð¶Ð¸Ñ‚ÑÑ Ð¾ÑˆÐ¸Ð±ÐºÐ°"
      );
      setErrorCardMonth(
         parseInt(cardMonth) <= 12 && parseInt(cardMonth) > 0 ? "" : "ÐžÑˆÐ¸Ð±ÐºÐ°"
      );
      setErrorCardYear(
         /^[0-9]{2}$/.test(cardYear) && parseInt(cardYear) > 0 ? "" : "ÐžÑˆÐ¸Ð±ÐºÐ°"
      );
      setErrorCVC(/^[0-9]{3}$/.test(cvc) ? "" : "ÐžÑˆÐ¸Ð±ÐºÐ°");
      setErrorCardLimit(parseInt(cardLimit) > 0 ? "" : "Ð’ Ð¿Ð¾Ð»Ðµ ÑÐ¾Ð´ÐµÑ€Ð¶Ð¸Ñ‚ÑÑ Ð¾ÑˆÐ¸Ð±ÐºÐ°");
      setWait(true);
      if (
         ErrorCardNumber !== "Ð’ Ð¿Ð¾Ð»Ðµ ÑÐ¾Ð´ÐµÑ€Ð¶Ð¸Ñ‚ÑÑ Ð¾ÑˆÐ¸Ð±ÐºÐ°" &&
         ErrorCardMonth !== "ÐžÑˆÐ¸Ð±ÐºÐ°" &&
         ErrorCardYear !== "ÐžÑˆÐ¸Ð±ÐºÐ°" &&
         ErrorCvc !== "ÐžÑˆÐ¸Ð±ÐºÐ°" &&
         ErrorCardLimit !== "Ð’ Ð¿Ð¾Ð»Ðµ ÑÐ¾Ð´ÐµÑ€Ð¶Ð¸Ñ‚ÑÑ Ð¾ÑˆÐ¸Ð±ÐºÐ°"
      ) {
         if (cardNumber && cardMonth && cardYear && cvc && cardLimit) {
            const auth = checkAuth();
            const body = new FormData();
            body.append("number", cardNumber);
            body.append("month", parseInt(cardMonth));
            body.append("year", parseInt(cardYear));
            body.append("cvc", cvc);
            body.append("card_limit", cardLimit);
            const response = await new api(
               `manage/clients/${state?.id_user ? state?.id_user : null}/virtual_card`,
               auth,
               {
                  method: "POST",
                  body,
               }
            ).send();
            if (response.status === "success") {
               onCloasAddCard();
               setIsLoad(false);
               getData();
            } else if (response.message === "Month is invalid") {
               setErrorCardMonth("ÐžÑˆÐ¸Ð±ÐºÐ°");
            } else if (response.message === "Year is invalid") {
               setErrorCardYear("ÐžÑˆÐ¸Ð±ÐºÐ°");
            }
         }
      }
      setWait(false);
   };

   const onCancelCard = async (e) => {
      e.preventDefault();
      setWait(true);
      const auth = checkAuth();
      const response = await new api(
         `manage/clients/${state?.id_user ? state?.id_user : null}/virtual_card`,
         auth,
         {
            method: "delete",
         }
      ).send();
      if (response.status === "success") {
         onCloasCancelCard();
         setIsLoad(false);
         getData();
      }
      setWait(false);
   };

   const onSubmitBlock = async (e) => {
      e.preventDefault();
      setValueBlockError("");
      setWait(true);
      let regexp = /^[A-Za-zÐ-Ð¯Ð°-ÑÐÑ‘0-9\s\.\,\-\(\)]{3,}$/.test(valueBlock);
      if (regexp && valueBlock) {
         const auth = checkAuth();
         const body = JSON.stringify({
            reason: valueBlock,
         });
         const response = await new api(
            `manage/clients/${state?.id_user ? state?.id_user : null}/block`,
            auth,
            {
               method: "PUT",
               body,
            }
         ).send();
         if (response.status === "success") {
            setValueBlock("");
            onCloasBlock();
            getData();
            setWait(false);
         } else {
            setWait(false);
         }
      } else {
         setValueBlockError("Ð’ ÑÐ¾Ð¾Ð±Ñ‰ÐµÐ½Ð¸Ð¸ ÑÐ¾Ð´ÐµÑ€Ð¶Ð°Ñ‚ÑŒÑÑ Ð½Ðµ ÐºÐ¾Ñ€Ñ€ÐµÐºÑ‚Ð½Ñ‹Ðµ ÑÐ¸Ð¼Ð²Ð¾Ð»Ñ‹.");
      }
      setWait(false);
   };

   const onSubmitUnBlock = async (e) => {
      e.preventDefault();
      setWait(true);
      const auth = checkAuth();
      const response = await new api(
         `manage/clients/${state?.id_user ? state?.id_user : null}/unblock`,
         auth,
         {
            method: "PUT",
         }
      ).send();
      if (response.status === "success") {
         onCloasBlock();
         getData();
         setWait(false);
      } else {
         setWait(false);
      }

      setWait(false);
   };

   const onSubmitDelete = async (e) => {
      e.preventDefault();
      setPasswordError("");
      setWait(true);
      const auth = checkAuth();
      const body = JSON.stringify({
         password: password,
      });
      const response = await new api(
         `manage/clients/${state?.id_user ? state?.id_user : null}/remove`,
         auth,
         {
            method: "DELETE",
            body,
         }
      ).send();
      if (response.status === "success") {
         onCloasDelete();
         navigate(-1);
         setWait(false);
      } else {
         setWait(false);
         setPasswordError("ÐÐµ Ð²ÐµÑ€Ð½Ñ‹Ð¹ Ð¿Ð°Ñ€Ð¾Ð»ÑŒ Ð¸Ð»Ð¸ Ð½ÐµÐ¸Ð·Ð²ÐµÑÑ‚Ð½Ð°Ñ Ð¾ÑˆÐ¸Ð±ÐºÐ°.");
      }
      setWait(false);
      setPassword("");
   };

   return isLoad ? (
      <div className={container}>
         <Card
            type={state?.phone_number ? "wrapper" : ""}
            className={container__section_1}
         >
            <div className={card__wrapper}>
               <img
                  src="/assets/base_avatar.jpg"
                  className={avatar}
                  alt="ÐÐ²Ð°Ñ‚Ð°Ñ€ Ð¿Ð¾Ð»ÑŒÐ·Ð¾Ð²Ð°Ñ‚ÐµÐ»Ñ"
                  width="64"
                  height="64"
               />
               <h2 className={card__title}>
                  {state?.name || state?.surname
                     ? getFIO(state?.name, state?.surname, state?.midname, "ser-name-mid")
                     : "ÐÐ¾Ð²Ñ‹Ð¹ ÐºÐ»Ð¸ÐµÐ½Ñ‚"}
               </h2>
               <span className={card__link + " " + card__link_tel}>
                  <a href={"tel:+" + state?.phone_number}>
                     {formatPhone(state?.phone_number)}
                  </a>
                  <IconCopy
                     onClick={() =>
                        navigator.clipboard.writeText(formatPhone(state?.phone_number))
                     }
                  />
               </span>
               <span className={card__link + " " + card__link_email}>
                  <a href={"mailto:" + state?.email}>
                     {state?.email ? state?.email : "Ð•Ñ‰Ðµ Ð½Ðµ Ð¿Ñ€ÐµÐ´Ð¾ÑÑ‚Ð°Ð²Ð»ÐµÐ½Ð¾"}
                  </a>
                  <IconCopy
                     onClick={() =>
                        navigator.clipboard.writeText(
                           state?.email ? state?.email : "Ð•Ñ‰Ðµ Ð½Ðµ Ð¿Ñ€ÐµÐ´Ð¾ÑÑ‚Ð°Ð²Ð»ÐµÐ½Ð¾"
                        )
                     }
                  />
               </span>
            </div>
         </Card>
         {state?.id_loan ? (
            <Card
               type={state?.number ? "wrapper-link" : ""}
               id={state?.id_loan}
               className={container__section_2}
            >
               <span className={link__header}>
                  Ð¢ÐµÐºÑƒÑ‰Ð¸Ð¹ Ð·Ð°Ð¹Ð¼ â„–{state?.number} <IconArrowRight />
               </span>
               <div className={link__block}>
                  <span className={link__title}>Ð¡Ñ‚Ð°Ð²ÐºÐ° Ð² Ð´ÐµÐ½ÑŒ</span>
                  <span className={link__subtitle}>
                     {state?.rate.toLocaleString("ru-RU")} %
                  </span>
               </div>
               <div className={link__block}>
                  <span className={link__title}>Ð¡ÑƒÐ¼Ð¼Ð° Ð·Ð°Ð¹Ð¼Ð°</span>
                  <span className={link__subtitle}>
                     {(state?.total_amount / 100).toLocaleString("ru-RU")} â‚½
                  </span>
               </div>
            </Card>
         ) : state?.apply_block ? (
            <LongNotice buttonText={"Ð Ð°Ð·Ð±Ð»Ð¾ÐºÐ¸Ñ€Ð¾Ð²Ð°Ñ‚ÑŒ"} onClick={() => onOpen()}>
               <h2 className={card__title}>Ð—Ð°ÑÐ²ÐºÐ¸ Ð·Ð°Ð±Ð»Ð¾ÐºÐ¸Ñ€Ð¾Ð²Ð°Ð½Ñ‹</h2>
               <br />
               ÐžÑ‚ÐºÐ°Ð·Ð°Ð½Ð¾ Ð² Ð·Ð°Ð¹Ð¼Ðµ:{" "}
               <b>
                  {state?.denied_time
                     ? new Date(state?.denied_time).toLocaleString("ru-RU")
                     : new Date().toLocaleDateString("ru-RU")}
               </b>
               .
               <br />
               Ð”Ð°Ñ‚Ð° Ð°Ð²Ñ‚Ð¾-Ñ€Ð°Ð·Ð±Ð»Ð¾ÐºÐ¸Ñ€Ð¾Ð²ÐºÐ¸:{" "}
               <b>
                  ~
                  {state?.denied_end
                     ? new Date(state?.denied_end).toLocaleString("ru-RU")
                     : new Date().toLocaleDateString("ru-RU")}
               </b>
               .
            </LongNotice>
         ) : (
            <Card type={"wrapper"} className={container__section_2}>
               <span className={link__header}>Ð£ ÐºÐ»Ð¸ÐµÐ½Ñ‚Ð° Ð½ÐµÑ‚ Ð°ÐºÑ‚Ð¸Ð²Ð½Ð¾Ð³Ð¾ Ð·Ð°Ð¹Ð¼Ð°.</span>
               <div className={link__block}>
                  <span className={link__title}>Ð¡Ñ‚Ð°Ð²ÐºÐ° Ð² Ð´ÐµÐ½ÑŒ</span>
                  <span className={link__subtitle}>0.00 %</span>
               </div>
               <div className={link__block}>
                  <span className={link__title}>Ð¡ÑƒÐ¼Ð¼Ð° Ð·Ð°Ð¹Ð¼Ð°</span>
                  <span className={link__subtitle}>0.00 â‚½</span>
               </div>
            </Card>
         )}
         <Accordion className={container__section_3}>
            {state?.pasport ? (
               <>
                  <AccordionItem title={"ÐŸÐ°ÑÐ¿Ð¾Ñ€Ñ‚Ð½Ñ‹Ðµ Ð´Ð°Ð½Ð½Ñ‹Ðµ"}>
                     <div className={passport}>
                        <div className={passport__block}>
                           <span>ÐŸÐ°ÑÐ¿Ð¾Ñ€Ñ‚:</span>
                           <span
                              className={field}
                              onClick={() => navigator.clipboard.writeText(state.pasport)}
                           >
                              {getPasport()}
                           </span>
                        </div>
                        <div className={passport__block}>
                           <span>Ð”Ð°Ñ‚Ð° Ñ€Ð¾Ð¶Ð´ÐµÐ½Ð¸Ñ:</span>
                           <span
                              className={field}
                              onClick={() => navigator.clipboard.writeText(state.bday)}
                           >
                              {new Date(state.bday).toLocaleDateString("ru-RU")}
                           </span>
                        </div>
                        <div className={passport__block}>
                           <span>ÐœÐµÑÑ‚Ð¾ Ñ€Ð¾Ð¶Ð´ÐµÐ½Ð¸Ñ:</span>
                           <span
                              className={field}
                              onClick={() => navigator.clipboard.writeText(state.bplace)}
                           >
                              {state.bplace}
                           </span>
                        </div>
                        <div className={passport__block}>
                           <span>ÐšÐ¾Ð´ Ð¿Ð¾Ð´Ñ€Ð°Ð·Ð´ÐµÐ»ÐµÐ½Ð¸Ñ:</span>
                           <span
                              className={field}
                              onClick={() =>
                                 navigator.clipboard.writeText(state.pasportOrgCode)
                              }
                           >
                              {getCode()}
                           </span>
                        </div>
                        <div className={passport__block}>
                           <span>Ð”Ð°Ñ‚Ð° Ð²Ñ‹Ð´Ð°Ñ‡Ð¸:</span>
                           <span
                              className={field}
                              onClick={() =>
                                 navigator.clipboard.writeText(state.pasportDateGet)
                              }
                           >
                              {new Date(state.pasportDateGet).toLocaleDateString("ru-RU")}
                           </span>
                        </div>
                        <div className={passport__block}>
                           <span>ÐšÐµÐ¼ Ð²Ñ‹Ð´Ð°Ð½:</span>
                           <span
                              className={field}
                              onClick={() =>
                                 navigator.clipboard.writeText(state.pasportOrg)
                              }
                           >
                              {state.pasportOrg}
                           </span>
                        </div>
                        {state.inn ? (
                           <>
                              <div className={passport__block}>
                                 <span>Ð˜ÐÐ:</span>
                                 <span
                                    className={field}
                                    onClick={() =>
                                       navigator.clipboard.writeText(state.inn)
                                    }
                                 >
                                    {state.inn}
                                 </span>
                              </div>
                           </>
                        ) : null}
                        <div className={passport__block}>
                           <span>ÐÐ´Ñ€ÐµÑ Ñ€ÐµÐ³Ð¸ÑÑ‚Ñ€Ð°Ñ†Ð¸Ð¸:</span>
                           <span
                              className={field}
                              onClick={() =>
                                 navigator.clipboard.writeText(state.registration)
                              }
                           >
                              {state.registration}
                           </span>
                        </div>
                        {state.isOnReg === 1 ? (
                           <div className={passport__block}>
                              <span>ÐÐ´Ñ€ÐµÑ Ð¿Ñ€Ð¾Ð¶Ð¸Ð²Ð°Ð½Ð¸Ñ:</span>
                              <span
                                 className={field}
                                 onClick={() =>
                                    navigator.clipboard.writeText(state.living)
                                 }
                              >
                                 {state.living}
                              </span>
                           </div>
                        ) : null}
                        <hr />
                        <div className={passport + " " + passport_row}>
                           <div className={passport__block + " " + passport__block_col}>
                              <span>Ð¤Ð¾Ñ‚Ð¾Ð³Ñ€Ð°Ñ„Ð¸Ñ Ñ€Ð°Ð·Ð²Ð¾Ñ€Ð¾Ñ‚Ð°:</span>
                              <a
                                 href={state.photo1}
                                 target={"_blank"}
                                 title={"ÐžÑ‚ÐºÑ€Ñ‹Ñ‚ÑŒ Ð² Ð½Ð¾Ð²Ð¾Ð¹ Ð²ÐºÐ»Ð°Ð´ÐºÐµ " + state.photo1}
                                 rel="noreferrer"
                                 className={field}
                              >
                                 <img
                                    src={
                                       state.photo1
                                          ? state.photo1
                                          : "https://upload.wikimedia.org/wikipedia/commons/4/46/%D0%A1%D0%B5%D1%80%D1%8B%D0%B9_%D1%86%D0%B2%D0%B5%D1%82-_2014-03-15_18-16.jpg"
                                    }
                                    alt="Ð¤Ð¾Ñ‚Ð¾Ð³Ñ€Ð°Ñ„Ð¸Ñ Ñ€Ð°Ð·Ð²Ð¾Ñ€Ð¾Ñ‚Ð° Ð¿Ð°ÑÐ¿Ð¾Ñ€Ñ‚Ð°"
                                    width={"256px"}
                                    height={"128px"}
                                 />
                              </a>
                           </div>
                           <div className={passport__block + " " + passport__block_col}>
                              <span>Ð¤Ð¾Ñ‚Ð¾Ð³Ñ€Ð°Ñ„Ð¸Ñ Ñ€ÐµÐ³Ð¸ÑÑ‚Ñ€Ð°Ñ†Ð¸Ð¸:</span>
                              <a
                                 href={state.photo2}
                                 target={"_blank"}
                                 title={"ÐžÑ‚ÐºÑ€Ñ‹Ñ‚ÑŒ Ð² Ð½Ð¾Ð²Ð¾Ð¹ Ð²ÐºÐ»Ð°Ð´ÐºÐµ " + state.photo2}
                                 rel="noreferrer"
                                 className={field}
                              >
                                 <img
                                    src={
                                       state.photo2
                                          ? state.photo2
                                          : "https://upload.wikimedia.org/wikipedia/commons/4/46/%D0%A1%D0%B5%D1%80%D1%8B%D0%B9_%D1%86%D0%B2%D0%B5%D1%82-_2014-03-15_18-16.jpg"
                                    }
                                    alt="Ð¤Ð¾Ñ‚Ð¾Ð³Ñ€Ð°Ñ„Ð¸Ñ Ñ€ÐµÐ³Ð¸ÑÑ‚Ñ€Ð°Ñ†Ð¸Ð¸"
                                    width={"256px"}
                                    height={"128px"}
                                 />
                              </a>
                           </div>
                           <div className={passport__block + " " + passport__block_col}>
                              <span>Ð¤Ð¾Ñ‚Ð¾Ð³Ñ€Ð°Ñ„Ð¸Ñ Ñ Ð¿Ð°ÑÐ¿Ð¾Ñ€Ñ‚Ð¾Ð¼:</span>
                              <a
                                 href={state.photo3}
                                 target={"_blank"}
                                 title={"ÐžÑ‚ÐºÑ€Ñ‹Ñ‚ÑŒ Ð² Ð½Ð¾Ð²Ð¾Ð¹ Ð²ÐºÐ»Ð°Ð´ÐºÐµ " + state.photo3}
                                 rel="noreferrer"
                                 className={field}
                              >
                                 <img
                                    src={
                                       state.photo3
                                          ? state.photo3
                                          : "https://upload.wikimedia.org/wikipedia/commons/4/46/%D0%A1%D0%B5%D1%80%D1%8B%D0%B9_%D1%86%D0%B2%D0%B5%D1%82-_2014-03-15_18-16.jpg"
                                    }
                                    alt="Ð¤Ð¾Ñ‚Ð¾Ð³Ñ€Ð°Ñ„Ð¸Ñ Ñ Ð¿Ð°ÑÐ¿Ð¾Ñ€Ñ‚Ð¾Ð¼"
                                    width={"256px"}
                                    height={"128px"}
                                 />
                              </a>
                           </div>
                           <div className={passport__block + " " + passport__block_col}>
                              <span>Ð¤Ð¾Ñ‚Ð¾Ð³Ñ€Ð°Ñ„Ð¸Ñ Ð¡ÐÐ˜Ð›Ð¡Ð°:</span>
                              <a
                                 href={state.photo4}
                                 target={"_blank"}
                                 title={"ÐžÑ‚ÐºÑ€Ñ‹Ñ‚ÑŒ Ð² Ð½Ð¾Ð²Ð¾Ð¹ Ð²ÐºÐ»Ð°Ð´ÐºÐµ  " + state.photo4}
                                 rel="noreferrer"
                                 className={field}
                              >
                                 <img
                                    src={
                                       state.photo4
                                          ? state.photo4
                                          : "https://upload.wikimedia.org/wikipedia/commons/4/46/%D0%A1%D0%B5%D1%80%D1%8B%D0%B9_%D1%86%D0%B2%D0%B5%D1%82-_2014-03-15_18-16.jpg"
                                    }
                                    alt="Ð¤Ð¾Ñ‚Ð¾Ð³Ñ€Ð°Ñ„Ð¸Ñ Ð¡ÐÐ˜Ð›Ð¡Ð°"
                                    width={"256px"}
                                    height={"128px"}
                                 />
                              </a>
                           </div>
                        </div>
                     </div>
                  </AccordionItem>
                  <AccordionItem title={"Ð”Ð°Ð½Ð½Ñ‹Ðµ Ð¾ Ð´Ð¾Ñ…Ð¾Ð´Ðµ"}>
                     {state?.jobType !== "" ? (
                        <div className={passport}>
                           <div className={passport__block}>
                              <span>Ð’Ð¸Ð´ Ð·Ð°Ð½ÑÑ‚Ð¾ÑÑ‚Ð¸:</span>
                              <span
                                 className={field}
                                 onClick={() =>
                                    navigator.clipboard.writeText(state?.jobType)
                                 }
                              >
                                 {state?.jobType !== ""
                                    ? jobTypeArray[state?.jobType]
                                    : "ÐÐµ ÑƒÐºÐ°Ð·Ð°Ð½Ð¾"}
                              </span>
                           </div>
                           {state?.jobType === 0 ? (
                              <>
                                 <div className={passport}>
                                    <div className={passport__block}>
                                       <span>ÐœÐµÑÑ‚Ð¾ Ñ€Ð°Ð±Ð¾Ñ‚Ñ‹:</span>
                                       <span
                                          className={field}
                                          onClick={() =>
                                             navigator.clipboard.writeText(
                                                state.jobPlace
                                                   ? state.jobPlace
                                                   : "ÐÐµ ÑƒÐºÐ°Ð·Ð°Ð½Ð¾"
                                             )
                                          }
                                          dangerouslySetInnerHTML={{
                                             __html: state.jobPlace
                                                ? state.jobPlace
                                                : "ÐÐµ ÑƒÐºÐ°Ð·Ð°Ð½Ð¾",
                                          }}
                                       ></span>
                                    </div>
                                 </div>
                                 <div className={passport}>
                                    <div className={passport__block}>
                                       <span>Ð”Ð¾Ð»Ð¶Ð½Ð¾ÑÑ‚ÑŒ:</span>
                                       <span
                                          className={field}
                                          onClick={() =>
                                             navigator.clipboard.writeText(
                                                state.jobPosition
                                             )
                                          }
                                       >
                                          {state.jobPosition
                                             ? state.jobPosition
                                             : "ÐÐµ ÑƒÐºÐ°Ð·Ð°Ð½Ð¾"}
                                       </span>
                                    </div>
                                 </div>
                                 <div className={passport}>
                                    <div className={passport__block}>
                                       <span>ÐÐ´Ñ€ÐµÑ Ð¾Ñ€Ð³Ð°Ð½Ð¸Ð·Ð°Ñ†Ð¸Ð¸:</span>
                                       <span
                                          className={field}
                                          onClick={() =>
                                             navigator.clipboard.writeText(
                                                state.jobAddress
                                             )
                                          }
                                       >
                                          {state.jobAddress
                                             ? state.jobAddress
                                             : "ÐÐµ ÑƒÐºÐ°Ð·Ð°Ð½Ð¾"}
                                       </span>
                                    </div>
                                 </div>
                                 <div className={passport}>
                                    <div className={passport__block}>
                                       <span>ÐÐ¾Ð¼ÐµÑ€ Ñ‚ÐµÐ»ÐµÑ„Ð¾Ð½Ð°:</span>
                                       <a
                                          href={"tel:+7" + state.jobPhone}
                                          className={field}
                                          onClick={() =>
                                             navigator.clipboard.writeText(
                                                formatPhone(state.jobPhone)
                                             )
                                          }
                                       >
                                          {state.jobPhone
                                             ? formatPhone(state.jobPhone)
                                             : "ÐÐµ ÑƒÐºÐ°Ð·Ð°Ð½Ð¾"}
                                       </a>
                                    </div>
                                 </div>
                                 <div className={passport}>
                                    <div className={passport__block}>
                                       <span>Ð‘Ð»Ð¸Ð¶Ð°Ð¹ÑˆÐ°Ñ Ð²Ñ‹Ð¿Ð»Ð°Ñ‚Ð°:</span>
                                       <span
                                          className={field}
                                          onClick={() =>
                                             navigator.clipboard.writeText(
                                                state.nextIncome || state.nextIncome === 0
                                                   ? nextIncomeArray[state.nextIncome]
                                                   : "ÐÐµ ÑƒÐºÐ°Ð·Ð°Ð½Ð¾"
                                             )
                                          }
                                       >
                                          {state.nextIncome || state.nextIncome === 0
                                             ? nextIncomeArray[state.nextIncome]
                                             : "ÐÐµ ÑƒÐºÐ°Ð·Ð°Ð½Ð¾"}
                                       </span>
                                    </div>
                                 </div>
                              </>
                           ) : null}
                           <div className={passport}>
                              <div className={passport__block}>
                                 <span>Ð•Ð¶ÐµÐ¼ÐµÑÑÑ‡Ð½Ñ‹Ð¹ Ð´Ð¾Ñ…Ð¾Ð´:</span>
                                 <span
                                    className={field}
                                    onClick={() =>
                                       navigator.clipboard.writeText(
                                          state.income || state.income === 0
                                             ? incomeArray[state.income]
                                             : "ÐÐµ ÑƒÐºÐ°Ð·Ð°Ð½Ð¾"
                                       )
                                    }
                                 >
                                    {state.income || state.income === 0
                                       ? incomeArray[state.income]
                                       : "ÐÐµ ÑƒÐºÐ°Ð·Ð°Ð½Ð¾"}
                                 </span>
                              </div>
                           </div>
                        </div>
                     ) : (
                        <div className={passport}>
                           <div className={passport__block}>
                              <span>Ð˜Ð½Ñ„Ð¾Ñ€Ð¼Ð°Ñ†Ð¸Ñ Ð¾Ñ‚ÑÑƒÑ‚Ð²ÑƒÐµÑ‚</span>
                           </div>
                        </div>
                     )}
                  </AccordionItem>

                  <AccordionItem title={"Ð’Ñ‹Ð¿ÑƒÑÐº Ð²Ð¸Ñ€Ñ‚ÑƒÐ°Ð»ÑŒÐ½Ð¾Ð¹ ÐºÐ°Ñ€Ñ‚Ñ‹"}>
                     <div className={acord}>
                        <div className={notice}>
                           <p className={notice__text}>
                              {
                                 state?.virtual_card.status === "none" ? "Ð’Ð¸Ñ€Ñ‚ÑƒÐ°Ð»ÑŒÐ½Ð°Ñ ÐºÐ°Ñ€Ñ‚Ð° ÐºÐ»Ð¸ÐµÐ½Ñ‚Ð°" : (
                                    <b>
                                       ÐšÐ°Ñ€Ñ‚Ð°: {
                                          state?.virtual_card.number
                                             .toString()
                                             .split(/(\d{4})/)
                                             .filter((item) => item !== "")
                                             .join(" ")
                                       } | Ð”ÐµÐ¹ÑÑ‚Ð²Ð¸Ñ‚ÐµÐ»ÑŒÐ½Ð° Ð´Ð¾ {
                                          state?.virtual_card.month +
                                          " / " +
                                          state?.virtual_card.year
                                       }
                                    </b>
                                 )
                              }
                           </p>
                           <div className={notice__actions}>
                              {state?.virtual_card.status === "none" ? (
                                 <button
                                    className={notice__button}
                                    type="button"
                                    onClick={() => onOpenAddCard()}
                                 >
                                    Ð”Ð¾Ð±Ð°Ð²Ð¸Ñ‚ÑŒ
                                 </button>
                              ) : (
                                 <>
                                    <button
                                       className={
                                          notice__button + " " + notice__button_red
                                       }
                                       type="button"
                                       onClick={() => onOpenCancelCard()}
                                    >
                                       ÐžÑ‚ÐºÐ»ÑŽÑ‡Ð¸Ñ‚ÑŒ ÐºÐ°Ñ€Ñ‚Ñƒ
                                    </button>
                                 </>
                              )}
                           </div>
                        </div>
                        {state?.virtual_card.status !== "none" ? (
                           <ul className={about}>
                              
                              <li className={about_item}>
                                 <span className={about_text + " " + about_text_nums}>
                                    CVC:{" " + state?.virtual_card.cvc + " "}
                                    |
                                    Ð›Ð¸Ð¼Ð¸Ñ‚: {" " +
                                       state?.virtual_card.card_limit.toLocaleString(
                                          "ru-RU"
                                       ) +
                                       " â‚½"}
                                 </span>
                              </li>
                              <li className={about_item + " " + about_text_nums}>
                                 <span className={about_text + " " + about_text_nums}>
                                    {
                                       state?.virtual_card.document ? (
                                          <a
                                             href={state?.virtual_card.document}
                                             target="_blank"
                                             className={about_text + " " + about_text_link}
                                             rel="noreferrer noopenner"
                                          >ÐžÑ‚ÐºÑ€Ñ‹Ñ‚ÑŒ Ð´Ð¾Ð³Ð¾Ð²Ð¾Ñ€</a>
                                       ) : (
                                          <span className={about_text + " " + about_text_nums}>
                                             Ð”Ð¾Ð³Ð¾Ð²Ð¾Ñ€ Ð½Ðµ Ð¿Ð¾Ð´Ð¿Ð¸ÑÐ°Ð½
                                          </span>)
                                    }
                                 </span>
                              </li>
                              <li className={about_item + " " + about_text_nums}>
                              </li>
                           </ul>
                        ) : null}

                        <small className={about_subtext}>
                           Ð•ÑÐ»Ð¸ Ñƒ Ð²Ð°Ñ Ð²Ð¾Ð·Ð½Ð¸ÐºÐ»Ð¸ Ð¿Ñ€Ð¾Ð±Ð»ÐµÐ¼Ñ‹ Ñ Ð¿Ñ€ÐµÐ´Ð¾ÑÑ‚Ð°Ð²Ð»ÐµÐ½Ð¸ÐµÐ¼ Ð´Ð¾ÑÑ‚ÑƒÐ¿Ð°{" "}
                           <br /> Ð¸Ð»Ð¸ Ð¾Ñ‚ÐºÐ»ÑŽÑ‡ÐµÐ½Ð¸ÐµÐ¼ Ð²Ð¸Ñ€Ñ‚ÑƒÐ°Ð»ÑŒÐ½Ð¾Ð¹ ÐºÐ°Ñ€Ñ‚Ñ‹ Ð¾Ñ‚ ÑÐ¸ÑÑ‚ÐµÐ¼Ñ‹,
                           Ð¾Ð±Ñ€Ð°Ñ‚Ð¸Ñ‚ÐµÑÑŒ Ð²{" "}
                           <Link to={"/manage/support"}>ÑÐ»ÑƒÐ¶Ð±Ñƒ Ð¿Ð¾Ð´Ð´ÐµÑ€Ð¶ÐºÐ¸</Link>.
                        </small>
                     </div>
                  </AccordionItem>
               </>
            ) : null}
            {row.length > 0 ? (
               <AccordionItem title={"Ð˜ÑÑ‚Ð¾Ñ€Ð¸Ñ Ð·Ð°Ð¹Ð¼Ð¾Ð²"}>
                  <Table
                     rows={row}
                     colums={[
                        {
                           paramName: "number",
                           name: "ÐÐ¾Ð¼ÐµÑ€ Ð´Ð¾Ð³Ð¾Ð²Ð¾Ñ€Ð°",
                        },
                        {
                           paramName: "totaol_amount",
                           name: "Ð¡ÑƒÐ¼Ð¼Ð°",
                        },
                        {
                           paramName: "status",
                           name: "Ð¡Ñ‚Ð°Ñ‚ÑƒÑ",
                        },
                        {
                           paramName: "action",
                           name: "Ð”ÐµÐ¹ÑÑ‚Ð²Ð¸Ñ",
                        },
                     ]}
                     onSort={(param, direction) => setSort([param, direction])}
                     sortParam={sort[0]}
                     sortDirection={sort[1]}
                     baseColumnsWidth={[null, 256, 256, 160]}
                     blockOnEmpty={
                        <EmptyData
                           title="ÐÐµÑ‚ Ð´Ð°Ð½Ð½Ñ‹Ñ…"
                           text="Ð”Ð°Ð½Ð½Ñ‹Ðµ Ð¾Ñ‚ÑÑƒÑ‚Ð²ÑƒÑŽÑ‚. Ð’ÐµÑ€Ð¾ÑÑ‚Ð½Ð¾, Ñ‡Ñ‚Ð¾ Ñƒ ÐºÐ»Ð¸ÐµÐ½Ñ‚Ð° ÐµÑ‰Ðµ Ð½Ðµ Ð±Ñ‹Ð»Ð¾ Ð·Ð°Ð¹Ð¼Ð¾Ð²."
                        />
                     }
                     pagination={pagination}
                     onPaginate={onPaginate}
                  />
               </AccordionItem>
            ) : null}
            <AccordionItem title={"Ð”ÐµÐ¹ÑÑ‚Ð²Ð¸Ñ ÑƒÑ‡Ñ‘Ñ‚Ð½Ð¾Ð¹ Ð·Ð°Ð¿Ð¸ÑÑŒÑŽ"}>
               <div className={acord}>
                  <h4>Ð‘Ð»Ð¾ÐºÐ¸Ñ€Ð¾Ð²ÐºÐ° Ð»Ð¸Ñ‡Ð½Ð¾Ð³Ð¾ ÐºÐ°Ð±Ð¸Ð½ÐµÑ‚Ð° ÐºÐ»Ð¸ÐµÐ½Ñ‚Ð°</h4>
                  <LongNotice
                     buttonText={state?.status ? "Ð—Ð°Ð±Ð»Ð¾ÐºÐ¸Ñ€Ð¾Ð²Ð°Ñ‚ÑŒ" : "Ð Ð°Ð·Ð±Ð»Ð¾ÐºÐ¸Ñ€Ð¾Ð²Ð°Ñ‚ÑŒ"}
                     onClick={() => onOpenBlock()}
                     size={state?.status ? "long_no_icon_yellow" : "long_no_icon"}
                  >
                     {state?.status
                        ? "Ð—Ð°Ð±Ð»Ð¾ÐºÐ¸Ñ€Ð¾Ð²Ð°Ñ‚ÑŒ Ð´Ð¾ÑÑ‚ÑƒÐ¿ Ð² Ð»Ð¸Ñ‡Ð½Ñ‹Ð¹ ÐºÐ°Ð±Ð¸Ð½ÐµÑ‚?"
                        : "Ð Ð°Ð·Ð±Ð»Ð¾ÐºÐ¸Ñ€Ð¾Ð²Ð°Ñ‚ÑŒ Ð´Ð¾ÑÑ‚ÑƒÐ¿ Ð² Ð»Ð¸Ñ‡Ð½Ñ‹Ð¹ ÐºÐ°Ð±Ð¸Ð½ÐµÑ‚? Ð—Ð°Ð±Ð»Ð¾ÐºÐ¸Ñ€Ð¾Ð²Ð°Ð½ Ð¿Ð¾ Ð¿Ñ€Ð¸Ñ‡Ð¸Ð½Ðµ: " +
                          state?.block_reason}
                  </LongNotice>
                  <h4>Ð£Ð´Ð°Ð»ÐµÐ½Ð¸Ðµ ÑƒÑ‡Ñ‘Ñ‚Ð½ÑƒÑŽ Ð·Ð°Ð¿Ð¸ÑÑŒ ÐºÐ»Ð¸ÐµÐ½Ñ‚Ð°</h4>
                  <LongNotice
                     buttonText={"Ð£Ð´Ð°Ð»Ð¸Ñ‚ÑŒ"}
                     onClick={() => onOpenDelete()}
                     size="long"
                  >
                     Ð£Ð´Ð°Ð»ÐµÐ½Ð¸Ðµ ÑƒÑ‡Ñ‘Ñ‚Ð½Ð¾Ð¹ Ð·Ð°Ð¿Ð¸ÑÐ¸ ÐºÐ»Ð¸ÐµÐ½Ñ‚Ð°.
                  </LongNotice>
               </div>
            </AccordionItem>
         </Accordion>
         {isActive ? (
            <PopUp title={"Ð¡Ð½ÑÑ‚ÑŒ Ð¾Ð³Ñ€Ð°Ð½Ð¸Ñ‡ÐµÐ½Ð¸Ñ Ð¿Ð¾ Ð·Ð°ÑÐ²ÐºÐ°Ð¼?"} onCloase={() => onCloas()}>
               <form action="" onSubmit={onSubmit} className={form}>
                  <p className={form__text}>
                     Ð•ÑÐ»Ð¸ Ð²Ñ‹ ÑÐ½Ð¸Ð¼ÐµÑ‚Ðµ Ð¾Ð³Ñ€Ð°Ð½Ð¸Ñ‡ÐµÐ½Ð¸Ðµ Ð½Ð° Ð¾Ñ„Ð¾Ñ€Ð¼Ð»ÐµÐ½Ð¸Ðµ Ð·Ð°ÑÐ²ÐºÐ¸: ÐºÐ»Ð¸ÐµÐ½Ñ‚ ÑÐ¼Ð¾Ð¶ÐµÑ‚
                     Ð¾ÑÑ‚Ð°Ð²Ð¸Ñ‚ÑŒ Ð½Ð¾Ð²ÑƒÑŽ Ð·Ð°ÑÐ²ÐºÑƒ. <br /> Ð’Ñ‹ ÑƒÐ²ÐµÑ€ÐµÐ½Ñ‹, Ñ‡Ñ‚Ð¾ Ñ…Ð¾Ñ‚Ð¸Ñ‚Ðµ ÑÐ½ÑÑ‚ÑŒ
                     Ð¾Ð³Ñ€Ð°Ð½Ð¸Ñ‡ÐµÐ½Ð¸Ðµ?
                  </p>
                  <div className={form__buttons}>
                     <Button variant={"gray"} onClick={() => onCloas()}>
                        ÐžÑ‚Ð¼ÐµÐ½Ð°
                     </Button>
                     <Button isWaiting={wait} type={"submit"}>
                        Ð Ð°Ð·Ð±Ð»Ð¾ÐºÐ¸Ñ€Ð¾Ð²Ð°Ñ‚ÑŒ
                     </Button>
                  </div>
               </form>
            </PopUp>
         ) : null}
         {isBlock ? (
            <PopUp
               title={
                  state?.status
                     ? "Ð—Ð°Ð±Ð»Ð¾ÐºÐ¸Ñ€Ð¾Ð²Ð°Ñ‚ÑŒ Ð»Ð¸Ñ‡Ð½Ñ‹Ð¹ ÐºÐ°Ð±Ð¸Ð½ÐµÑ‚?"
                     : "Ð Ð°Ð·Ð±Ð»Ð¾ÐºÐ¸Ñ€Ð¾Ð²Ð°Ñ‚ÑŒ Ð»Ð¸Ñ‡Ð½Ñ‹Ð¹ ÐºÐ°Ð±Ð¸Ð½ÐµÑ‚?"
               }
               onCloase={() => onCloasBlock()}
            >
               <form
                  action=""
                  onSubmit={state?.status ? onSubmitBlock : onSubmitUnBlock}
                  className={form}
               >
                  {state?.status ? (
                     <>
                        <small>
                           Ð£ÐºÐ°Ð¶Ð¸Ñ‚Ðµ Ð¿Ñ€Ð¸Ñ‡Ð¸Ð½Ñƒ Ð±Ð»Ð¾ÐºÐ¸Ñ€Ð¾Ð²ÐºÐ¸ ÐºÐ»Ð¸ÐµÐ½Ñ‚Ð°. Ð­Ñ‚Ð¾ Ð¿Ð¾Ð¼Ð¾Ð¶ÐµÑ‚ Ð² Ð±ÑƒÐ´ÑƒÑ‰ÐµÐ¼
                           Ð¸Ð·Ð±ÐµÐ¶Ð°Ñ‚ÑŒ Ð¾ÑˆÐ¸Ð±Ð¾Ñ‡Ð½Ð¾Ð¹ Ð±Ð»Ð¾ÐºÐ¸Ñ€Ð¾Ð²ÐºÐ¸.
                        </small>
                        <Input
                           className={textaria}
                           label={"ÐŸÑ€Ð¸Ñ‡Ð¸Ð½Ð° Ð±Ð»Ð¾ÐºÐ¸Ñ€Ð¾Ð²ÐºÐ¸"}
                           type="textarea"
                           value={valueBlock}
                           onChange={(e) => setValueBlock(e[0])}
                           error={valueBlockError}
                        />
                     </>
                  ) : (
                     <p className={form__text}>
                        Ð—Ð°Ð±Ð»Ð¾ÐºÐ¸Ñ€Ð¾Ð²Ð°Ð½ Ð¿Ð¾ Ð¿Ñ€Ð¸Ñ‡Ð¸Ð½Ðµ: <b>{state?.block_reason}</b>
                        <br />
                        <br />
                        Ð’Ñ‹ ÑƒÐ²ÐµÑ€ÐµÐ½Ñ‹, Ñ‡Ñ‚Ð¾ Ñ…Ð¾Ñ‚Ð¸Ñ‚Ðµ Ñ€Ð°Ð·Ð±Ð»Ð¾ÐºÐ¸Ñ€Ð¾Ð²Ð°Ñ‚ÑŒ Ð»Ð¸Ñ‡Ð½Ñ‹Ð¹ ÐºÐ°Ð±Ð¸Ð½ÐµÑ‚ ÐºÐ»Ð¸ÐµÐ½Ñ‚Ð°?
                     </p>
                  )}
                  <div className={form__buttons}>
                     <Button variant={"gray"} onClick={() => onCloasBlock()}>
                        ÐžÑ‚Ð¼ÐµÐ½Ð°
                     </Button>
                     <Button isWaiting={wait} type={"submit"}>
                        {state?.status ? "Ð—Ð°Ð±Ð»Ð¾ÐºÐ¸Ñ€Ð¾Ð²Ð°Ñ‚ÑŒ" : "Ð Ð°Ð·Ð±Ð»Ð¾ÐºÐ¸Ñ€Ð¾Ð²Ð°Ñ‚ÑŒ"}
                     </Button>
                  </div>
               </form>
            </PopUp>
         ) : null}
         {isDelete ? (
            <PopUp title={"Ð£Ð´Ð°Ð»Ð¸Ñ‚ÑŒ ÑƒÑ‡Ñ‘Ñ‚Ð½ÑƒÑŽ Ð·Ð°Ð¿Ð¸ÑÑŒ?"} onCloase={() => onCloasDelete()}>
               <form action="" onSubmit={onSubmitDelete} className={form}>
                  <p className={form__text}>
                     Ð£Ð´Ð°Ð»ÐµÐ½Ð¸Ðµ ÑƒÑ‡Ñ‘Ñ‚Ð½Ð¾Ð¹ Ð·Ð°Ð¿Ð¸ÑÐ¸ Ð½ÐµÐ¾Ð±Ñ€Ð°Ñ‚Ð¸Ð¼Ð¾: Ð¿Ð°ÑÐ¿Ð¾Ñ€Ñ‚Ð½Ñ‹Ðµ Ð´Ð°Ð½Ð½Ñ‹Ðµ, ÑÐºÐ°Ð½Ñ‹
                     Ð´Ð¾ÐºÑƒÐ¼ÐµÐ½Ñ‚Ð¾Ð², Ð¸Ð½Ñ„Ð¾Ñ€Ð¼Ð°Ñ†Ð¸Ñ Ð¾ Ñ‚Ñ€ÑƒÐ´Ð¾Ð²Ð¾Ð¹ Ð´ÐµÑÑ‚ÐµÐ»ÑŒÐ½Ð¾ÑÑ‚Ð¸ Ð¸ Ð¸ÑÑ‚Ð¾Ñ€Ð¸Ñ Ð·Ð°Ð¹Ð¼Ð¾Ð² Ð±ÑƒÐ´ÑƒÑ‚
                     Ð±ÐµÐ·Ð²Ð¾Ð·Ð²Ñ€Ð°Ñ‚Ð½Ð¾ ÑƒÐ´Ð°Ð»ÐµÐ½Ñ‹ Ð¸Ð· ÑÐ¸ÑÑ‚ÐµÐ¼Ñ‹. Ð¢Ð°Ðº Ð¶Ðµ Ð±ÑƒÐ´ÑƒÑ‚ ÑƒÐ´Ð°Ð»Ñ‘Ð½ Ð°ÐºÑ‚Ð¸Ð²Ð½Ñ‹Ð¹ Ð·Ð°Ð¹Ð¼,
                     ÐµÑÐ»Ð¸ Ð¾Ð½ Ð¸Ð¼ÐµÐµÑ‚ÑÑ. <br />
                     <br /> ÐŸÐ¾Ð´Ñ‚Ð²ÐµÑ€Ð´Ð¸Ñ‚Ðµ ÑÐ²Ð¾Ð¸ Ð´ÐµÑÑ‚Ð²Ð¸Ñ, Ð²Ð²ÐµÐ´Ñ Ð¿Ð°Ñ€Ð¾Ð»ÑŒ ÑƒÑ‡Ñ‘Ñ‚Ð½Ð¾Ð¹ Ð·Ð°Ð¿Ð¸ÑÐ¸.
                  </p>
                  <Input
                     label={"ÐŸÐ°Ñ€Ð¾Ð»ÑŒ"}
                     placeholder={"******"}
                     type={"password"}
                     value={password}
                     onChange={setPassword}
                     error={passwordError}
                     disabled={wait}
                  />
                  <div className={form__buttons}>
                     <Button variant={"gray"} onClick={() => onCloasDelete()}>
                        ÐžÑ‚Ð¼ÐµÐ½Ð°
                     </Button>
                     <Button isWaiting={wait} type={"submit"}>
                        Ð£Ð´Ð°Ð»Ð¸Ñ‚ÑŒ
                     </Button>
                  </div>
               </form>
            </PopUp>
         ) : null}
         {isAddCard ? (
            <PopUp
               title={"ÐŸÑ€ÐµÐ´Ð¾ÑÑ‚Ð°Ð²Ð¸Ñ‚ÑŒ Ð²Ð¸Ñ€Ñ‚ÑƒÐ°Ð»ÑŒÐ½ÑƒÑŽ ÐºÐ°Ñ€Ñ‚Ñƒ"}
               onCloase={() => onCloasAddCard()}
            >
               <form onSubmit={onAddCard} className={form}>
                  <small>Ð’Ð½Ð¸Ð¼Ð°Ñ‚ÐµÐ»ÑŒÐ½Ð¾ ÑƒÐºÐ°Ð¶Ð¸Ñ‚Ðµ Ð´Ð°Ð½Ð½Ñ‹Ðµ ÐºÐ°Ñ€Ñ‚Ñ‹ ÐºÐ»Ð¸ÐµÐ½Ñ‚Ð°.</small>
                  <Input
                     label={"ÐÐ¾Ð¼ÐµÑ€ ÐºÐ°Ñ€Ñ‚Ñ‹"}
                     type="number"
                     placeholder={"0000 0000 0000 0000"}
                     mask={"XXXX XXXX XXXX XXXX"}
                     value={cardNumber}
                     onChange={(e) => setCardNumber(e)}
                     error={ErrorCardNumber}
                  />
                  <div className={form__fields}>
                     <Input
                        className={form__buttons_min}
                        label={"CVC ÐºÐ¾Ð´"}
                        type="number"
                        placeholder={"123"}
                        mask={"XXX"}
                        value={cvc}
                        onChange={(e) => setCVC(e)}
                        error={ErrorCvc}
                     />
                     <Input
                        className={form__buttons_min}
                        label={"ÐœÐµÑÑÑ†"}
                        type="number"
                        placeholder={"03"}
                        mask={"XX"}
                        value={cardMonth}
                        onChange={(e) => setCardMonth(e)}
                        error={ErrorCardMonth}
                     />
                     <Input
                        className={form__buttons_min}
                        label={"Ð“Ð¾Ð´"}
                        type="number"
                        placeholder={"26"}
                        mask={"XX"}
                        value={cardYear}
                        onChange={(e) => setCardYear(e)}
                        error={ErrorCardYear}
                     />
                  </div>
                  <Input
                     label={"Ð›Ð¸Ð¼Ð¸Ñ‚ Ð¿Ð¾ ÐºÐ°Ñ€Ñ‚Ðµ"}
                     type="number"
                     placeholder={"120000"}
                     value={cardLimit}
                     onChange={(e) => setCardLimit(e)}
                     error={ErrorCardLimit}
                  />
                  <div className={form__buttons}>
                     <Button variant={"gray"} onClick={() => onCloasAddCard()}>
                        ÐžÑ‚Ð¼ÐµÐ½Ð°
                     </Button>
                     <Button isWaiting={wait} type={"submit"}>
                        ÐŸÑ€ÐµÐ´Ð¾ÑÑ‚Ð°Ð²Ð¸Ñ‚ÑŒ
                     </Button>
                  </div>
               </form>
            </PopUp>
         ) : null}
         {isCancelCard ? (
            <PopUp
               title={"ÐžÑ‚ÐºÐ»ÑŽÑ‡Ð¸Ñ‚ÑŒ ÐºÐ°Ñ€Ñ‚Ñƒ Ð¾Ñ‚ Slon Finance"}
               onCloase={() => onCloasCancelCard()}
            >
               <form onSubmit={onCancelCard} className={form}>
                  <p>
                     ÐžÑ‚ÐºÐ»ÑŽÑ‡ÐµÐ½Ð¸Ðµ ÐºÐ°Ñ€Ñ‚Ñ‹ Ð¿Ñ€Ð¸Ð²ÐµÐ´ÐµÑ‚ Ðº ÐµÑ‘ ÑƒÐ´Ð°Ð»ÐµÐ½Ð¸ÑŽ Ð¸Ð· ÑÐ¸ÑÑ‚ÐµÐ¼Ñ‹, Ð¾Ð´Ð½Ð°ÐºÐ¾, ÐµÑÐ»Ð¸
                     Ð¿Ð¾Ð»ÑŒÐ·Ð¾Ð²Ð°Ñ‚ÐµÐ»ÑŒ Ð´Ð¾Ð±Ð°Ð²Ð¸Ð» ÐºÐ°Ñ€Ñ‚Ñƒ Ð² Apple Pay Ð¸Ð»Ð¸ Google Pay (Ð¸ Ð´Ñ€.
                     Ð¿Ð»Ð°Ñ‚Ñ‘Ð¶Ð½Ñ‹Ðµ ÐºÐ¾ÑˆÐµÐ»ÑŒÐºÐ¸), Ð¾Ð½ ÑÐ¼Ð¾Ð¶ÐµÑ‚ Ð¿Ð¾Ð»ÑŒÐ·Ð¾Ð²Ð°Ñ‚ÑŒÑÑ ÐµÐ¹. <br />
                     <br />
                     ÐžÐ±Ñ€Ð°Ñ‚Ð¸Ñ‚Ðµ Ð²Ð½Ð¸Ð¼Ð°Ð½Ð¸Ðµ, Ñ‡Ñ‚Ð¾ ÐºÐ°Ñ€Ñ‚Ð° Ð±ÑƒÐ´ÐµÑ‚ ÑƒÐ´Ð°Ð»ÐµÐ½Ð° Ð¸Ð· ÑÐ¸ÑÑ‚ÐµÐ¼Ñ‹ Slon Finance.
                     Ð”Ð»Ñ Ð±Ð»Ð¾ÐºÐ¸Ñ€Ð¾Ð²ÐºÐ¸ ÐºÐ°Ñ€Ñ‚Ñ‹, Ð¾Ð±Ñ€Ð°Ñ‚Ð¸Ñ‚ÐµÑÑŒ Ð² Ð±Ð°Ð½Ðº, Ð²Ñ‹Ð¿ÑƒÑÑ‚Ð¸Ð²ÑˆÐ¸Ð¹ ÐºÐ°Ñ€Ñ‚Ñƒ.
                  </p>
                  <div className={form__buttons}>
                     <Button variant={"gray"} onClick={() => onCloasCancelCard()}>
                        ÐžÑ‚Ð¼ÐµÐ½Ð°
                     </Button>
                     <Button isWaiting={wait} type={"submit"}>
                        ÐžÑ‚Ð¼ÐµÐ½Ð¸Ñ‚ÑŒ ÑƒÑÐ»ÑƒÐ³Ñƒ
                     </Button>
                  </div>
               </form>
            </PopUp>
         ) : null}
      </div>
   ) : (
      <div
         style={{
            minHeight: "70vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
         }}
      >
         <ReactLoading type="spin" color="#2D9614" />
      </div>
   );
}

export default Customer;
