import { useEffect, useState } from "react";

import Input from "../../components/Input";
import InputRadio from "../../components/InputRadio";
import DropDownList from "../../components/DropDownList";
import FileLoader from "../../components/FileLoader";

import css from "./styles.module.scss";

const {
    application__stepBlock,
    application__formBlock,
    application__formBlock_text
} = css;

const ApplicationStep2 = ({onChange = () => {}, data = {}, errors = {}, hasSend = false}) => {

    const yesNoArray = ["Ð”Ð°", "ÐÐµÑ‚"];

    const [pasport, setPasport] = useState(data.pasport ? data.pasport : "");
    const [bday, setBday] = useState(data.bday ? data.bday : "");
    
    const [bplace, setBplace] = useState(data.bplace ? data.bplace : "");
    
    const [pasportOrgCode, setpasportOrgCode] = useState(data.pasportOrgCode ? data.pasportOrgCode : "");
    const [pasportDateGet, setpasportDateGet] = useState(data.pasportDateGet ? data.pasportDateGet : "");
    
    const [pasportOrg, setpasportOrg] = useState(data.pasportOrg ? data.pasportOrg : "");

    const [inn, setInn] = useState(data.inn ? data.inn : "");

    const [photo1, setPhoto1] = useState(data.photo1 ? data.photo1 : "");
    const [photo2, setPhoto2] = useState(data.photo2 ? data.photo2 : "");
    const [photo3, setPhoto3] = useState(data.photo3 ? data.photo3 : "");
    const [photo4, setPhoto4] = useState(data.photo4 ? data.photo4 : "");
    
    const [registration, setRegistration] = useState(data.registration ? data.registration : "");
    const [isOnReg, setIsOnReg] = useState(data.isOnReg || data.isOnReg === 0? data.isOnReg : 1);
    const [living, setLiving] = useState(data.living ? data.living : "");
    
    const [familyState, setFamilyState] = useState(data.familyState || data.familyState === 0 ? data.familyState : 0);
    const familyStateArray = [
        "Ð¥Ð¾Ð»Ð¾ÑÑ‚ / Ð½Ðµ Ð·Ð°Ð¼ÑƒÐ¶ÐµÐ¼",
        "Ð“Ñ€Ð°Ð¶Ð´Ð°Ð½ÑÐºÐ¸Ð¹ Ð±Ñ€Ð°Ðº",
        "Ð–ÐµÐ½Ð°Ñ‚ / Ð·Ð°Ð¼ÑƒÐ¶ÐµÐ¼",
        "Ð’ Ñ€Ð°Ð·Ð²Ð¾Ð´Ðµ"
    ];
    
    const [familyCount, setFamilyCount] = useState(data.familyCount || data.familyCount === 0 ? data.familyCount : 0);
    const familyCountArray = [
        "1",
        "2",
        "3",
        "4",
        "5",
        "Ð‘Ð¾Ð»ÐµÐµ 5"
    ];
    
    const [childrenCount, setChildrenCount] = useState(data.childrenCount || data.childrenCount === 0 ? data.childrenCount : 0);
    const childrenCountArray = [
        "ÐÐµÑ‚",
        "1",
        "2",
        "3",
        "4",
        "5",
        "Ð‘Ð¾Ð»ÐµÐµ 5"
    ];

    const [liveWithParents, setLiveWithParents] = useState(data.liveWithParents || data.liveWithParents === 0 ? data.liveWithParents : 1);
    
    useEffect(() => {
        onChange({
            pasport,
            bday,
            bplace,
            pasportOrgCode,
            pasportDateGet,
            pasportOrg,
            inn,
            photo1,
            photo2,
            photo3,
            photo4,
            registration,
            isOnReg,
            living,
            familyState,
            familyCount,
            childrenCount,
            liveWithParents
        });
    }, [
        pasport,
        bday,
        bplace,
        pasportOrgCode,
        pasportDateGet,
        pasportOrg,
        inn,
        photo1,
        photo2,
        photo3,
        photo4,
        registration,
        isOnReg,
        living,
        familyState,
        familyCount,
        childrenCount,
        liveWithParents
    ]);

    return (
        <>
            <div className={application__stepBlock}>
                <Input
                    label={"Ð¡ÐµÑ€Ð¸Ñ Ð¸ Ð½Ð¾Ð¼ÐµÑ€"}
                    placeholder={"0000-000000"}
                    className={application__formBlock}
                    mask={"XXXX-XXXXXX"}
                    type={"number"}
                    value={pasport}
                    onChange={setPasport}
                    error={errors.pasport ? errors.pasport : null}
                    showErrorOnBlur={!hasSend}
                />
                <Input
                    label={"Ð”Ð°Ñ‚Ð° Ñ€Ð¾Ð¶Ð´ÐµÐ½Ð¸Ñ"}
                    placeholder={"01.01.1990"}
                    className={application__formBlock}
                    type={"date"}
                    value={bday}
                    onChange={setBday}
                    error={errors.bday ? errors.bday : null}
                    showErrorOnBlur={!hasSend}
                />
            </div>
            <div className={application__stepBlock}>
                <Input
                    label={"ÐœÐµÑÑ‚Ð¾ Ñ€Ð¾Ð¶Ð´ÐµÐ½Ð¸Ñ"}
                    placeholder={"Ð³. ÐœÐ¾ÑÐºÐ²Ð°"}
                    className={application__formBlock}
                    value={bplace}
                    onChange={setBplace}
                    error={errors.bplace ? errors.bplace : null}
                    showErrorOnBlur={!hasSend}
                />
            </div>
            <div className={application__stepBlock}>
                <Input
                    label={"ÐšÐ¾Ð´ Ð¿Ð¾Ð´Ñ€Ð°Ð·Ð´ÐµÐ»ÐµÐ½Ð¸Ñ"}
                    placeholder={"000-000"}
                    className={application__formBlock}
                    mask={"XXX-XXX"}
                    type={"number"}
                    value={pasportOrgCode}
                    onChange={setpasportOrgCode}
                    error={errors.pasportOrgCode ? errors.pasportOrgCode : null}
                    showErrorOnBlur={!hasSend}
                />
                <Input
                    label={"Ð”Ð°Ñ‚Ð° Ð²Ñ‹Ð´Ð°Ñ‡Ð¸"}
                    placeholder={"01.01.2010"}
                    className={application__formBlock}
                    type={"date"}
                    value={pasportDateGet}
                    onChange={setpasportDateGet}
                    error={errors.pasportDateGet ? errors.pasportDateGet : null}
                    showErrorOnBlur={!hasSend}
                />
            </div>
            <div className={application__stepBlock}>
                <Input
                    label={"ÐšÐµÐ¼ Ð²Ñ‹Ð´Ð°Ð½"}
                    placeholder={"ÐžÑ‚Ð´ÐµÐ»Ð¾Ð¼ Ð£Ð¤ÐœÐ¡ Ð Ð¾ÑÑÐ¸Ð¸ Ð¿Ð¾ Ð³Ð¾Ñ€Ð¾Ð´Ñƒ ÐœÐ¾ÑÐºÐ²Ðµ"}
                    className={application__formBlock}
                    value={pasportOrg}
                    onChange={setpasportOrg}
                    error={errors.pasportOrg ? errors.pasportOrg : null}
                    showErrorOnBlur={!hasSend}
                />
            </div>
            <div className={application__stepBlock}>
                <Input
                    label={"Ð˜ÐÐ"}
                    placeholder={"782040981338"}
                    className={application__formBlock}
                    mask={"XXXXXXXXXXXX"}
                    type={"number"}
                    value={inn}
                    onChange={setInn}
                />
                <p className={application__formBlock + " " + application__formBlock_text}>
                    Ð—Ð°Ð¿Ð¾Ð»Ð½ÐµÐ½Ð½Ð¾Ðµ Ð¿Ð¾Ð»Ðµ Ð˜ÐÐ Ð¿Ð¾Ð²Ñ‹ÑˆÐ°ÐµÑ‚ ÑˆÐ°Ð½ÑÑ‹ Ð¿Ð¾Ð»ÑƒÑ‡ÐµÐ½Ð¸Ñ Ð·Ð°Ð¹Ð¼Ð°
                </p>
            </div>
            <div className={application__stepBlock}>
                <FileLoader
                    extensions=".jpg,.jpeg,.png"
                    value={photo1}
                    onChange={setPhoto1}
                >
                    Ð¤Ð¾Ñ‚Ð¾ Ð¾ÑÐ½Ð¾Ð²Ð½Ð¾Ð³Ð¾ Ñ€Ð°Ð·Ð²Ð¾Ñ€Ð¾Ñ‚Ð° Ð¿Ð°ÑÐ¿Ð¾Ñ€Ñ‚Ð°
                </FileLoader>
                <FileLoader
                    extensions=".jpg,.jpeg,.png"
                    value={photo2}
                    onChange={setPhoto2}
                >
                    Ð¤Ð¾Ñ‚Ð¾ ÑÑ‚Ñ€Ð°Ð½Ð¸Ñ†Ñ‹ Ñ€ÐµÐ³Ð¸ÑÑ‚Ñ€Ð°Ñ†Ð¸Ð¸
                </FileLoader>
                <FileLoader
                    extensions=".jpg,.jpeg,.png"
                    value={photo3}
                    onChange={setPhoto3}
                >
                    Ð¡ÐµÐ»Ñ„Ð¸ Ñ Ð¾ÑÐ½Ð¾Ð²Ð½Ñ‹Ð¼ Ñ€Ð°Ð·Ð²Ð¾Ñ€Ð¾Ñ‚Ð¾Ð¼ Ð¿Ð°ÑÐ¿Ð¾Ñ€Ñ‚Ð°
                </FileLoader>
                <FileLoader
                    extensions=".jpg,.jpeg,.png"
                    value={photo4}
                    onChange={setPhoto4}
                >
                    Ð¤Ð¾Ñ‚Ð¾ Ð¡ÐÐ˜Ð›Ð¡Ð°
                </FileLoader>
            </div>
            <div className={application__stepBlock}>
                <Input
                    label={"ÐÐ´Ñ€ÐµÑ Ñ€ÐµÐ³Ð¸ÑÑ‚Ñ€Ð°Ñ†Ð¸Ð¸"}
                    placeholder={"ÐœÐ¾ÑÐºÐ²Ð°, ÑƒÐ». Ð¢Ð²ÐµÑ€ÑÐºÐ°Ñ, Ð´. 1, ÐºÐ². 1"}
                    className={application__formBlock}
                    value={registration}
                    onChange={setRegistration}
                    error={errors.registration ? errors.registration : null}
                    showErrorOnBlur={!hasSend}
                />
            </div>
            <div className={application__stepBlock}>
                <InputRadio
                    className={""}
                    label="ÐÐ´Ñ€ÐµÑ Ð¿Ñ€Ð¾Ð¶Ð¸Ð²Ð°Ð½Ð¸Ñ ÑÐ¾Ð²Ð¿Ð°Ð´Ð°ÐµÑ‚ Ñ Ð°Ð´Ñ€ÐµÑÐ¾Ð¼ Ñ€ÐµÐ³Ð¸ÑÑ‚Ñ€Ð°Ñ†Ð¸Ð¸"
                    options={yesNoArray}
                    onChange={setIsOnReg}
                    name={"isOnReg"}
                    value={isOnReg}
                />
            </div>
            {
                isOnReg ? (
                    <div className={application__stepBlock}>
                        <Input
                            label={"ÐÐ´Ñ€ÐµÑ Ð¿Ñ€Ð¾Ð¶Ð¸Ð²Ð°Ð½Ð¸Ñ"}
                            placeholder={"ÐœÐ¾ÑÐºÐ²Ð°, ÑƒÐ». Ð¢Ð²ÐµÑ€ÑÐºÐ°Ñ, Ð´. 1, ÐºÐ². 1"}
                            className={application__formBlock}
                            value={living}
                            onChange={setLiving}
                            error={errors.living ? errors.living : null}
                            showErrorOnBlur={!hasSend}
                        />
                    </div>
                ) : ""
            }
            <div className={application__stepBlock}>
                <DropDownList
                    options={familyStateArray}
                    label="Ð¡ÐµÐ¼ÐµÐ¹Ð½Ð¾Ðµ Ð¿Ð¾Ð»Ð¾Ð¶ÐµÐ½Ð¸Ðµ"
                    value={familyState}
                    onChange={setFamilyState}
                />
                <DropDownList
                    options={familyCountArray}
                    label="ÐšÐ¾Ð»Ð¸Ñ‡ÐµÑÑ‚Ð²Ð¾ Ñ‡Ð»ÐµÐ½Ð¾Ð² ÑÐµÐ¼ÑŒÐ¸"
                    value={familyCount}
                    onChange={setFamilyCount}
                />
            </div>
            <div className={application__stepBlock}>
                <DropDownList
                    options={childrenCountArray}
                    label="ÐšÐ¾Ð»Ð¸Ñ‡ÐµÑÑ‚Ð²Ð¾ Ð´ÐµÑ‚ÐµÐ¹ Ð´Ð¾ 18 Ð»ÐµÑ‚"
                    value={childrenCount}
                    onChange={setChildrenCount}
                />
                <InputRadio
                    className={""}
                    label="ÐŸÑ€Ð¾Ð¶Ð¸Ð²Ð°ÑŽ Ñ Ñ€Ð¾Ð´Ð¸Ñ‚ÐµÐ»ÑÐ¼Ð¸"
                    options={yesNoArray}
                    onChange={setLiveWithParents}
                    name={"liveWithPArents"}
                    value={liveWithParents}
                />
            </div>
        </>
    );
}

export default ApplicationStep2;