import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/Button";
import ContentHead from "../../../components/ContentHead";
import EmptyData from "../../../components/EmptyData";
import Input from "../../../components/Input";
import Table from "../../../components/Table";
import checkAuth from "../../../helpers/checkAuth";
import api from "../../../helpers/fetch";
import formatPhone from "../../../helpers/formatPhone";
import getFIO from "../../../helpers/getFIO";
import TextOverflow from "../../../helpers/text-overflow";
import Card from "../../Components/Card";
import Container from "../../Components/Container";
import FulscreenLoader from "../../Components/FulscreenLoader";
import IconCheckmark from "../../Components/Icons/IconCheckmark";
import IconDismiss from "../../Components/Icons/IconDismiss";
import InfoLoanHeader from "../../Components/InfoLoanHeader";
import PopUp from "../../Components/PopUp";
import scss from "./index.module.scss";

const {
   loan,
   loan__list,
   loan__container,
   confirm,
   confirm__bank,
   confirm__tel,
   confirm__button,
   table__action,
   table__button,
   table__button_green,
   table__button_red,
   table__button_disabled,
   wrapper__accept,
   attantion_small,
   buttons,
   field,
} = scss;

function Loan() {
   const getInfo = (status, bank, tel, table, onClick) => {
      switch (status) {
         case "confirmed": {
            return (
               <Container>
                  <div className={loan__container}>
                     <EmptyData
                        redButton
                        buttonText="ÐžÑ‚Ð¾Ð·Ð²Ð°Ñ‚ÑŒ Ñ€ÐµÑˆÐµÐ½Ð¸Ðµ Ð¿Ð¾ Ð·Ð°Ð¹Ð¼Ñƒ"
                        onClick={() => setIsDenied(!isDenied)}
                        showButton
                        text={"ÐšÐ°Ðº Ñ‚Ð¾Ð»ÑŒÐºÐ¾ ÐºÐ»Ð¸ÐµÐ½Ñ‚ Ð¿Ð¾Ð´Ð¿Ð¸ÑˆÐµÑ‚ Ð´Ð¾Ð³Ð¾Ð²Ð¾Ñ€, Ñ‚ÑƒÑ‚ Ð¿Ð¾ÑÐ²ÑÑ‚ÑÑ Ð¸Ð½Ñ„Ð¾Ñ€Ð¼Ð°Ñ†Ð¸Ñ Ð´Ð»Ñ Ð¿ÐµÑ€ÐµÐ²Ð¾Ð´Ð° Ñ‡ÐµÑ€ÐµÐ· Ð¡Ð‘ÐŸ."}
                        title={"Ð”Ð¾Ð³Ð¾Ð²Ð¾Ñ€ Ð¾Ð¶Ð¸Ð´Ð°ÐµÑ‚ Ð¿Ð¾Ð´Ð¿Ð¸ÑÐ°Ð½Ð¸Ñ"}
                     />
                  </div>
               </Container>
            );
         }
         case "unpayed": {
            return (
               <Container>
                  <ContentHead
                     title={"Ð”Ð¾Ð³Ð¾Ð²Ð¾Ñ€ Ð¿Ð¾Ð´Ð¿Ð¸ÑÐ°Ð½!"}
                     subtitle={"ÐžÑÑ‚Ð°Ð»Ð¾ÑÑŒ Ð»Ð¸ÑˆÑŒ Ð¿Ð¾Ð´Ñ‚Ð²ÐµÑ€Ð´Ð¸Ñ‚ÑŒ Ð´ÐµÐ½ÐµÐ¶Ð½Ñ‹Ð¹ Ð¿ÐµÑ€ÐµÐ²Ð¾Ð´ ÐºÐ»Ð¸ÐµÐ½Ñ‚Ñƒ Ñ‡ÐµÑ€ÐµÐ· Ð¡Ð‘ÐŸ."}
                     bottomSubtitle
                  />
                  <span className={confirm}>
                     ÐšÐ»Ð¸ÐµÐ½Ñ‚ Ð¿Ð¾Ð¿Ñ€Ð¾ÑÐ¸Ð» Ð¿ÐµÑ€ÐµÐ²ÐµÑÑ‚Ð¸ Ð´ÐµÐ½ÑŒÐ³Ð¸ Ð² <span className={confirm__bank}>{bank}</span> Ð½Ð° Ð½Ð¾Ð¼ÐµÑ€{" "}
                     <a className={confirm__tel} href={"tel:+7" + tel}>
                        {formatPhone(tel)}
                     </a>
                  </span>
                  <Button className={confirm__button} onClick={() => setIsNewTrans(!isNewTrans)}>
                     ÐŸÐ¾Ð´Ñ‚Ð²ÐµÑ€Ð´Ð¸Ñ‚ÑŒ Ð¿ÐµÑ€ÐµÐ²Ð¾Ð´
                  </Button>
               </Container>
            );
         }
         case "signed": {
            return table ? (
               <Container>
                  <ContentHead title={"Ð¢Ñ€Ð°Ð½Ð·Ð°ÐºÑ†Ð¸Ð¸"} buttonClick={() => onClick()} />
                  <Table
                     rows={table}
                     colums={[
                        {
                           paramName: "number",
                           name: "ÐÐ¾Ð¼ÐµÑ€ Ñ‚Ñ€Ð°Ð½Ð·Ð°ÐºÑ†Ð¸Ð¸",
                        },
                        {
                           paramName: "date_created",
                           name: "Ð”Ð°Ñ‚Ð° Ð¿Ð»Ð°Ñ‚ÐµÐ¶Ð°",
                        },
                        {
                           paramName: "status",
                           name: "Ð¡Ñ‚Ð°Ñ‚ÑƒÑ",
                        },
                        {
                           paramName: "amount",
                           name: "Ð¡ÑƒÐ¼Ð¼Ð°",
                        },
                        {
                           paramName: "action",
                           name: "Ð”ÐµÐ¹ÑÑ‚Ð²Ð¸Ñ",
                        },
                     ]}
                     onSort={(param, direction) => setSort([param, direction])}
                     sortParam={sort[0]}
                     sortDirection={sort[1]}
                     baseColumnsWidth={[null, 260, 260, 200, 200, 152]}
                     blockOnEmpty={
                        <EmptyData title="ÐÐµÑ‚ Ð´Ð°Ð½Ð½Ñ‹Ñ…" text="Ð’ÐµÑ€Ð¾ÑÑ‚Ð½Ð¾, ÐºÐ»Ð¸ÐµÐ½Ñ‚ ÐµÑ‰Ðµ Ð½Ðµ Ð¾Ð¿Ð»Ð°Ñ‚Ð¸Ð» Ð½Ð¸ Ð¾Ð´Ð½Ð¾Ð³Ð¾ Ð¿Ð»Ð°Ñ‚ÐµÐ¶Ð°." />
                     }
                     pagination={pagination}
                     onPaginate={onPaginate}
                  />
               </Container>
            ) : (
               <FulscreenLoader />
            );
         }
         default: {
            break;
         }
      }
   };

   const params = useParams();
   const navigate = useNavigate();

   const [amount, setAmount] = useState("");
   const [datePay, setDatePay] = useState("");
   const [amountError, setAmountError] = useState("");
   const [datePayError, setDatePayError] = useState("");
   const [wait, setWait] = useState(false);
   const [isAcive, setIsActive] = useState(false);
   const [isNewTrans, setIsNewTrans] = useState(false);
   const [isDenied, setIsDenied] = useState(false);
   const [error, setError] = useState(false);
   const [errorForm, setErrorForm] = useState(false);
   const [sort, setSort] = useState([]);
   const [errorDenied, setErrorDenied] = useState(false);
   const [isDeniedTransaction, setIsDeniedTransaction] = useState(false);
   const [errorDeniedTransaction, setErrorDeniedTransaction] = useState(false);
   const [isAcceptTransaction, setIsAcceptTransaction] = useState(false);
   const [errorAcceptTransaction, setErrorAcceptTransaction] = useState(false);
   const [currentTransaction, setCurrentTransaction] = useState("");
   const [pagination, setPagination] = useState({});
   const [paginationPage, setPaginationPage] = useState(1);

   const [state, setState] = useState({
      status: "",
      userId: 0,
      number: 0,
      name: "",
      surname: "",
      midname: "",
      total_amount: 0,
      rate: 0,
      bank: "",
      phone_number: 0,
   });

   const [table, setTable] = useState([]);

   const [documents, setDocuments] = useState([]);

   const onDenied = async (e) => {
      e.preventDefault();
      setWait(true);
      const auth = checkAuth();
      const response = await new api(`manage/loans/${state?.id_loan}/decline`, auth, { method: "PUT" }).send();
      if (response.status === "success") {
         setWait(false);
         onHeandlerOpenerThree();
         navigate("/manage/loans");
      } else {
         setErrorDenied(true);
         setWait(false);
      }
      setWait(false);
   };

   const sendForm = async (e) => {
      e.preventDefault();
      setAmountError("");
      setDatePayError("");
      setWait(true);
      if (amount && parseInt(amount) > 0) {
         if (datePay) {
            const auth = checkAuth();
            const body = new FormData();
            body.append("id_loan", state.id_loan);
            body.append("amount", Math.floor(parseInt(amount) * 100));
            body.append("date", new Date(datePay)?.getTime());
            const response = await new api(`manage/transactions`, auth, { method: "POST", body }).send();
            if (response.status === "success") {
               onHeandlerOpener();
               setAmount("");
               setDatePay("");
               getData();
               getTrans();
            } else {
               setErrorForm(!errorForm);
            }
         } else {
            setDatePayError("ÐŸÐ¾Ð»Ðµ Ð´Ð¾Ð»Ð¶Ð½Ð¾ Ð±Ñ‹Ñ‚ÑŒ Ð·Ð°Ð¿Ð¾Ð»Ð½ÐµÐ½Ð¾");
         }
      } else {
         setAmountError("ÐŸÐ¾Ð»Ðµ Ð´Ð¾Ð»Ð¶Ð½Ð¾ Ð±Ñ‹Ñ‚ÑŒ Ð·Ð°Ð¿Ð¾Ð»Ð½ÐµÐ½Ð¾");
      }
      setWait(false);
      setAmount("");
      setDatePay("");
   };

   const getData = async () => {
      const auth = checkAuth();
      const response = await new api(`manage/loans/${params.id}`, auth).send();
      if (response.status === "success") {
         setState(response.data.loan);
         setDocuments(response.data.documents);
      } else {
         navigate("/manage/loans");
      }
   };

   const getTrans = async () => {
      const auth = checkAuth();
      let queryParams = getSortQuery("status");
      const response = await new api(
         `manage/transactions?id_loan=${params.id}${queryParams}` +
            "&page=" +
            (paginationPage !== 0 && paginationPage ? paginationPage : 1),
         auth
      ).send();
      if (response.status === "success") {
         setTable(toTable(response?.data.data));
         setPagination(response?.data.pagination);
      }
   };

   const getSortQuery = (nameField) => {
      if (sort.length === 0) {
         setSort([nameField, "asc"]);
      } else {
         return "&sort_param=" + sort[0] + "&sort_direction=" + sort[1];
      }
      return "";
   };

   useEffect(() => {
      getData();
   }, []);

   useEffect(() => {
      getTrans();
   }, [sort, paginationPage]);

   const onPaginate = (number) => {
      setPaginationPage(number);
   };

   const toTable = (rows = []) => {
      const tmpArray = [];
      rows.forEach((el, key) => {
         tmpArray.push([
            {
               type: "text",
               value: el.id_loan_transaction,
            },
            {
               type: "date",
               value: el.date_created,
            },
            {
               type: el.status === "confirmed" ? "success" : "text",
               value: statusesTransaction[el.status],
            },
            {
               type: "money",
               value: el.amount,
            },
            {
               type: "component",
               value: (
                  <span className={table__action}>
                     {el.status === "processing" ? (
                        <>
                           <button
                              className={table__button + " " + table__button_green}
                              onClick={() => setCurrentAccept(el.id_loan_transaction)}
                           >
                              <IconCheckmark />
                           </button>
                           <button
                              className={table__button + " " + table__button_red}
                              onClick={() => setCurrentDenied(el.id_loan_transaction)}
                           >
                              <IconDismiss />
                           </button>
                        </>
                     ) : (
                        <>
                           <button className={table__button + " " + table__button_disabled} disabled>
                              <IconCheckmark />
                           </button>
                           <button className={table__button + " " + table__button_disabled} disabled>
                              <IconDismiss />
                           </button>
                        </>
                     )}
                  </span>
               ),
            },
         ]);
      });
      return tmpArray;
   };

   const statusesTransaction = {
      confirmed: "ÐžÐ´Ð¾Ð±Ñ€ÐµÐ½Ð°",
      processing: "ÐžÐ±Ñ€Ð°Ð±Ð°Ñ‚Ñ‹Ð²Ð°ÐµÑ‚ÑÑ",
      denied: "ÐžÑ‚ÐºÐ»Ð¾Ð½Ñ‘Ð½Ð°",
   };

   const onConfirm = async (e) => {
      e.preventDefault();
      const auth = checkAuth();
      const response = await new api(`manage/loans/${params.id}/payout`, auth, { method: "PUT" }).send();
      if (response.status === "success") {
         onHeandlerOpenerSecond();
         getData();
      } else {
         setError(!error);
      }
   };

   const setCurrentAccept = (id) => {
      setIsAcceptTransaction(true);
      setCurrentTransaction(id);
   };

   const setCurrentDenied = (id) => {
      setIsDeniedTransaction(true);
      setCurrentTransaction(id);
   };

   const onDeniedTransaction = async (e) => {
      e.preventDefault();
      const auth = checkAuth();
      setWait(true);
      const body = JSON.stringify({ status: "denied" });
      const response = await new api(`manage/transactions/${currentTransaction}/update`, auth, {
         method: "PUT",
         body,
      }).send();
      if (response.status === "success") {
         onHeandlerOpenerFore();
         getTrans();
      }
      setWait(false);
      setCurrentTransaction("");
   };

   const onAcceptTransaction = async (e) => {
      e.preventDefault();
      const auth = checkAuth();
      setWait(true);
      const body = JSON.stringify({ status: "confirmed" });
      const response = await new api(`manage/transactions/${currentTransaction}/update`, auth, {
         method: "PUT",
         body,
      }).send();
      if (response.status === "success") {
         onHeandlerOpenerFive();
         getTrans();
      }
      setWait(false);
      setCurrentTransaction("");
   };

   const onHeandlerOpener = () => {
      document.body.style.overflowY = null;
      setIsActive(!isAcive);
   };
   const onHeandlerOpenerSecond = () => {
      document.body.style.overflowY = null;
      setIsNewTrans(!isNewTrans);
   };
   const onHeandlerOpenerThree = () => {
      document.body.style.overflowY = null;
      setIsDenied(!isDenied);
   };
   const onHeandlerOpenerFore = () => {
      document.body.style.overflowY = null;
      setIsDeniedTransaction(!isDeniedTransaction);
   };
   const onHeandlerOpenerFive = () => {
      document.body.style.overflowY = null;
      setIsAcceptTransaction(!isAcceptTransaction);
   };

   return (
      <>
         <div className={loan}>
            <InfoLoanHeader
               params={state.number}
               rate={state.rate}
               amount={state.total_amount / 100}
               initials={getFIO(state.name, TextOverflow(state.surname, 11), state.midname, "ser-initials")}
               id={state.id_client}
            />
            <Container>
               <h2>Ð”Ð¾ÐºÑƒÐ¼ÐµÐ½Ñ‚Ñ‹</h2>
               <div className={loan__list}>
                  {documents.map(({ name, url }, key) => (
                     <Card type={"document"} title={TextOverflow(name, 55)} link={url} key={key.toString()} />
                  ))}
               </div>
            </Container>
            {getInfo(state.status, state.bank, state.phone_number, table, () => setIsActive(!isAcive))}
         </div>
         {isAcive ? (
            <PopUp title={"ÐÐ¾Ð²Ð°Ñ Ñ‚Ñ€Ð°Ð½Ð·Ð°ÐºÑ†Ð¸Ñ"} onCloase={() => onHeandlerOpener()}>
               <form action="" onSubmit={sendForm} className={wrapper__accept}>
                  {!errorForm ? (
                     <>
                        <Input
                           label={"Ð¡ÑƒÐ¼Ð¼Ð° Ð¿Ð»Ð°Ñ‚ÐµÐ¶Ð°"}
                           placeholder={"10000"}
                           className={field}
                           type={"number"}
                           value={amount}
                           onChange={setAmount}
                           error={amountError}
                        />
                        <Input
                           label={"Ð”Ð°Ñ‚Ð° Ð¿Ð»Ð°Ñ‚ÐµÐ¶Ð°"}
                           placeholder={"01.01.2001"}
                           className={field}
                           type={"date"}
                           value={datePay}
                           onChange={setDatePay}
                           error={datePayError}
                        />
                        <p className={attantion_small}>
                           Ð’Ð½Ð¸Ð¼Ð°Ñ‚ÐµÐ»ÑŒÐ½Ð¾ Ð¿Ñ€Ð¾Ð²ÐµÑ€ÑŒÑ‚Ðµ Ð´Ð°Ð½Ð½Ñ‹Ðµ, ÐºÐ¾Ñ‚Ð¾Ñ€Ñ‹Ðµ Ð²Ñ‹ Ð²Ð²ÐµÐ»Ð¸ Ð² Ð¿Ð¾Ð»Ñ Ð²Ñ‹ÑˆÐµ. Ð•ÑÐ»Ð¸ Ð²Ñ‹ Ð´Ð¾Ð¿ÑƒÑÑ‚Ð¸Ñ‚Ðµ Ð¾ÑˆÐ¸Ð±ÐºÑƒ,
                           Ð¸Ð·Ð¼ÐµÐ½Ð¸Ñ‚ÑŒ Ð´Ð°Ð½Ð½Ñ‹Ðµ Ð±ÑƒÐ´ÐµÑ‚ Ð½Ðµ Ð²Ð¾Ð·Ð¼Ð¾Ð¶Ð½Ð¾.
                        </p>
                        <p className={attantion_small}>
                           ÐŸÐ¾Ð´Ñ‚Ð²ÐµÑ€Ð¶Ð´Ð°Ñ Ð´Ð°Ð½Ð½ÑƒÑŽ Ñ„Ð¾Ñ€Ð¼Ñƒ, Ð²Ñ‹ ÑÐ¾Ð³Ð»Ð°ÑˆÐ°ÐµÑ‚ÐµÑÑŒ Ñ Ð¼Ð°Ñ‚ÐµÑ€Ð¸Ð°Ð»ÑŒÐ½Ð¾Ð¹ Ð¾Ñ‚Ð²ÐµÑ‚ÑÑ‚Ð²ÐµÐ½Ð½Ð¾ÑÑ‚ÑŒÑŽ.
                        </p>
                     </>
                  ) : (
                     <>
                        <p className={attantion_small}>Ð§Ñ‚Ð¾-Ñ‚Ð¾ Ð¿Ð¾ÑˆÐ»Ð¾ Ð½Ðµ Ð¿Ð¾ Ð¿Ð»Ð°Ð½Ñƒ!</p>
                        <p className={attantion_small}>ÐŸÑ€Ð¾Ð±ÑƒÐ¹Ñ‚Ðµ Ð½ÐµÐ¼Ð½Ð¾Ð³Ð¾ Ð¿Ð¾Ð·Ð¶Ðµ Ð¸Ð»Ð¸ Ð¾Ð±Ñ€Ð°Ñ‚Ð¸Ñ‚ÐµÑÑŒ Ð² ÑÐ»ÑƒÐ¶Ð±Ñƒ Ð¿Ð¾Ð´Ð´ÐµÑ€Ð¶ÐºÐ¸.</p>
                     </>
                  )}
                  <div className={buttons}>
                     <Button variant={"gray"} onClick={() => onHeandlerOpener()}>
                        ÐžÑ‚Ð¼ÐµÐ½Ð°
                     </Button>
                     {!errorForm ? (
                        <Button isWaiting={wait} type={"submit"}>
                           Ð—Ð°Ñ€ÐµÐ³Ð¸ÑÑ‚Ñ€Ð¸Ñ€Ð¾Ð²Ð°Ñ‚ÑŒ
                        </Button>
                     ) : (
                        <Button onClick={() => window.location.reload(false)}>ÐžÐ±Ð½Ð¾Ð²Ð¸Ñ‚ÑŒ ÑÑ‚Ñ€Ð°Ð½Ð¸Ñ†Ñƒ</Button>
                     )}
                  </div>
               </form>
            </PopUp>
         ) : null}
         {isNewTrans ? (
            <PopUp title={"Ð¢Ñ€ÐµÐ±ÑƒÐµÑ‚ÑÑ Ð¿Ð¾Ð´Ñ‚Ð²ÐµÑ€Ð¶Ð´ÐµÐ½Ð¸Ðµ"} onCloase={() => onHeandlerOpenerSecond()}>
               <form action="" onSubmit={onConfirm} className={wrapper__accept}>
                  {error ? (
                     <>
                        <p className={attantion_small}>Ð§Ñ‚Ð¾-Ñ‚Ð¾ Ð¿Ð¾ÑˆÐ»Ð¾ Ð½Ðµ Ð¿Ð¾ Ð¿Ð»Ð°Ð½Ñƒ!</p>
                        <p className={attantion_small}>ÐŸÑ€Ð¾Ð±ÑƒÐ¹Ñ‚Ðµ Ð½ÐµÐ¼Ð½Ð¾Ð³Ð¾ Ð¿Ð¾Ð·Ð¶Ðµ Ð¸Ð»Ð¸ Ð¾Ð±Ñ€Ð°Ñ‚Ð¸Ñ‚ÐµÑÑŒ Ð² ÑÐ»ÑƒÐ¶Ð±Ñƒ Ð¿Ð¾Ð´Ð´ÐµÑ€Ð¶ÐºÐ¸.</p>
                     </>
                  ) : (
                     <>
                        <p className={attantion_small}>
                           Ð’Ñ‹ ÑƒÐ²ÐµÑ€ÐµÐ½Ñ‹ Ñ‡Ñ‚Ð¾ Ñ…Ð¾Ñ‚Ð¸Ñ‚Ðµ Ð¿Ð¾Ð´Ñ‚Ð²ÐµÑ€Ð´Ð¸Ñ‚ÑŒ Ð¿ÐµÑ€ÐµÐ²Ð¾Ð´ Ð´ÐµÐ½ÐµÐ¶Ð½Ñ‹Ñ… ÑÑ€ÐµÐ´ÑÑ‚Ð² ÐºÐ»Ð¸ÐµÐ½Ñ‚Ñƒ?
                        </p>
                        <p className={attantion_small}>
                           ÐžÐ¿ÐµÑ€Ð°Ñ†Ð¸ÑŽ Ð±ÑƒÐ´ÐµÑ‚ Ð½ÐµÐ»ÑŒÐ·Ñ Ð¾Ñ‚Ð¼ÐµÐ½Ð¸Ñ‚ÑŒ Ð¸Ð»Ð¸ Ð¸Ð·Ð¼ÐµÐ½Ð¸Ñ‚ÑŒ. Ð‘ÑƒÐ´ÑŒÑ‚Ðµ Ð²Ð½Ð¸Ð¼Ð°Ñ‚ÐµÐ»ÑŒÐ½Ñ‹!
                        </p>
                     </>
                  )}
                  <div className={buttons}>
                     <Button variant={"gray"} onClick={() => onHeandlerOpenerSecond()}>
                        ÐžÑ‚Ð¼ÐµÐ½Ð°
                     </Button>
                     {!error ? (
                        <Button type={"submit"}>ÐŸÐ¾Ð´Ñ‚Ð²ÐµÑ€Ð´Ð¸Ñ‚ÑŒ</Button>
                     ) : (
                        <Button onClick={() => window.location.reload(false)}>ÐžÐ±Ð½Ð¾Ð²Ð¸Ñ‚ÑŒ ÑÑ‚Ñ€Ð°Ð½Ð¸Ñ†Ñƒ</Button>
                     )}
                  </div>
               </form>
            </PopUp>
         ) : null}
         {isDenied ? (
            <PopUp title={"Ð¢Ñ€ÐµÐ±ÑƒÐµÑ‚ÑÑ Ð¿Ð¾Ð´Ñ‚Ð²ÐµÑ€Ð¶Ð´ÐµÐ½Ð¸Ðµ"} onCloase={() => onHeandlerOpenerThree()}>
               <form action="" onSubmit={onDenied} className={wrapper__accept}>
                  {!errorDenied ? (
                     <p className={attantion_small}>
                        Ð•ÑÐ»Ð¸ Ð²Ñ‹ Ð¾Ñ‚Ð·Ð¾Ð²ÐµÑ‚Ðµ Ñ€ÐµÑˆÐµÐ½Ð¸Ðµ, Ñ‚Ð¾ Ð¾Ð±Ñ€Ð°Ñ‚Ð¸Ñ‚ÑŒ Ð´ÐµÐ¹ÑÑ‚Ð²Ð¸Ðµ Ð±ÑƒÐ´ÐµÑ‚ Ð½ÐµÐ²Ð¾Ð·Ð¼Ð¾Ð¶Ð½Ð¾! Ð’ ÑÐ»ÑƒÑ‡Ð°Ðµ, ÐµÑÐ»Ð¸ Ð²Ñ‹ ÑÑ‚Ð¾ ÑÐ´ÐµÐ»Ð°Ð»Ð¸
                        ÑÐ»ÑƒÑ‡Ð°Ð½Ð¾, ÑÐ²ÑÐ¶Ð¸Ñ‚ÐµÑÑŒ Ñ ÐºÐ»Ð¸ÐµÐ½Ñ‚Ð¾Ð¼ Ð¸ Ð¿Ð¾Ð¿Ñ€Ð¾ÑÐ¸Ñ‚Ðµ ÐµÑ‰Ðµ Ñ€Ð°Ð· Ð¾Ñ„Ð¾Ñ€Ð¼Ð¸Ñ‚ÑŒ Ð·Ð°ÑÐ²ÐºÑƒ.
                     </p>
                  ) : (
                     <>
                        <p className={attantion_small}>Ð§Ñ‚Ð¾-Ñ‚Ð¾ Ð¿Ð¾ÑˆÐ»Ð¾ Ð½Ðµ Ð¿Ð¾ Ð¿Ð»Ð°Ð½Ñƒ!</p>
                        <p className={attantion_small}>ÐŸÑ€Ð¾Ð±ÑƒÐ¹Ñ‚Ðµ Ð¿Ð¾Ð·Ð¶Ðµ Ð¸Ð»Ð¸ Ð¾Ð±Ñ€Ð°Ñ‚Ð¸Ñ‚ÐµÑÑŒ Ð² ÑÐ»ÑƒÐ¶Ð±Ñƒ Ð¿Ð¾Ð´Ð´ÐµÑ€Ð¶ÐºÐ¸.</p>
                     </>
                  )}
                  <div className={buttons}>
                     <Button variant={"gray"} onClick={() => onHeandlerOpenerThree()}>
                        ÐžÑ‚Ð¼ÐµÐ½Ð°
                     </Button>
                     {!errorDenied ? (
                        <Button isWaiting={wait} type={"submit"}>
                           ÐžÑ‚Ð¾Ð·Ð²Ð°Ñ‚ÑŒ Ñ€ÐµÑˆÐµÐ½Ð¸Ðµ
                        </Button>
                     ) : (
                        <Button onClick={() => window.location.reload(false)}>ÐžÐ±Ð½Ð¾Ð²Ð¸Ñ‚ÑŒ ÑÑ‚Ñ€Ð°Ð½Ð¸Ñ†Ñƒ</Button>
                     )}
                  </div>
               </form>
            </PopUp>
         ) : null}
         {isDeniedTransaction ? (
            <PopUp title={"Ð¢Ñ€ÐµÐ±ÑƒÐµÑ‚ÑÑ Ð¿Ð¾Ð´Ñ‚Ð²ÐµÑ€Ð¶Ð´ÐµÐ½Ð¸Ðµ"} onCloase={() => onHeandlerOpenerFore()}>
               <form action="" onSubmit={onDeniedTransaction} className={wrapper__accept}>
                  {!errorDeniedTransaction ? (
                     <p className={attantion_small}>
                        ÐžÑ‚ÐºÐ»Ð¾Ð½Ð¸Ð² Ñ‚Ñ€Ð°Ð½Ð·Ð°ÐºÑ†Ð¸ÑŽ, Ð²Ñ‹ Ð½Ðµ ÑÐ¼Ð¾Ð¶ÐµÑ‚Ðµ Ð±Ð¾Ð»ÐµÐµ ÐµÐµ Ð¿Ð¾Ð´Ñ‚Ð²ÐµÑ€Ð´Ð¸Ñ‚ÑŒ. Ð’Ð½Ð¸Ð¼Ð°Ñ‚ÐµÐ»ÑŒÐ½Ð¾ Ð¿Ñ€Ð¾Ð²Ñ€ÑŒÑ‚Ðµ Ð´Ð°Ð½Ð½Ñ‹Ðµ, Ð¿Ñ€ÐµÑ€ÐµÐ´
                        Ð¾Ñ‚ÐºÐ»Ð¾Ð½ÐµÐ½Ð¸ÐµÐ¼ Ñ‚Ñ€Ð°Ð½Ð·Ð°ÐºÑ†Ð¸Ð¸.
                     </p>
                  ) : (
                     <>
                        <p className={attantion_small}>Ð§Ñ‚Ð¾-Ñ‚Ð¾ Ð¿Ð¾ÑˆÐ»Ð¾ Ð½Ðµ Ð¿Ð¾ Ð¿Ð»Ð°Ð½Ñƒ!</p>
                        <p className={attantion_small}>ÐŸÑ€Ð¾Ð±ÑƒÐ¹Ñ‚Ðµ Ð¿Ð¾Ð·Ð¶Ðµ Ð¸Ð»Ð¸ Ð¾Ð±Ñ€Ð°Ñ‚Ð¸Ñ‚ÐµÑÑŒ Ð² ÑÐ»ÑƒÐ¶Ð±Ñƒ Ð¿Ð¾Ð´Ð´ÐµÑ€Ð¶ÐºÐ¸.</p>
                     </>
                  )}
                  <div className={buttons}>
                     <Button variant={"gray"} onClick={() => onHeandlerOpenerFore()}>
                        ÐžÑ‚Ð¼ÐµÐ½Ð°
                     </Button>
                     {!errorDeniedTransaction ? (
                        <Button isWaiting={wait} type={"submit"}>
                           ÐžÑ‚Ð¾Ð·Ð²Ð°Ñ‚ÑŒ Ñ€ÐµÑˆÐµÐ½Ð¸Ðµ
                        </Button>
                     ) : (
                        <Button onClick={() => window.location.reload(false)}>ÐžÐ±Ð½Ð¾Ð²Ð¸Ñ‚ÑŒ ÑÑ‚Ñ€Ð°Ð½Ð¸Ñ†Ñƒ</Button>
                     )}
                  </div>
               </form>
            </PopUp>
         ) : null}
         {isAcceptTransaction ? (
            <PopUp title={"Ð¢Ñ€ÐµÐ±ÑƒÐµÑ‚ÑÑ Ð¿Ð¾Ð´Ñ‚Ð²ÐµÑ€Ð¶Ð´ÐµÐ½Ð¸Ðµ"} onCloase={() => onHeandlerOpenerFive()}>
               <form action="" onSubmit={onAcceptTransaction} className={wrapper__accept}>
                  {!errorAcceptTransaction ? (
                     <p className={attantion_small}>
                        ÐžÐ´Ð¾Ð±Ñ€Ð¸Ð² Ñ‚Ñ€Ð°Ð½Ð·Ð°ÐºÑ†Ð¸ÑŽ, Ð²Ñ‹ Ð½Ðµ ÑÐ¼Ð¾Ð¶ÐµÑ‚Ðµ Ð±Ð¾Ð»ÐµÐµ ÐµÐµ Ð¸Ð·Ð¼ÐµÐ½ÑÑ‚ÑŒ. Ð’Ð½Ð¸Ð¼Ð°Ñ‚ÐµÐ»ÑŒÐ½Ð¾ Ð¿Ñ€Ð¾Ð²Ñ€ÑŒÑ‚Ðµ Ð´Ð°Ð½Ð½Ñ‹Ðµ, Ð¿Ñ€ÐµÑ€ÐµÐ´
                        Ð¿Ð¾Ð´Ñ‚Ð²ÐµÑ€Ð¶Ð´ÐµÐ½Ð¸ÐµÐ¼ Ñ‚Ñ€Ð°Ð½Ð·Ð°ÐºÑ†Ð¸Ð¸.
                     </p>
                  ) : (
                     <>
                        <p className={attantion_small}>Ð§Ñ‚Ð¾-Ñ‚Ð¾ Ð¿Ð¾ÑˆÐ»Ð¾ Ð½Ðµ Ð¿Ð¾ Ð¿Ð»Ð°Ð½Ñƒ!</p>
                        <p className={attantion_small}>ÐŸÑ€Ð¾Ð±ÑƒÐ¹Ñ‚Ðµ Ð¿Ð¾Ð·Ð¶Ðµ Ð¸Ð»Ð¸ Ð¾Ð±Ñ€Ð°Ñ‚Ð¸Ñ‚ÐµÑÑŒ Ð² ÑÐ»ÑƒÐ¶Ð±Ñƒ Ð¿Ð¾Ð´Ð´ÐµÑ€Ð¶ÐºÐ¸.</p>
                     </>
                  )}
                  <div className={buttons}>
                     <Button variant={"gray"} onClick={() => onHeandlerOpenerFive()}>
                        ÐžÑ‚Ð¼ÐµÐ½Ð°
                     </Button>
                     {!errorDenied ? (
                        <Button isWaiting={wait} type={"submit"}>
                           ÐžÐ´Ð¾Ð±Ñ€Ð¸Ñ‚ÑŒ Ñ‚Ñ€Ð°Ð½Ð·Ð°ÐºÑ†Ð¸ÑŽ
                        </Button>
                     ) : (
                        <Button onClick={() => window.location.reload(false)}>ÐžÐ±Ð½Ð¾Ð²Ð¸Ñ‚ÑŒ ÑÑ‚Ñ€Ð°Ð½Ð¸Ñ†Ñƒ</Button>
                     )}
                  </div>
               </form>
            </PopUp>
         ) : null}
      </>
   );
}

export default Loan;
