import { useState } from "react";
import { Link } from "react-router-dom";
import Button from "../Button";
import InputRange from "../InputRange";
import Picture from "../Picture";

import css from "./styles.module.scss";

const {
   calculator,
   calculator__image,
   calculator__imageFade,
   calculator__content,
   calculator__header,
   calculator__slogan,
   calculator__form,
   calculator__formBlock,
   calculator__formLabel,
   calculator__formValue,
   calculator__formInput,
   calculator__footer,
   calculator__footerInfo,
   calculator__footerInfoRow,
   calculator__footerInfoText,
   calculator__footerInfoValue,
   calculator__submitButton,
   calculator__documentsLink,
} = css;

const initialState = window.initialState
   ? window.initialState
   : {
        settings: {
           calculator: {
              min_amount: 10000,
              max_amount: 30000,
              min_interest: 0,
              max_interest: 1,
              min_duration: 7,
              max_duration: 30,
           },
        },
     };

const Calculator = () => {
   const amount = [];
   const days = [];

   for (
      let i = initialState.settings.calculator.min_amount;
      i <= initialState.settings.calculator.max_amount;
      i += 1000
   ) {
      amount.push(i);
   }
   for (
      let i = initialState.settings.calculator.min_duration;
      i <= initialState.settings.calculator.max_duration;
      i++
   ) {
      days.push(i);
   }

   const [selectedAmount, selectAmount] = useState(3);
   const [selectedDay, selectDay] = useState(3);

   const lastDate = new Date(new Date().getTime() + days[selectedDay] * 24 * 60 * 60 * 1000);

   return (
      <section className={calculator}>
         <Picture fileName="grass" alt="" className={calculator__image} />
         <div className={calculator__imageFade} />

         <div className={calculator__content} style={{ zIndex: 1, position: "relative" }}>
            <h2 className={calculator__header}>ÐŸÐ¾Ð»ÑƒÑ‡Ð¸ Ð¾Ð½Ð»Ð°Ð¹Ð½â€‘Ð·Ð°Ñ‘Ð¼</h2>
            <p className={calculator__slogan}>ÐœÐ¾Ð¼ÐµÐ½Ñ‚Ð°Ð»ÑŒÐ½Ð¾. ÐšÑ€ÑƒÐ³Ð»Ð¾ÑÑƒÑ‚Ð¾Ñ‡Ð½Ð¾. Ð”Ð¾ÑÑ‚ÑƒÐ¿Ð½Ð¾.</p>

            <div className={calculator__form}>
               <div className={calculator__formBlock}>
                  <p className={calculator__formLabel}>ÐšÐ°ÐºÐ°Ñ ÑÑƒÐ¼Ð¼Ð° Ð²Ð°Ð¼ Ñ‚Ñ€ÐµÐ±ÑƒÐµÑ‚ÑÑ?</p>
                  <p className={calculator__formValue}>
                     â‚½ {(amount[selectedAmount] ? amount[selectedAmount] : 0).toLocaleString()}
                  </p>
                  <InputRange
                     aria-label="Ð¡ÑƒÐ¼Ð¼Ð° Ð·Ð°Ð¹Ð¼Ð°"
                     className={calculator__formInput}
                     variant="white"
                     value={selectedAmount}
                     length={amount.length - 1}
                     onChange={(e) => selectAmount(e)}
                     step={1}
                  />
               </div>
               <div className={calculator__formBlock}>
                  <p className={calculator__formLabel}>ÐÐ° ÐºÐ°ÐºÐ¾Ð¹ ÑÑ€Ð¾Ðº?</p>
                  <p className={calculator__formValue}>{days[selectedDay]} Ð´Ð½ÐµÐ¹</p>
                  <InputRange
                     aria-label="Ð¡Ñ€Ð¾Ðº Ð·Ð°Ð¹Ð¼Ð°"
                     className={calculator__formInput}
                     variant="white"
                     value={selectedDay}
                     length={days.length - 1}
                     onChange={(e) => selectDay(e)}
                     step={1}
                  />
               </div>
            </div>

            <div className={calculator__footer}>
               <div className={calculator__footerInfo}>
                  <div className={calculator__footerInfoRow}>
                     <p className={calculator__footerInfoText}>Ð¡Ñ‚Ð°Ð²ÐºÐ° Ð² Ð´ÐµÐ½ÑŒ</p>
                     <p className={calculator__footerInfoValue}>0.00%</p>
                  </div>
                  <div className={calculator__footerInfoRow}>
                     <p className={calculator__footerInfoText}>Ð’ÐµÑ€Ð½ÑƒÑ‚ÑŒ Ð´Ð¾</p>
                     <p className={calculator__footerInfoValue}>
                        {("0" + lastDate.getDate()).slice(-2)}.{("0" + (lastDate.getMonth() + 1)).slice(-2)}
                     </p>
                  </div>
               </div>

               <Button
                  href={"/application?amount=" + amount[selectedAmount] + "&duration=" + days[selectedDay]}
                  variant={"white"}
                  className={calculator__submitButton}
               >
                  ÐžÑ„Ð¾Ñ€Ð¼Ð¸Ñ‚ÑŒ
               </Button>
            </div>

            <Link className={calculator__documentsLink} to={"/dokumenti"}>
               Ð£ÑÐ»Ð¾Ð²Ð¸Ñ Ð¿Ð¾Ð»ÑƒÑ‡ÐµÐ½Ð¸Ñ Ð·Ð°Ð¹Ð¼Ð°
            </Link>
         </div>
      </section>
   );
};

export default Calculator;
