import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Button from "../../../components/Button";
import DropDownList from "../../../components/DropDownList";
import EmptyData from "../../../components/EmptyData";
import Input from "../../../components/Input";
import Table from "../../../components/Table";
import checkAuth from "../../../helpers/checkAuth";
import api from "../../../helpers/fetch";
import getFIO from "../../../helpers/getFIO";
import FulscreenLoader from "../../Components/FulscreenLoader";
import Head from "../../Components/Head";
import IconFilter from "../../Components/Icons/IconFilter";
import PopUp from "../../Components/PopUp";

import scss from "./index.module.scss";

const { link, wrapper__accept, attantion_small, buttons } = scss;

function Staff() {
   const [wait, setWait] = useState(false);
   const [isActive, setIsActive] = useState(false);
   const [row, setRow] = useState([]);
   const [colums] = useState([
      {
         paramName: "surname",
         name: "Ð¤Ð˜Ðž",
      },
      {
         paramName: "phone_number",
         name: "ÐÐ¾Ð¼ÐµÑ€ Ñ‚ÐµÐ»ÐµÑ„Ð¾Ð½Ð°",
      },
      {
         paramName: "role",
         name: "Ð”Ð¾Ð»Ð¶Ð½Ð¾ÑÑ‚ÑŒ",
      },
      {
         paramName: "status",
         name: "Ð¡Ñ‚Ð°Ñ‚ÑƒÑ",
      },
      {
         paramName: "action",
         name: "Ð”ÐµÐ¹ÑÑ‚Ð²Ð¸Ñ",
      },
   ]);
   const [sort, setSort] = useState(["surname", "asc"]);
   const [search, setSearch] = useState("");

   const statusArray = ["Ð£Ð²Ð¾Ð»ÐµÐ½", "Ð Ð°Ð±Ð¾Ñ‚Ð°ÐµÑ‚"];
   const roleArray = ["ÐœÐµÐ½ÐµÐ´Ð¶ÐµÑ€", "Ð ÐµÐ´Ð°ÐºÑ‚Ð¾Ñ€", "ÐÐ´Ð¼Ð¸Ð½Ð¸ÑÑ‚Ñ€Ð°Ñ‚Ð¾Ñ€"];

   const [name, setName] = useState("");
   const [nameError, setNameError] = useState("");
   const [surname, setSurname] = useState("");
   const [surnameError, setSurnameError] = useState("");
   const [midname, setMidname] = useState("");
   const [phone_number, setPhone_number] = useState("");
   const [phone_numberError, setPhone_numberError] = useState("");
   const [email, setEmail] = useState("");
   const [emailError, setEmailError] = useState("");
   const [role, setRole] = useState(0);
   const [pagination, setPagination] = useState({});
   const [paginationPage, setPaginationPage] = useState(1);

   const toTable = (rows = []) => {
      const tmpArray = [];
      rows.forEach((el, key) => {
         tmpArray.push([
            {
               type: "text",
               value: getFIO(el.name, el.surname, el.midname, "ser-name-mid"),
            },
            {
               type: "tel",
               value: el.phone_number,
            },
            {
               type: "text",
               value: roleArray[el.role - 1],
            },
            {
               type: el.status === 0 ? "error" : "success",
               value: statusArray[el.status],
            },
            {
               type: "component",
               value: (
                  <Link className={link} to={"/manage/staff/" + el.id_user}>
                     ÐŸÐ¾Ð´Ñ€Ð¾Ð±Ð½ÐµÐµ
                  </Link>
               ),
            },
         ]);
      });
      return tmpArray;
   };

   const getData = async () => {
      const auth = checkAuth();
      let queryParams = getSortQuery("surname");
      const response = await new api(
         `manage/users?${search ? "search=" + search : ""}` +
            queryParams +
            "&page=" +
            (paginationPage !== 0 && paginationPage ? paginationPage : 1),
         auth
      ).send();
      if (response.status === "success") {
         setRow(toTable(response.data.data));
         setPagination(response.data.pagination);
      }
   };

   const getSearch = (q) => {
      setSearch(q);
   };

   const getSortQuery = (nameField) => {
      if (sort.length === 0) {
         setSort([nameField, "asc"]);
         return "";
      } else {
         return "&sort_param=" + sort[0] + "&sort_direction=" + sort[1];
      }
   };

   const onSubmit = async (e) => {
      e.preventDefault();
      setWait(true);
      setNameError("");
      setSurnameError("");
      setPhone_numberError("");
      setEmailError("");
      if (!name) {
         setNameError("ÐŸÐ¾Ð»Ðµ Ð´Ð¾Ð»Ð¶Ð½Ð¾ Ð±Ñ‹Ñ‚ÑŒ Ð·Ð°Ð¿Ð¾Ð»Ð½ÐµÐ½Ð¾!");
      }
      if (!surname) {
         setSurnameError("ÐŸÐ¾Ð»Ðµ Ð´Ð¾Ð»Ð¶Ð½Ð¾ Ð±Ñ‹Ñ‚ÑŒ Ð·Ð°Ð¿Ð¾Ð»Ð½ÐµÐ½Ð¾!");
      }
      if (!phone_number) {
         setPhone_numberError("ÐŸÐ¾Ð»Ðµ Ð´Ð¾Ð»Ð¶Ð½Ð¾ Ð±Ñ‹Ñ‚ÑŒ Ð·Ð°Ð¿Ð¾Ð»Ð½ÐµÐ½Ð¾!");
      }
      if (!email) {
         setEmailError("ÐŸÐ¾Ð»Ðµ Ð´Ð¾Ð»Ð¶Ð½Ð¾ Ð±Ñ‹Ñ‚ÑŒ Ð·Ð°Ð¿Ð¾Ð»Ð½ÐµÐ½Ð¾!");
      }
      if (name && surname && phone_number && email) {
         const auth = checkAuth();
         const body = new FormData();
         body.append("role", role + 1);
         body.append("name", name);
         body.append("surname", surname);
         body.append("midname", midname);
         body.append("phone_number", phone_number);
         body.append("email", email);
         const response = await new api(`manage/users`, auth, {
            method: "POST",
            body,
         }).send();
         if (response.status === "success") {
            onCloas();
            getData();
            setWait(false);
         } else {
            setPhone_numberError("Ð¢Ð°ÐºÐ¾Ð¹ Ð¿Ð¾Ð»ÑŒÐ·Ð¾Ð²Ð°Ñ‚ÐµÐ»ÑŒ ÑƒÐ¶Ðµ ÑÑƒÑ‰ÐµÑÑ‚Ð²ÑƒÐµÑ‚!");
            setEmailError("Ð¢Ð°ÐºÐ¾Ð¹ Ð¿Ð¾Ð»ÑŒÐ·Ð¾Ð²Ð°Ñ‚ÐµÐ»ÑŒ ÑƒÐ¶Ðµ ÑÑƒÑ‰ÐµÑÑ‚Ð²ÑƒÐµÑ‚!");
            setWait(false);
         }
      }
      setWait(false);
   };

   useEffect(() => {
      getData();
   }, []);

   useEffect(() => {
      getData();
      onPaginate(1);
   }, [sort, search]);

   useEffect(() => {
      getData();
   }, [paginationPage]);

   const onCloas = () => {
      setIsActive(!isActive);
      document.body.style.overflowY = null;
      getData();
   };

   const onPaginate = (number) => {
      setPaginationPage(number);
   };

   return !wait ? (
      <>
         <Head
            onLeavePresEnter={getSearch}
            onClickButton={() => setIsActive(!isActive)}
            buttonText={"ÐÐ¾Ð²Ñ‹Ð¹ ÑÐ¾Ñ‚Ñ€ÑƒÐ´Ð½Ð¸Ðº"}
            searchButton
         />
         <Table
            rows={row}
            colums={colums}
            onSort={(param, direction) => setSort([param, direction])}
            sortParam={sort[0]}
            sortDirection={sort[1]}
            baseColumnsWidth={[null, 256, 256, 160, 160]}
            blockOnEmpty={
               <EmptyData
                  title="ÐÐµÑ‚ Ð´Ð°Ð½Ð½Ñ‹Ñ…"
                  text="ÐŸÐ¾ Ð²Ð°ÑˆÐµÐ¼Ñƒ Ð·Ð°Ð¿Ñ€Ð¾ÑÑƒ Ð½Ðµ Ð½Ð°Ð¹Ð´ÐµÐ½Ð¾ Ð½Ð¸ Ð¾Ð´Ð½Ð¾Ð³Ð¾ ÑÐ¾Ñ‚Ñ€ÑƒÐ´Ð½Ð¸ÐºÐ°."
               />
            }
            pagination={pagination}
            onPaginate={onPaginate}
         />
         {isActive ? (
            <PopUp title={"ÐÐ¾Ð²Ñ‹Ð¹ ÑÐ¾Ñ‚Ñ€ÑƒÐ´Ð½Ð¸Ðº"} onCloase={() => onCloas()}>
               <form action="" onSubmit={onSubmit} className={wrapper__accept}>
                  <DropDownList
                     label={"Ð”Ð¾Ð»Ð¶Ð½Ð¾ÑÑ‚ÑŒ"}
                     options={roleArray}
                     value={role}
                     onChange={setRole}
                  />
                  <Input
                     label={"Ð˜Ð¼Ñ*"}
                     placeholder={"Ð˜Ð¼Ñ"}
                     type={"text"}
                     regex={/^[Ð°-ÑÐ-Ð¯Ñ‘Ð \-]{0,}$/u}
                     value={name}
                     onChange={setName}
                     error={nameError}
                  />
                  <Input
                     label={"Ð¤Ð°Ð¼Ð¸Ð»Ð¸Ñ*"}
                     placeholder={"Ð¤Ð°Ð¼Ð¸Ð»Ð¸Ñ"}
                     type={"text"}
                     regex={/^[Ð°-ÑÐ-Ð¯Ñ‘Ð \-]{0,}$/u}
                     value={surname}
                     onChange={setSurname}
                     error={surnameError}
                  />
                  <Input
                     label={"ÐžÑ‚Ñ‡ÐµÑÑ‚Ð²Ð¾"}
                     placeholder={"ÐžÑ‚Ñ‡ÐµÑÑ‚Ð²Ð¾"}
                     type={"text"}
                     regex={/^[Ð°-ÑÐ-Ð¯Ñ‘Ð \-]{0,}$/u}
                     value={midname}
                     onChange={setMidname}
                  />
                  <Input
                     label={"Ð¢ÐµÐ»ÐµÑ„Ð¾Ð½*"}
                     placeholder={"+7 (906) 800 35-35"}
                     mask={"+7 (XXX) XXX-XX-XX"}
                     name="tel"
                     type={"text"}
                     value={phone_number}
                     onChange={setPhone_number}
                     error={phone_numberError}
                  />
                  <Input
                     label={"Ð­Ð»ÐµÐºÑ‚Ñ€Ð¾Ð½Ð½Ð°Ñ Ð¿Ð¾Ñ‡Ñ‚Ð°*"}
                     placeholder={"Ð­Ð»ÐµÐºÑ‚Ñ€Ð¾Ð½Ð½Ð°Ñ Ð¿Ð¾Ñ‡Ñ‚Ð°"}
                     type={"text"}
                     value={email}
                     onChange={setEmail}
                     error={emailError}
                  />
                  <p className={attantion_small}>
                     Ð’Ð½Ð¸Ð¼Ð°Ñ‚ÐµÐ»ÑŒÐ½Ð¾ Ð¿Ñ€Ð¾Ð²ÐµÑ€ÑÐ¹Ñ‚Ðµ Ð´Ð°Ð½Ð½Ñ‹Ðµ ÑÐ¾Ñ‚Ñ€ÑƒÐ´Ð½Ð¸ÐºÐ° Ð¿ÐµÑ€ÐµÐ´ ÑÐ¾Ñ…Ñ€Ð°Ð½ÐµÐ½Ð¸ÐµÐ¼.
                  </p>
                  <div className={buttons}>
                     <Button variant={"gray"} onClick={() => onCloas()}>
                        ÐžÑ‚Ð¼ÐµÐ½Ð°
                     </Button>
                     <Button isWaiting={wait} type={"submit"}>
                        Ð”Ð¾Ð±Ð°Ð²Ð¸Ñ‚ÑŒ
                     </Button>
                  </div>
               </form>
            </PopUp>
         ) : null}
      </>
   ) : (
      <FulscreenLoader />
   );
}

export default Staff;
