import { useRef } from "react";
import { useState, useEffect } from "react";
import IconLoadPhoto from "../Icons/LoadPhoto";

import css from "./styles.module.scss";

const {
    fileLoader,
    fileLoader__input,
    fileLoader__wrapper,
    fileLoader__icon,
    fileLoader__preview,
    fileLoader__text,
    fileLoader__remove
} = css;

const FileLoader = ({
    children,
    value,
    className,
    onChange,
    extensions
}) => {

    if (
        value &&
        Object.keys(value).length === 0 &&
        Object.getPrototypeOf(value) === Object.prototype
    ) value = null;

    const input = useRef();
    const [file, setFile] = useState(value ? value : null);
    const [filePath, setFilePath] = useState(null);
    
    if (file) {
        let fr = new FileReader();
        fr.onload = function () {
            setFilePath(fr.result);
        }
        fr.readAsDataURL(file);
    }

    const onFileChange = (e) => {
        if (e.target.files.length > 0) {
            setFile(e.target.files[0]);
        }
    }

    const onFileRemove = () => {
        input.current.value = null
        setFile(null);
    }

    useEffect(() => {
        if (file) {
            let fr = new FileReader();
            fr.onload = function () {
                setFilePath(fr.result);
            }
            fr.readAsDataURL(file);
        }
        else {
            setFilePath(null);
        }
        
        if (typeof onChange === "function") onChange(file);
    }, [file]);

    return (
        <div className={fileLoader}>
            <input
                type={"file"}
                onChange={onFileChange}
                value={""}
                ref={input}
                className={fileLoader__input}
                title={file?.name ? file.name : "Ð¤Ð°Ð¹Ð» Ð½Ðµ Ð²Ñ‹Ð±Ñ€Ð°Ð½"}
                accept={extensions}
            />
            <div className={fileLoader__wrapper}>
                {
                    file === null || filePath === null ? (
                        <IconLoadPhoto className={fileLoader__icon} />
                    ) : (
                        <img className={fileLoader__preview} src={filePath} alt={file.name} />
                    )
                }
                {
                    file === null ? (
                        <p className={fileLoader__text}>
                            {children}
                        </p>
                    ) : ""
                }
            </div>
            {
                file !== null ? (
                    <button
                        type="button"
                        className={fileLoader__remove}
                        onClick={onFileRemove}
                    />
                ) : ""
            }
        </div>
    )
}

export default FileLoader;