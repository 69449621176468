import { useEffect } from "react";
import { useState } from "react";
import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import api from "../../helpers/fetch";
import BackMenu from "../BackMenu";
import BlockWrapper from "../BlockWrapper";
import Button from "../Button";
import Input from "../Input";
import PACardWrapper from "../PersonalArea/CardWrapper";

import scss from "./index.module.scss";

const {
   PaymentUnauthorized__container,
   PaymentUnauthorized__wrapper,
   PaymentUnauthorized__title,
   PaymentUnauthorized__text,
   PaymentUnauthorized__secondaryText,
   PaymentUnauthorized__form,
   PaymentUnauthorized__input,
   PaymentUnauthorized__button,
   wrapper,
   cards,
   cards__current,
   cards__title,
   cards__grid,
   cards__grid_white,
   cards__grid_actions,
   cards__pay,
   cards__title_black,
   container,
} = scss;

function PaymentUnauthorized() {

   const navigate = useNavigate();
   const param = useParams();

   const [number, setNumber] = useState("");
   const [numberError, setNumberError] = useState(false);
   const [date, setDate] = useState("");
   const [dateError, setDateError] = useState(false);

   const [wait, setWait] = useState(false);
   const [response, setResponse] = useState();

   const [errorMessage, setErrorMessage] = useState(false);

   const getCompact = async (e) => {
      e.preventDefault();
      setWait(true);
      setDateError(false);
      setNumberError(false);
      setErrorMessage(false);
      const request = new api(`payments?number=${number}&bday=${date}`, false);
      const res = await request.send();
      
      setWait(false);

      if (res.status === "success") {
         setResponse(res);
      }
      else {
         if (res.message === "Can't get payment information") {
            setErrorMessage("ÐÐµ ÑƒÐ´Ð°Ð»Ð¾ÑÑŒ Ð¿Ð¾Ð»ÑƒÑ‡Ð¸Ñ‚ÑŒ Ð¿Ð»Ð°Ñ‚Ñ‘Ð¶Ð½ÑƒÑŽ Ð¸Ð½Ñ„Ð¾Ñ€Ð¼Ð°Ñ†Ð¸ÑŽ.");
         }
         else if (res.message === "Loan not found") {
            setNumberError("Ð—Ð°Ñ‘Ð¼ Ñ Ñ‚Ð°ÐºÐ¸Ð¼ Ð½Ð¾Ð¼ÐµÑ€Ð¾Ð¼ Ð½Ðµ Ð½Ð°Ð¹Ð´ÐµÐ½.");
         }
         else if (res.message === "Bday is invalid" || res.message === "Bday is empty") {
            setDateError("Ð”Ð°Ñ‚Ð° Ñ€Ð¾Ð¶Ð´ÐµÐ½Ð¸Ñ Ð½Ðµ ÑƒÐºÐ°Ð·Ð°Ð½Ð° Ð¸Ð»Ð¸ ÑƒÐºÐ°Ð·Ð°Ð½Ð° Ð½Ðµ ÐºÐ¾Ñ€Ñ€ÐµÐºÑ‚Ð½Ð¾.")
         }
         else if (res.message === "Number is invalid" || res.message === "Number is empty") {
            setNumberError("ÐÐ¾Ð¼ÐµÑ€ Ð½Ðµ ÑƒÐºÐ°Ð·Ð°Ð½ Ð¸Ð»Ð¸ ÑƒÐºÐ°Ð·Ð°Ð½ Ð½Ðµ ÐºÐ¾Ñ€Ñ€ÐµÐºÑ‚Ð½Ð¾.");
         }
         else {
            navigate("/404");
         }
      }
   };

   const resetError = () => {
      if (number) {
         setNumberError(false);
      }
      if (date) {
         setDateError(false);
      }
   }

   const onChangeNumber = (number) => {
      setNumber(number);
      resetError();
   };

   const onChangeDate = (date) => {
      setDate(date);
      resetError();
   };

   return param["token"] ? (
      <section className={container}>
         <BackMenu>
            <Outlet />
         </BackMenu>
      </section>
   ) : (
      <section className={PaymentUnauthorized__container}>
         {!response ? (
            <BlockWrapper className={PaymentUnauthorized__wrapper}>
               <h2 className={PaymentUnauthorized__title}>ÐŸÐ¾Ð¸ÑÐº Ð·Ð°Ð¹Ð¼Ð° Ð´Ð»Ñ Ð¾Ð¿Ð»Ð°Ñ‚Ñ‹</h2>
               <p className={PaymentUnauthorized__text}>{errorMessage ? errorMessage : "ÐŸÐ¾ Ð½Ð¾Ð¼ÐµÑ€Ñƒ Ð´Ð¾Ð³Ð¾Ð²Ð¾Ñ€Ð°"}</p>
               <form className={PaymentUnauthorized__form} onSubmit={getCompact} action="">
                  <Input
                     className={PaymentUnauthorized__input}
                     type="text"
                     label={"ÐÐ¾Ð¼ÐµÑ€ Ð´Ð¾Ð³Ð¾Ð²Ð¾Ñ€Ð°"}
                     placeholder="ÐÐ¾Ð¼ÐµÑ€ Ð´Ð¾Ð³Ð¾Ð²Ð¾Ñ€Ð°"
                     name="number"
                     regex={/[0-9]/}
                     value={number}
                     onChange={(number) => onChangeNumber(number)}
                     error={numberError}
                  />
                  <div className={wrapper}>
                     <Input
                        className={PaymentUnauthorized__input}
                        type="date"
                        placeholder="01.01.2000"
                        label={"Ð”Ð°Ñ‚Ð° Ñ€Ð¾Ð¶Ð´ÐµÐ½Ð¸Ñ"}
                        mask="XX.XX.XXXX"
                        name="date"
                        regex={/[0-9]/}
                        value={date}
                        onChange={(date) => onChangeDate(date)}
                        error={dateError}
                     />
                     <Button
                        isWaiting={wait}
                        className={PaymentUnauthorized__button}
                        type={"sabmit"}
                        disabled={!number || !date || numberError || dateError}
                     >
                        ÐÐ°Ð¹Ñ‚Ð¸
                     </Button>
                  </div>
               </form>
            </BlockWrapper>
         ) : (
            <section className={cards}>
               <PACardWrapper className={cards__pay}>
                  <h3 className={cards__title + " " + cards__title_black}>ÐœÐ¸Ð½Ð¸Ð¼Ð°Ð»ÑŒÐ½Ñ‹Ð¹ Ð¿Ð»Ð°Ñ‚Ñ‘Ð¶</h3>
                  <div className={cards__grid}>
                     <span>
                        <strong>
                           {parseFloat(response?.data?.minimal > 0 ? response?.data?.minimal / 100 : 0).toLocaleString(
                              "ru-RU"
                           )}{" "}
                           â‚½
                        </strong>{" "}
                        c Ð¿Ñ€Ð¾Ñ†ÐµÐ½Ñ‚Ð°Ð¼Ð¸
                     </span>
                  </div>
                  <Button
                     href={"/oplatit/" + response?.data?.token}
                     variant={"gray"}
                     disabled={response?.data?.minimal <= 0}
                  >
                     {response?.data?.minimal > 0 ? "Ð’Ð½ÐµÑÑ‚Ð¸ Ð¿Ð»Ð°Ñ‚Ñ‘Ð¶" : "ÐŸÐ»Ð°Ñ‚Ñ‘Ð¶ Ð¾Ð¿Ð»Ð°Ñ‡ÐµÐ½"}
                  </Button>
               </PACardWrapper>
               <PACardWrapper className={cards__current}>
                  <h3 className={cards__title}>ÐŸÐ¾Ð³Ð°ÑÐ¸Ñ‚ÑŒ Ð·Ð°Ð´Ð¾Ð»Ð¶ÐµÐ½Ð½Ð¾ÑÑ‚ÑŒ</h3>
                  <div className={cards__grid + " " + cards__grid_white}>
                     <span>
                        <strong>{parseFloat(response?.data?.total / 100).toLocaleString("ru-RU")} â‚½</strong> c
                        Ð¿Ñ€Ð¾Ñ†ÐµÐ½Ñ‚Ð°Ð¼Ð¸
                     </span>
                  </div>
                  <div className={cards__grid + " " + cards__grid_white + " " + cards__grid_actions}>
                     <Button variant={"white"} href={"/oplatit/" + response?.data?.token + "/total"}>
                        {"ÐŸÐ¾Ð³Ð°ÑÐ¸Ñ‚ÑŒ Ð·Ð°Ñ‘Ð¼"}
                     </Button>
                  </div>
               </PACardWrapper>
            </section>
         )}
         <p className={PaymentUnauthorized__secondaryText}>
            Ð£ÐºÐ°Ð·Ñ‹Ð²Ð°Ñ Ð½Ð¾Ð¼ÐµÑ€ Ð´Ð¾Ð³Ð¾Ð²Ð¾Ñ€Ð° Ð¸ Ð´Ð°Ñ‚Ñƒ Ñ€Ð¾Ð¶Ð´ÐµÐ½Ð¸Ñ, Ð²Ñ‹ ÑÐ¾Ð³Ð»Ð°ÑˆÐ°ÐµÑ‚ÐµÑÑŒ Ð½Ð° Ð¾Ð±Ñ€Ð°Ð±Ð¾Ñ‚ÐºÑƒ{" "}
            <Link to="/politika-konfidencialnosti">Ð¿ÐµÑ€ÑÐ¾Ð½Ð°Ð»ÑŒÐ½Ñ‹Ñ… Ð´Ð°Ð½Ð½Ñ‹Ñ…</Link>.
         </p>
      </section>
   );
}

export default PaymentUnauthorized;
