import { useState } from "react";
import { Outlet } from "react-router-dom";
import Aside from "../../Components/Aside";
import AsideList from "../../Components/AsideList";

import scss from "./index.module.scss";

const { container, aside, outlet } = scss;

function Settings() {
   const [menu] = useState([
      { title: "ÐšÐ°Ð»ÑŒÐºÑƒÐ»ÑÑ‚Ð¾Ñ€", link: "calculator", disabled: false },
      { title: "ÐžÐ± Ð¾Ñ€Ð³Ð°Ð½Ð¸Ð·Ð°Ñ†Ð¸Ð¸", link: "about-organization", disabled: false },
      { title: "ÐŸÐ»Ð°Ñ‚Ñ‘Ð¶Ð½Ð°Ñ Ð¸Ð½Ñ„.", link: "payment-information", disabled: false },
      { title: "Ð‘Ð°Ð½ÐºÐ¸ Ð¡Ð‘ÐŸ", link: "banks-sbp", disabled: false },
   ]);
   return (
      <div className={container}>
         <Aside className={aside}>{menu ? <AsideList data={menu} /> : null}</Aside>
         <div className={outlet}>
            <Outlet />
         </div>
      </div>
   );
}

export default Settings;
