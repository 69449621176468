import IconCopy from "../../Icons/Copy";
import Container from "../Container";
import scss from "./index.module.scss";

const { information, information__item, information__copy } = scss;

function Information({ title, data, requisites, wait }) {
   return (
      <Container title={title}>
         {requisites ? (
            <>
               <ul className={information}>
                  <li className={information__item}>
                     <h3>Ð˜ÐÐ</h3>
                     {wait ? <p>{data?.inn}</p> : <div className="loader"></div>}
                     <button
                        disabled={wait ? false : true}
                        className={information__copy}
                        type={"button"}
                        onClick={() =>
                           navigator.clipboard.writeText(
                              wait ? data?.inn : "Ð—Ð°Ð³Ñ€ÑƒÐ·ÐºÐ° Ð´Ð°Ð½Ð½Ñ‹Ñ…"
                           )
                        }
                     >
                        <IconCopy />
                     </button>
                  </li>
                  <li className={information__item}>
                     <h3>Ð Ð°ÑÑ‡Ñ‘Ñ‚Ð½Ñ‹Ð¹ ÑÑ‡Ñ‘Ñ‚</h3>
                     {wait ? <p>{data?.account}</p> : <div className="loader"></div>}
                     <button
                        disabled={wait ? false : true}
                        className={information__copy}
                        type={"button"}
                        onClick={() =>
                           navigator.clipboard.writeText(
                              wait ? data?.account : "Ð—Ð°Ð³Ñ€ÑƒÐ·ÐºÐ° Ð´Ð°Ð½Ð½Ñ‹Ñ…"
                           )
                        }
                     >
                        <IconCopy />
                     </button>
                  </li>
                  <li className={information__item}>
                     <h3>Ð‘Ð˜Ðš Ð±Ð°Ð½ÐºÐ° Ð¿Ð¾Ð»ÑƒÑ‡Ð°Ñ‚ÐµÐ»Ñ</h3>
                     {wait ? <p>{data?.bic}</p> : <div className="loader"></div>}
                     <button
                        disabled={wait ? false : true}
                        className={information__copy}
                        type={"button"}
                        onClick={() =>
                           navigator.clipboard.writeText(
                              wait ? data?.bic : "Ð—Ð°Ð³Ñ€ÑƒÐ·ÐºÐ° Ð´Ð°Ð½Ð½Ñ‹Ñ…"
                           )
                        }
                     >
                        <IconCopy />
                     </button>
                  </li>
                  <li className={information__item}>
                     <h3>ÐÐ”Ð¡</h3>
                     {wait ? <p>Ð’ÐºÐ»ÑŽÑ‡Ñ‘Ð½</p> : <div className="loader"></div>}
                     <button
                        disabled={wait ? false : true}
                        className={information__copy}
                        type={"button"}
                        onClick={() =>
                           navigator.clipboard.writeText(
                              wait ? "Ð’ÐºÐ»ÑŽÑ‡Ñ‘Ð½" : "Ð—Ð°Ð³Ñ€ÑƒÐ·ÐºÐ° Ð´Ð°Ð½Ð½Ñ‹Ñ…"
                           )
                        }
                     >
                        <IconCopy />
                     </button>
                  </li>
                  <li className={information__item}>
                     <h3>ÐÐ°Ð·Ð½Ð°Ñ‡ÐµÐ½Ð¸Ðµ Ð¿Ð»Ð°Ñ‚ÐµÐ¶Ð°</h3>
                     {wait ? <p>{data?.purpose}</p> : <div className="loader"></div>}
                     <button
                        disabled={wait ? false : true}
                        className={information__copy}
                        type={"button"}
                        onClick={() =>
                           navigator.clipboard.writeText(
                              wait ? data?.purpose : "Ð—Ð°Ð³Ñ€ÑƒÐ·ÐºÐ° Ð´Ð°Ð½Ð½Ñ‹Ñ…"
                           )
                        }
                     >
                        <IconCopy />
                     </button>
                  </li>
                  <li className={information__item}>
                     <h3>Ð¡ÑƒÐ¼Ð¼Ð° Ð¿Ð»Ð°Ñ‚ÐµÐ¶Ð°</h3>
                     {wait ? (
                        <p>{(data?.amount / 100).toLocaleString("ru-RU")} â‚½</p>
                     ) : (
                        <div className="loader"></div>
                     )}
                     <button
                        disabled={wait ? false : true}
                        className={information__copy}
                        type={"button"}
                        onClick={() =>
                           navigator.clipboard.writeText(
                              wait ? data?.amount : "Ð—Ð°Ð³Ñ€ÑƒÐ·ÐºÐ° Ð´Ð°Ð½Ð½Ñ‹Ñ…"
                           )
                        }
                     >
                        <IconCopy />
                     </button>
                  </li>
               </ul>
            </>
         ) : data ? (
            Object.keys(data).includes("card") ? (
               <ul className={information}>
                  {
                     data?.purpose ? (
                        <li className={information__item}>
                           <h3>ÐšÐ¾Ð¼Ð¼ÐµÐ½Ñ‚Ð°Ñ€Ð¸Ð¹ Ð¿Ð»Ð°Ñ‚ÐµÐ¶Ð°</h3>
                           {wait ? <p>{data?.purpose}</p> : <div className="loader"></div>}
                        </li>
                     ) : ""
                  }
                  <li className={information__item}>
                     <h3>Ð¡ÑƒÐ¼Ð¼Ð° Ð¿Ð»Ð°Ñ‚ÐµÐ¶Ð°</h3>
                     {wait ? (
                        <p>{(data?.amount / 100).toLocaleString("ru-Ru")} â‚½</p>
                     ) : (
                        <div className="loader"></div>
                     )}
                  </li>
               </ul>
            ) : (
               <ul className={information}>
                  <li className={information__item}>
                     <h3>ÐÐ°Ð·Ð½Ð°Ñ‡ÐµÐ½Ð¸Ðµ Ð¿Ð»Ð°Ñ‚ÐµÐ¶Ð°</h3>
                     {wait ? <p>{data?.purpose}</p> : <div className="loader"></div>}
                  </li>
                  <li className={information__item}>
                     <h3>Ð¤Ð˜Ðž Ð¿Ð»Ð°Ñ‚ÐµÐ»ÑŒÑ‰Ð¸ÐºÐ°</h3>
                     {wait ? (
                        <p>
                           {(data?.name ? data?.name : "â€”") +
                              " " +
                              (data?.midName ? data?.midName : "â€”") +
                              " " +
                              (data?.surName ? data?.surName : "â€”")}
                        </p>
                     ) : (
                        <div className="loader"></div>
                     )}
                  </li>
                  <li className={information__item}>
                     <h3>Ð¡ÑƒÐ¼Ð¼Ð° Ð¿Ð»Ð°Ñ‚ÐµÐ¶Ð°</h3>
                     {wait ? (
                        <p>{(data?.amount / 100).toLocaleString("ru-Ru")} â‚½</p>
                     ) : (
                        <div className="loader"></div>
                     )}
                  </li>
               </ul>
            )
         ) : (
            <div>
               <ul className={information}>
                  <li className={information__item}>
                     <div className="loader"></div>
                  </li>
               </ul>
            </div>
         )}
      </Container>
   );
}

export default Information;
