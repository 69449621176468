import Button from "../../../components/Button";
import PACardWrapper from "../../../components/PersonalArea/CardWrapper";
import Timer from "../../../components/Timer";
import scss from "./index.module.scss";

const {
   cards__current,
   cards__current_white,
   cards__notify,
   cards__content,
   cards__title,
   cards__text,
   cards__text_mb14,
   cards__title_black,
   cards__text_black,
   cards__pay,
   cards__grid,
} = scss;

function NotifyCard(status, loan) {
   switch (status) {
      case 1: {
         return (
            <PACardWrapper className={cards__notify}>
               {
                  loan.waiting_time - new Date().getTime() > 0 ? (
                     <>
                        <h3 className={cards__title + " " + cards__title_black}>
                           Ð Ð°ÑÑÐ¼Ð¾Ñ‚Ñ€ÐµÐ½Ð¸Ðµ Ð·Ð°ÑÐ²ÐºÐ¸
                        </h3>
                        <div className={cards__content}>
                           <span className={cards__text + " " + cards__text_black}>
                              ÐœÑ‹ ÑÐ¾Ð¾Ð±Ñ‰Ð¸Ð¼ Ð¾ Ñ€ÐµÑˆÐµÐ½Ð¸Ð¸ Ð¿Ð¾ Ð’Ð°ÑˆÐµÐ¼Ñƒ Ð·Ð°Ð¹Ð¼Ñƒ Ð² Ñ‚ÐµÑ‡ÐµÐ½Ð¸Ð¸:
                           </span>
                           <Timer date={loan.waiting_time} />
                        </div>
                     </>
                  ) : (
                     <>
                        <h3 className={cards__title + " " + cards__title_black}>
                           Ð Ð°ÑÑÐ¼Ð¾Ñ‚Ñ€ÐµÐ½Ð¸Ðµ Ð·Ð°ÑÐ²ÐºÐ¸
                        </h3>
                        <div className={cards__content}>
                           <span className={cards__text + " " + cards__text_black}>
                              ÐÐ°Ð¼ Ñ‚Ñ€ÐµÐ±ÑƒÐµÑ‚ÑÑ ÐµÑ‰Ðµ Ð½ÐµÐºÐ¾Ñ‚Ð¾Ñ€Ð¾Ðµ Ð²Ñ€ÐµÐ¼Ñ Ð´Ð»Ñ&nbsp;Ð¿Ñ€Ð¸Ð½ÑÑ‚Ð¸Ñ Ñ€ÐµÑˆÐµÐ½Ð¸Ñ Ð¿Ð¾&nbsp;Ð²Ð°ÑˆÐµÐ¹ Ð·Ð°ÑÐ²ÐºÐµ.<br /><br />
                              ÐŸÐ¾Ð¶Ð°Ð»ÑƒÐ¹ÑÑ‚Ð° Ð¾Ð¶Ð¸Ð´Ð°Ð¹Ñ‚Ðµ Ñ€ÐµÑˆÐµÐ½Ð¸Ñ.
                           </span>
                        </div>
                     </>
                  )
               }
            </PACardWrapper>
         );
      }
      case 2: {
         return (
            <PACardWrapper className={cards__current + " " + cards__current_white}>
               <h3 className={cards__title + " " + cards__title_black}>ÐžÐ³Ñ€Ð°Ð½Ð¸Ñ‡ÐµÐ½Ð¸Ñ Ð¦Ð‘ Ð Ð¤</h3>
               <span className={cards__text + " " + cards__text_black + " " + cards__text_mb14}>
                  Ð’ ÑÐ²ÑÐ·Ð¸ Ñ Ð¾Ð³Ñ€Ð°Ð½Ð¸Ñ‡ÐµÐ½Ð¸ÑÐ¼Ð¸ Ð¦Ð‘ Ð Ð¤, Ð¿Ñ€Ð¸ Ð’Ð°ÑˆÐµÐ¹ Ð´Ð¾Ð»Ð³Ð¾Ð²Ð¾Ð¹ Ð½Ð°Ð³Ñ€ÑƒÐ·ÐºÐµ Ð¼Ñ‹ Ð½Ðµ Ð¼Ð¾Ð¶ÐµÐ¼ Ð²Ñ‹Ð´Ð°Ñ‚ÑŒ ÑÑƒÐ¼Ð¼Ñƒ Ð²Ñ‹ÑˆÐµ&nbsp;Ð¾Ð´Ð¾Ð±Ñ€ÐµÐ½Ð½Ð¾Ð¹.
               </span>
               <span className={cards__text + " " + cards__text_black} style={{marginBottom: "auto"}}>
                  Ð”Ð°Ð½Ð½Ñ‹Ðµ Ð¾Ð³Ñ€Ð°Ð½Ð¸Ñ‡ÐµÐ½Ð¸Ñ Ð´ÐµÐ¹ÑÑ‚Ð²ÑƒÑŽÑ‚ Ð²Ð¾ Ð²ÑÐµÑ… ÐœÐ¤Ðž Ñ 1 ÑÐ½Ð²Ð°Ñ€Ñ 2023 Ð³Ð¾Ð´Ð°.
               </span>
            </PACardWrapper>
         );
      }
      case 3: {
         return (
            <PACardWrapper className={cards__current + " " + cards__current_white}>
               <h3 className={cards__title + " " + cards__title_black}>
                  Ð’ Ð·Ð°Ð¹Ð¼Ðµ Ð¾Ñ‚ÐºÐ°Ð·Ð°Ð½Ð¾
               </h3>
               <span className={cards__text + " " + cards__text_black}>
                  Ðš ÑÐ¾Ð¶Ð°Ð»ÐµÐ½Ð¸ÑŽ, Ð¼Ñ‹ Ð½Ðµ Ð¼Ð¾Ð¶ÐµÐ¼ Ð²Ñ‹Ð´Ð°Ñ‚ÑŒ Ð’Ð°Ð¼ Ð·Ð°Ñ‘Ð¼ Ð² Ð´Ð°Ð½Ð½Ñ‹Ð¹ Ð¼Ð¾Ð¼ÐµÐ½Ñ‚.
               </span>
               <span className={cards__text + " " + cards__text_black}>
                  Ð’Ñ‹ ÑÐ¼Ð¾Ð¶ÐµÑ‚Ðµ Ð¾Ñ„Ð¾Ñ€Ð¼Ð¸Ñ‚ÑŒ Ð½Ð¾Ð²ÑƒÑŽ Ð·Ð°ÑÐ²ÐºÑƒ Ð½Ð° Ð·Ð°Ñ‘Ð¼{" "}
                  <strong>{new Date(loan.denied_to).toLocaleDateString("ru-RU")}</strong>.
               </span>
            </PACardWrapper>
         );
      }
      case 4: {
         return (
            <PACardWrapper className={cards__pay}>
               <h3 className={cards__title + " " + cards__title_black}>
                  ÐœÐ¸Ð½Ð¸Ð¼Ð°Ð»ÑŒÐ½Ñ‹Ð¹ Ð¿Ð»Ð°Ñ‚Ñ‘Ð¶
               </h3>
               <div className={cards__grid}>
                  <span>
                     Ð¡ÑƒÐ¼Ð¼Ð° Ð¿Ð»Ð°Ñ‚ÐµÐ¶Ð°:{" "}
                     <strong>
                        {parseFloat(
                           loan.info.total > 0 ? loan.info.total / 100 : 0
                        ).toLocaleString("ru-RU")}{" "}
                        â‚½
                     </strong>
                  </span>
                  <span>
                     ÐžÐ¿Ð»Ð°Ñ‚Ð¸Ñ‚ÑŒ Ð´Ð¾:{" "}
                     <strong>
                        {new Date(loan.info.date).toLocaleDateString("ru-RU")}
                     </strong>
                  </span>
               </div>
               <Button
                  href={"/payment-method"}
                  variant={"gray"}
                  disabled={loan.info.total <= 0}
               >
                  {loan.info.total > 0 ? "Ð’Ð½ÐµÑÑ‚Ð¸ Ð¿Ð»Ð°Ñ‚Ñ‘Ð¶" : "ÐŸÐ»Ð°Ñ‚Ñ‘Ð¶ Ð¾Ð¿Ð»Ð°Ñ‡ÐµÐ½"}
               </Button>
            </PACardWrapper>
         );
      }
      default: {
         return (
            <PACardWrapper className={cards__current + " " + cards__current_white}>
               <h3 className={cards__title + " " + cards__title_black}>Ð£Ð²ÐµÐ´Ð¾Ð¼Ð»ÐµÐ½Ð¸Ñ</h3>
               <span className={cards__text + " " + cards__text_black}>
                  Ð£ Ð²Ð°Ñ Ð¿Ð¾ÐºÐ° Ñ‡Ñ‚Ð¾ Ð½ÐµÑ‚ Ð½Ð¾Ð²Ñ‹Ñ… ÑƒÐ²ÐµÐ´Ð¾Ð¼Ð»ÐµÐ½Ð¸Ð¹.
               </span>
            </PACardWrapper>
         );
      }
   }
}

export default NotifyCard;
