import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Confirm from "../../components/PaymentsComponents/Confirm";
import CrediCard from "../../components/PaymentsComponents/CreditCard";
import Information from "../../components/PaymentsComponents/Information";
import Instruction from "../../components/PaymentsComponents/Instruction";
import QR from "../../components/PaymentsComponents/qr";
import api from "../../helpers/fetch";
import scss from "./index.module.scss";

const { container, confirm, instruction, information, aside } = scss;

function PaymentPageByBankCard() {
   const params = useParams();
   const navigate = useNavigate();

   const [state] = useState({
      instruction: {
         title: "ÐšÐ°Ðº Ð¾Ð¿Ð»Ð°Ñ‚Ð¸Ñ‚ÑŒ?*",
         list: [
            "Ð¡ÐºÐ¾Ð¿Ð¸Ñ€ÑƒÐ¹Ñ‚Ðµ Ð½Ð¾Ð¼ÐµÑ€ ÐºÐ°Ñ€Ñ‚Ñ‹ Slon Finance",
            "Ð—Ð°Ð¿ÑƒÑÑ‚Ð¸Ñ‚Ðµ Ð¿Ñ€Ð¸Ð»Ð¾Ð¶ÐµÐ½Ð¸Ðµ Ð±Ð°Ð½ÐºÐ° Ð½Ð° Ð’Ð°ÑˆÐµÐ¼ Ñ‚ÐµÐ»ÐµÑ„Ð¾Ð½Ðµ",
            "ÐžÑ‚ÐºÑ€Ð¾Ð¹Ñ‚Ðµ Ñ€Ð°Ð·Ð´ÐµÐ» â€œÐŸÐ»Ð°Ñ‚ÐµÐ¶Ð¸â€ Ð¸Ð»Ð¸ â€œÐŸÐµÑ€ÐµÐ²Ð¾Ð´Ñ‹â€",
            "Ð’Ñ‹Ð±ÐµÑ€Ð¸Ñ‚Ðµ â€œÐŸÐµÑ€ÐµÐ²ÐµÑÑ‚Ð¸ Ð¿Ð¾ Ð½Ð¾Ð¼ÐµÑ€Ñƒ ÐºÐ°Ñ€Ñ‚Ñ‹â€",
            "Ð’ÑÑ‚Ð°Ð²ÑŒÑ‚Ðµ ÑÐºÐ¾Ð¿Ð¸Ñ€Ð¾Ð²Ð°Ð½Ð½Ñ‹Ð¹ Ð½Ð¾Ð¼ÐµÑ€ ÐºÐ°Ñ€Ñ‚Ñ‹ Ð¸ ÑƒÐºÐ°Ð¶Ð¸Ñ‚Ðµ ÑÑƒÐ¼Ð¼Ñƒ Ð¿Ð»Ð°Ñ‚ÐµÐ¶Ð°",
            "ÐŸÑ€Ð¾Ð²ÐµÑ€ÑŒÑ‚Ðµ Ð·Ð°Ð¿Ð¾Ð»Ð½ÐµÐ½Ð½Ñ‹Ðµ Ð´Ð°Ð½Ð½Ñ‹Ðµ",
            "ÐÐ°Ð¶Ð¼Ð¸Ñ‚Ðµ â€œÐžÐ¿Ð»Ð°Ñ‚Ð¸Ñ‚ÑŒâ€",
         ],
         subtext:
            "*Ð’ Ð¿Ñ€Ð¸Ð»Ð¾Ð¶ÐµÐ½Ð¸Ð¸ Ð’Ð°ÑˆÐµÐ³Ð¾ Ð±Ð°Ð½ÐºÐ° Ð¿Ñ€Ð¾Ñ†ÐµÑÑ Ð¾Ð¿Ð»Ð°Ñ‚Ñ‹ Ð¿ÐµÑ€ÐµÐ²Ð¾Ð´Ð¾Ð¼ Ð½Ð° Ð±Ð°Ð½ÐºÐ¾Ð²ÑÐºÑƒÑŽ ÐºÐ°Ñ€Ñ‚Ñƒ Ð¼Ð¾Ð¶ÐµÑ‚ Ð½ÐµÐ·Ð½Ð°Ñ‡Ð¸Ñ‚ÐµÐ»ÑŒÐ½Ð¾ Ð¾Ñ‚Ð»Ð¸Ñ‡Ð°Ñ‚ÑŒÑÑ.",
      },
      confirm: {
         title: "Ð£Ð¶Ðµ Ð¿Ñ€Ð¾Ð¸Ð·Ð²ÐµÐ»Ð¸ Ð¿Ð»Ð°Ñ‚Ñ‘Ð¶?",
         content: {
            text: "ÐŸÐ¾Ð¼Ð¾Ð³Ð¸Ñ‚Ðµ ÑƒÑÐºÐ¾Ñ€Ð¸Ñ‚ÑŒ ÐµÐ³Ð¾ Ð¾Ð±Ñ€Ð°Ð±Ð¾Ñ‚ÐºÑƒ - Ð¿Ð¾Ð´Ñ‚Ð²ÐµÑ€Ð´Ð¸Ñ‚Ðµ Ð²Ñ‹Ð¿Ð¾Ð»Ð½ÐµÐ½Ð¸Ðµ Ð¿Ð»Ð°Ñ‚ÐµÐ¶Ð°.",
            buttonText: "Ð¯ Ð¾Ð¿Ð»Ð°Ñ‚Ð¸Ð»(Ð°)",
         },
         subtext:
            "Ð¯ Ð¿Ð¾Ð´Ñ‚Ð²ÐµÑ€Ð¶Ð´Ð°ÑŽ, Ñ‡Ñ‚Ð¾ Ð¿Ñ€Ð¾Ð¸Ð·Ð²Ñ‘Ð»(Ð°) Ð¿Ð»Ð°Ñ‚Ñ‘Ð¶ Ð¸ Ð² ÑÐ»ÑƒÑ‡Ð°Ðµ Ð½ÐµÐ¾Ð±Ñ…Ð¾Ð´Ð¸Ð¼Ð¾ÑÑ‚Ð¸ ÑÐ¼Ð¾Ð³Ñƒ Ð¿Ñ€ÐµÐ´Ð¾ÑÑ‚Ð°Ð²Ð¸Ñ‚ÑŒ Ð´Ð¾ÐºÑƒÐ¼ÐµÐ½Ñ‚Ð°Ð»ÑŒÐ½Ð¾Ðµ Ð¿Ð¾Ð´Ñ‚Ð²ÐµÑ€Ð¶Ð´ÐµÐ½Ð¸Ðµ Ð¿ÐµÑ€ÐµÐ²Ð¾Ð´Ð° Ð¸Ð· Ð±Ð°Ð½ÐºÐ°.",
      },
      information: {
         title: "Ðž Ð¿Ð»Ð°Ñ‚ÐµÐ¶Ðµ",
      },
   });

   const [token] = useState(localStorage.getItem("token"));

   const [info, setInfo] = useState({
      // purpose: "",
      name: "",
      surName: "",
      midName: "",
      number: "",
      amount: "",
      card: "",
   });

   const [success, setSuccess] = useState("");

   const getInfo = async () => {
      if (!params.token) {
         if (token) {
            const response = await (
               await fetch(
                  global.api +
                     "payments?type=qr" +
                     (params?.payment ? "&" + params?.payment : ""),
                  {
                     method: "GET",
                     headers: {
                        authorization: token,
                     },
                  }
               )
            ).json();
            setRes(response);
         }
      } else {
         const request = new api(`payments?token=${params?.token}&type=qr`, false);
         const response = await request.send();
         setRes(response);
      }
   };

   const setRes = (response) => {
      setSuccess(response.status);
      switch (response.status) {
         case "success": {
            if (!response?.redirect) {
               setInfo({
                  // purpose: response?.data?.purpose,
                  name: response?.data?.name,
                  surName: response?.data?.surname,
                  midName: response?.data?.midname,
                  number: response?.data?.number,
                  amount: response?.data?.amount,
                  card: response?.data?.card,
               });
            } else {
               navigate(response?.redirect);
            }
            break;
         }

         default: {
            setInfo({
               purpose: "",
               name: "",
               surName: "",
               midName: "",
               number: "",
               amount: "",
               card: "",
            });
            break;
         }
      }
   };

   useEffect(() => {
      getInfo();
   }, []);

   return (
      <div className={container}>
         <div className={information}>
            <Information title={state.information.title} data={info} wait={success} />
         </div>
         <div className={confirm}>
            <Confirm
               params={params}
               title={state.confirm.title}
               subtext={state.confirm.subtext}
               content={state.confirm.content}
               wait={success}
               number={info.number}
               amount={info.amount}
            />
         </div>
         <div className={instruction}>
            <Instruction
               title={state.instruction.title}
               instructions={state.instruction.list}
               subtext={state.instruction.subtext}
            />
         </div>
         <div className={aside}>
            <CrediCard numberCard={info.card} />
         </div>
      </div>
   );
}

export default PaymentPageByBankCard;
