import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import manageRouter from "../../router";
import IconArrowLeft from "../Icons/IconArrowLeft";
import scss from "./index.module.scss";

const { crumbs, crumbs__back, crumbs__link, crumbs__block } = scss;

function Breadcrumbs() {
   const navigate = useNavigate();
   const location = useLocation();
   const breadcrumbs = useBreadcrumbs(manageRouter);

   const getCrumb = (match, breadcrumb) => {
      if (breadcrumb.props.children === "ÐŸÐ°Ð½ÐµÐ»ÑŒ ÑƒÐ¿Ñ€Ð°Ð²Ð»ÐµÐ½Ð¸Ñ" && breadcrumbs.length < 3) {
         return (
            <>
               <Link className={crumbs__link} to={match.pathname}>
                  {breadcrumb}
               </Link>
            </>
         );
      } else if (breadcrumb.props.children === "ÐŸÐ°Ð½ÐµÐ»ÑŒ ÑƒÐ¿Ñ€Ð°Ð²Ð»ÐµÐ½Ð¸Ñ" && breadcrumbs.length >= 3) {
         return (
            <>
               <Link className={crumbs__link} to={match.pathname}>
                  {breadcrumb}
               </Link>
               <span>/</span>
            </>
         );
      } else if (
         match.pathname !== "/" &&
         breadcrumbs[breadcrumbs.length - 1].breadcrumb.props.children !== breadcrumb.props.children
      ) {
         return (
            <>
               <Link className={crumbs__link} to={match.pathname}>
                  {breadcrumb}
               </Link>
               <span>/</span>
            </>
         );
      } else if (
         match.pathname !== "/" &&
         breadcrumbs[breadcrumbs.length - 1].breadcrumb.props.children === breadcrumb.props.children
      ) {
         return (
            <>
               <Link className={crumbs__link} to={match.pathname}>
                  {breadcrumb.props.children}
               </Link>
            </>
         );
      } else if (match.pathname !== "/") {
         return null;
      }
   };

   return (
      <nav className={crumbs}>
         {location.pathname.split("/").length > 3 ? (
            <IconArrowLeft className={crumbs__back} onClick={() => navigate(-1)} />
         ) : null}
         {breadcrumbs.map(({ match, breadcrumb }, key) => (
            <div className={crumbs__block} key={key}>
               {getCrumb(match, breadcrumb)}
            </div>
         ))}
      </nav>
   );
}

export default Breadcrumbs;
